/* Custom orange button */

.custom-primary {
    color: #EE6D00;
  }
  
  .custom-primary-btn {
    background-color: #EE6D00;
    border-color: #EE6D00;
    color: #fff;
  }
  
  /* Hover effect */  
  .custom-primary-btn:hover {
    background-color: #FF8800; /* Change to a slightly different shade for hover effect */
    border-color: #FF8800;
    color: white;
  }
  
  .custom-secondary-btn {
    background-color: #0055A4; /* Navy blue background color */
    border-color: #0055A4; /* Navy blue border color */
    color: #fff; /* White text color */
  }
  
  /* Hover effect */  
  .custom-secondary-btn:hover {
    background-color: #003366; /* Slightly different shade for hover effect */
    border-color: #003366;
    color: white;
  }
  
  .custom-soft-secondary-btn {
    background-color: rgba(128, 128, 128, 0.09);
    color: #002463;
    border-color: rgba(128, 128, 128, 0.09);
    border: none;
    padding: 10px 20px;
  }
  
  .custom-soft-secondary-btn:hover {
    background-color: #002463;
    color: white;
  }
  
  .custom-soft-primary-btn {
    background-color: rgb(255, 245, 227);
    color: orange;
    border-color: rgb(255, 245, 227);
    border: none;
    padding: 10px 20px;
  }
  
  .custom-soft-primary-btn:hover {
    background-color: orange;
    color: white;
  }
  
  .custom-bg-primary {
    background-color: #002463;
  }
  
  .custom-text-primary {
    color: #EE6D00;
  }

  .custom-bg-light {
    background-color: rgb(255, 245, 227);
  }