$bootstrap-icons-font: "bootstrap-icons" !default;
$bootstrap-icons-font-dir: "../fonts" !default;
$bootstrap-icons-font-file: #{$bootstrap-icons-font-dir}/#{$bootstrap-icons-font} !default;
$bootstrap-icons-font-hash: "24e3eb84d0bcaf83d77f904c78ac1f47" !default;
$bootstrap-icons-font-src: url("#{$bootstrap-icons-font-file}.woff2?#{$bootstrap-icons-font-hash}") format("woff2"), url("#{$bootstrap-icons-font-file}.woff?#{$bootstrap-icons-font-hash}") format("woff") !default;

@font-face {
  font-display: block;
  font-family: $bootstrap-icons-font;
  src: url("../../../fonts/bootstrap-icons.woff2?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff2"),
  url("../../../fonts/bootstrap-icons.woff?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff");
}
.bi::before,
[class^="bi-"]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: $bootstrap-icons-font !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$bootstrap-icons-map: (
  "123": "\f67f",
  "alarm-fill": "\f101",
  "alarm": "\f102",
  "align-bottom": "\f103",
  "align-center": "\f104",
  "align-end": "\f105",
  "align-middle": "\f106",
  "align-start": "\f107",
  "align-top": "\f108",
  "alt": "\f109",
  "app-indicator": "\f10a",
  "app": "\f10b",
  "archive-fill": "\f10c",
  "archive": "\f10d",
  "arrow-90deg-down": "\f10e",
  "arrow-90deg-left": "\f10f",
  "arrow-90deg-right": "\f110",
  "arrow-90deg-up": "\f111",
  "arrow-bar-down": "\f112",
  "arrow-bar-left": "\f113",
  "arrow-bar-right": "\f114",
  "arrow-bar-up": "\f115",
  "arrow-clockwise": "\f116",
  "arrow-counterclockwise": "\f117",
  "arrow-down-circle-fill": "\f118",
  "arrow-down-circle": "\f119",
  "arrow-down-left-circle-fill": "\f11a",
  "arrow-down-left-circle": "\f11b",
  "arrow-down-left-square-fill": "\f11c",
  "arrow-down-left-square": "\f11d",
  "arrow-down-left": "\f11e",
  "arrow-down-right-circle-fill": "\f11f",
  "arrow-down-right-circle": "\f120",
  "arrow-down-right-square-fill": "\f121",
  "arrow-down-right-square": "\f122",
  "arrow-down-right": "\f123",
  "arrow-down-short": "\f124",
  "arrow-down-square-fill": "\f125",
  "arrow-down-square": "\f126",
  "arrow-down-up": "\f127",
  "arrow-down": "\f128",
  "arrow-left-circle-fill": "\f129",
  "arrow-left-circle": "\f12a",
  "arrow-left-right": "\f12b",
  "arrow-left-short": "\f12c",
  "arrow-left-square-fill": "\f12d",
  "arrow-left-square": "\f12e",
  "arrow-left": "\f12f",
  "arrow-repeat": "\f130",
  "arrow-return-left": "\f131",
  "arrow-return-right": "\f132",
  "arrow-right-circle-fill": "\f133",
  "arrow-right-circle": "\f134",
  "arrow-right-short": "\f135",
  "arrow-right-square-fill": "\f136",
  "arrow-right-square": "\f137",
  "arrow-right": "\f138",
  "arrow-up-circle-fill": "\f139",
  "arrow-up-circle": "\f13a",
  "arrow-up-left-circle-fill": "\f13b",
  "arrow-up-left-circle": "\f13c",
  "arrow-up-left-square-fill": "\f13d",
  "arrow-up-left-square": "\f13e",
  "arrow-up-left": "\f13f",
  "arrow-up-right-circle-fill": "\f140",
  "arrow-up-right-circle": "\f141",
  "arrow-up-right-square-fill": "\f142",
  "arrow-up-right-square": "\f143",
  "arrow-up-right": "\f144",
  "arrow-up-short": "\f145",
  "arrow-up-square-fill": "\f146",
  "arrow-up-square": "\f147",
  "arrow-up": "\f148",
  "arrows-angle-contract": "\f149",
  "arrows-angle-expand": "\f14a",
  "arrows-collapse": "\f14b",
  "arrows-expand": "\f14c",
  "arrows-fullscreen": "\f14d",
  "arrows-move": "\f14e",
  "aspect-ratio-fill": "\f14f",
  "aspect-ratio": "\f150",
  "asterisk": "\f151",
  "at": "\f152",
  "award-fill": "\f153",
  "award": "\f154",
  "back": "\f155",
  "backspace-fill": "\f156",
  "backspace-reverse-fill": "\f157",
  "backspace-reverse": "\f158",
  "backspace": "\f159",
  "badge-3d-fill": "\f15a",
  "badge-3d": "\f15b",
  "badge-4k-fill": "\f15c",
  "badge-4k": "\f15d",
  "badge-8k-fill": "\f15e",
  "badge-8k": "\f15f",
  "badge-ad-fill": "\f160",
  "badge-ad": "\f161",
  "badge-ar-fill": "\f162",
  "badge-ar": "\f163",
  "badge-cc-fill": "\f164",
  "badge-cc": "\f165",
  "badge-hd-fill": "\f166",
  "badge-hd": "\f167",
  "badge-tm-fill": "\f168",
  "badge-tm": "\f169",
  "badge-vo-fill": "\f16a",
  "badge-vo": "\f16b",
  "badge-vr-fill": "\f16c",
  "badge-vr": "\f16d",
  "badge-wc-fill": "\f16e",
  "badge-wc": "\f16f",
  "bag-check-fill": "\f170",
  "bag-check": "\f171",
  "bag-dash-fill": "\f172",
  "bag-dash": "\f173",
  "bag-fill": "\f174",
  "bag-plus-fill": "\f175",
  "bag-plus": "\f176",
  "bag-x-fill": "\f177",
  "bag-x": "\f178",
  "bag": "\f179",
  "bar-chart-fill": "\f17a",
  "bar-chart-line-fill": "\f17b",
  "bar-chart-line": "\f17c",
  "bar-chart-steps": "\f17d",
  "bar-chart": "\f17e",
  "basket-fill": "\f17f",
  "basket": "\f180",
  "basket2-fill": "\f181",
  "basket2": "\f182",
  "basket3-fill": "\f183",
  "basket3": "\f184",
  "battery-charging": "\f185",
  "battery-full": "\f186",
  "battery-half": "\f187",
  "battery": "\f188",
  "bell-fill": "\f189",
  "bell": "\f18a",
  "bezier": "\f18b",
  "bezier2": "\f18c",
  "bicycle": "\f18d",
  "binoculars-fill": "\f18e",
  "binoculars": "\f18f",
  "blockquote-left": "\f190",
  "blockquote-right": "\f191",
  "book-fill": "\f192",
  "book-half": "\f193",
  "book": "\f194",
  "bookmark-check-fill": "\f195",
  "bookmark-check": "\f196",
  "bookmark-dash-fill": "\f197",
  "bookmark-dash": "\f198",
  "bookmark-fill": "\f199",
  "bookmark-heart-fill": "\f19a",
  "bookmark-heart": "\f19b",
  "bookmark-plus-fill": "\f19c",
  "bookmark-plus": "\f19d",
  "bookmark-star-fill": "\f19e",
  "bookmark-star": "\f19f",
  "bookmark-x-fill": "\f1a0",
  "bookmark-x": "\f1a1",
  "bookmark": "\f1a2",
  "bookmarks-fill": "\f1a3",
  "bookmarks": "\f1a4",
  "bookshelf": "\f1a5",
  "bootstrap-fill": "\f1a6",
  "bootstrap-reboot": "\f1a7",
  "bootstrap": "\f1a8",
  "border-all": "\f1a9",
  "border-bottom": "\f1aa",
  "border-center": "\f1ab",
  "border-inner": "\f1ac",
  "border-left": "\f1ad",
  "border-middle": "\f1ae",
  "border-outer": "\f1af",
  "border-right": "\f1b0",
  "border-style": "\f1b1",
  "border-top": "\f1b2",
  "border-width": "\f1b3",
  "border": "\f1b4",
  "bounding-box-circles": "\f1b5",
  "bounding-box": "\f1b6",
  "box-arrow-down-left": "\f1b7",
  "box-arrow-down-right": "\f1b8",
  "box-arrow-down": "\f1b9",
  "box-arrow-in-down-left": "\f1ba",
  "box-arrow-in-down-right": "\f1bb",
  "box-arrow-in-down": "\f1bc",
  "box-arrow-in-left": "\f1bd",
  "box-arrow-in-right": "\f1be",
  "box-arrow-in-up-left": "\f1bf",
  "box-arrow-in-up-right": "\f1c0",
  "box-arrow-in-up": "\f1c1",
  "box-arrow-left": "\f1c2",
  "box-arrow-right": "\f1c3",
  "box-arrow-up-left": "\f1c4",
  "box-arrow-up-right": "\f1c5",
  "box-arrow-up": "\f1c6",
  "box-seam": "\f1c7",
  "box": "\f1c8",
  "braces": "\f1c9",
  "bricks": "\f1ca",
  "briefcase-fill": "\f1cb",
  "briefcase": "\f1cc",
  "brightness-alt-high-fill": "\f1cd",
  "brightness-alt-high": "\f1ce",
  "brightness-alt-low-fill": "\f1cf",
  "brightness-alt-low": "\f1d0",
  "brightness-high-fill": "\f1d1",
  "brightness-high": "\f1d2",
  "brightness-low-fill": "\f1d3",
  "brightness-low": "\f1d4",
  "broadcast-pin": "\f1d5",
  "broadcast": "\f1d6",
  "brush-fill": "\f1d7",
  "brush": "\f1d8",
  "bucket-fill": "\f1d9",
  "bucket": "\f1da",
  "bug-fill": "\f1db",
  "bug": "\f1dc",
  "building": "\f1dd",
  "bullseye": "\f1de",
  "calculator-fill": "\f1df",
  "calculator": "\f1e0",
  "calendar-check-fill": "\f1e1",
  "calendar-check": "\f1e2",
  "calendar-date-fill": "\f1e3",
  "calendar-date": "\f1e4",
  "calendar-day-fill": "\f1e5",
  "calendar-day": "\f1e6",
  "calendar-event-fill": "\f1e7",
  "calendar-event": "\f1e8",
  "calendar-fill": "\f1e9",
  "calendar-minus-fill": "\f1ea",
  "calendar-minus": "\f1eb",
  "calendar-month-fill": "\f1ec",
  "calendar-month": "\f1ed",
  "calendar-plus-fill": "\f1ee",
  "calendar-plus": "\f1ef",
  "calendar-range-fill": "\f1f0",
  "calendar-range": "\f1f1",
  "calendar-week-fill": "\f1f2",
  "calendar-week": "\f1f3",
  "calendar-x-fill": "\f1f4",
  "calendar-x": "\f1f5",
  "calendar": "\f1f6",
  "calendar2-check-fill": "\f1f7",
  "calendar2-check": "\f1f8",
  "calendar2-date-fill": "\f1f9",
  "calendar2-date": "\f1fa",
  "calendar2-day-fill": "\f1fb",
  "calendar2-day": "\f1fc",
  "calendar2-event-fill": "\f1fd",
  "calendar2-event": "\f1fe",
  "calendar2-fill": "\f1ff",
  "calendar2-minus-fill": "\f200",
  "calendar2-minus": "\f201",
  "calendar2-month-fill": "\f202",
  "calendar2-month": "\f203",
  "calendar2-plus-fill": "\f204",
  "calendar2-plus": "\f205",
  "calendar2-range-fill": "\f206",
  "calendar2-range": "\f207",
  "calendar2-week-fill": "\f208",
  "calendar2-week": "\f209",
  "calendar2-x-fill": "\f20a",
  "calendar2-x": "\f20b",
  "calendar2": "\f20c",
  "calendar3-event-fill": "\f20d",
  "calendar3-event": "\f20e",
  "calendar3-fill": "\f20f",
  "calendar3-range-fill": "\f210",
  "calendar3-range": "\f211",
  "calendar3-week-fill": "\f212",
  "calendar3-week": "\f213",
  "calendar3": "\f214",
  "calendar4-event": "\f215",
  "calendar4-range": "\f216",
  "calendar4-week": "\f217",
  "calendar4": "\f218",
  "camera-fill": "\f219",
  "camera-reels-fill": "\f21a",
  "camera-reels": "\f21b",
  "camera-video-fill": "\f21c",
  "camera-video-off-fill": "\f21d",
  "camera-video-off": "\f21e",
  "camera-video": "\f21f",
  "camera": "\f220",
  "camera2": "\f221",
  "capslock-fill": "\f222",
  "capslock": "\f223",
  "card-checklist": "\f224",
  "card-heading": "\f225",
  "card-image": "\f226",
  "card-list": "\f227",
  "card-text": "\f228",
  "caret-down-fill": "\f229",
  "caret-down-square-fill": "\f22a",
  "caret-down-square": "\f22b",
  "caret-down": "\f22c",
  "caret-left-fill": "\f22d",
  "caret-left-square-fill": "\f22e",
  "caret-left-square": "\f22f",
  "caret-left": "\f230",
  "caret-right-fill": "\f231",
  "caret-right-square-fill": "\f232",
  "caret-right-square": "\f233",
  "caret-right": "\f234",
  "caret-up-fill": "\f235",
  "caret-up-square-fill": "\f236",
  "caret-up-square": "\f237",
  "caret-up": "\f238",
  "cart-check-fill": "\f239",
  "cart-check": "\f23a",
  "cart-dash-fill": "\f23b",
  "cart-dash": "\f23c",
  "cart-fill": "\f23d",
  "cart-plus-fill": "\f23e",
  "cart-plus": "\f23f",
  "cart-x-fill": "\f240",
  "cart-x": "\f241",
  "cart": "\f242",
  "cart2": "\f243",
  "cart3": "\f244",
  "cart4": "\f245",
  "cash-stack": "\f246",
  "cash": "\f247",
  "cast": "\f248",
  "chat-dots-fill": "\f249",
  "chat-dots": "\f24a",
  "chat-fill": "\f24b",
  "chat-left-dots-fill": "\f24c",
  "chat-left-dots": "\f24d",
  "chat-left-fill": "\f24e",
  "chat-left-quote-fill": "\f24f",
  "chat-left-quote": "\f250",
  "chat-left-text-fill": "\f251",
  "chat-left-text": "\f252",
  "chat-left": "\f253",
  "chat-quote-fill": "\f254",
  "chat-quote": "\f255",
  "chat-right-dots-fill": "\f256",
  "chat-right-dots": "\f257",
  "chat-right-fill": "\f258",
  "chat-right-quote-fill": "\f259",
  "chat-right-quote": "\f25a",
  "chat-right-text-fill": "\f25b",
  "chat-right-text": "\f25c",
  "chat-right": "\f25d",
  "chat-square-dots-fill": "\f25e",
  "chat-square-dots": "\f25f",
  "chat-square-fill": "\f260",
  "chat-square-quote-fill": "\f261",
  "chat-square-quote": "\f262",
  "chat-square-text-fill": "\f263",
  "chat-square-text": "\f264",
  "chat-square": "\f265",
  "chat-text-fill": "\f266",
  "chat-text": "\f267",
  "chat": "\f268",
  "check-all": "\f269",
  "check-circle-fill": "\f26a",
  "check-circle": "\f26b",
  "check-square-fill": "\f26c",
  "check-square": "\f26d",
  "check": "\f26e",
  "check2-all": "\f26f",
  "check2-circle": "\f270",
  "check2-square": "\f271",
  "check2": "\f272",
  "chevron-bar-contract": "\f273",
  "chevron-bar-down": "\f274",
  "chevron-bar-expand": "\f275",
  "chevron-bar-left": "\f276",
  "chevron-bar-right": "\f277",
  "chevron-bar-up": "\f278",
  "chevron-compact-down": "\f279",
  "chevron-compact-left": "\f27a",
  "chevron-compact-right": "\f27b",
  "chevron-compact-up": "\f27c",
  "chevron-contract": "\f27d",
  "chevron-double-down": "\f27e",
  "chevron-double-left": "\f27f",
  "chevron-double-right": "\f280",
  "chevron-double-up": "\f281",
  "chevron-down": "\f282",
  "chevron-expand": "\f283",
  "chevron-left": "\f284",
  "chevron-right": "\f285",
  "chevron-up": "\f286",
  "circle-fill": "\f287",
  "circle-half": "\f288",
  "circle-square": "\f289",
  "circle": "\f28a",
  "clipboard-check": "\f28b",
  "clipboard-data": "\f28c",
  "clipboard-minus": "\f28d",
  "clipboard-plus": "\f28e",
  "clipboard-x": "\f28f",
  "clipboard": "\f290",
  "clock-fill": "\f291",
  "clock-history": "\f292",
  "clock": "\f293",
  "cloud-arrow-down-fill": "\f294",
  "cloud-arrow-down": "\f295",
  "cloud-arrow-up-fill": "\f296",
  "cloud-arrow-up": "\f297",
  "cloud-check-fill": "\f298",
  "cloud-check": "\f299",
  "cloud-download-fill": "\f29a",
  "cloud-download": "\f29b",
  "cloud-drizzle-fill": "\f29c",
  "cloud-drizzle": "\f29d",
  "cloud-fill": "\f29e",
  "cloud-fog-fill": "\f29f",
  "cloud-fog": "\f2a0",
  "cloud-fog2-fill": "\f2a1",
  "cloud-fog2": "\f2a2",
  "cloud-hail-fill": "\f2a3",
  "cloud-hail": "\f2a4",
  "cloud-haze-1": "\f2a5",
  "cloud-haze-fill": "\f2a6",
  "cloud-haze": "\f2a7",
  "cloud-haze2-fill": "\f2a8",
  "cloud-lightning-fill": "\f2a9",
  "cloud-lightning-rain-fill": "\f2aa",
  "cloud-lightning-rain": "\f2ab",
  "cloud-lightning": "\f2ac",
  "cloud-minus-fill": "\f2ad",
  "cloud-minus": "\f2ae",
  "cloud-moon-fill": "\f2af",
  "cloud-moon": "\f2b0",
  "cloud-plus-fill": "\f2b1",
  "cloud-plus": "\f2b2",
  "cloud-rain-fill": "\f2b3",
  "cloud-rain-heavy-fill": "\f2b4",
  "cloud-rain-heavy": "\f2b5",
  "cloud-rain": "\f2b6",
  "cloud-slash-fill": "\f2b7",
  "cloud-slash": "\f2b8",
  "cloud-sleet-fill": "\f2b9",
  "cloud-sleet": "\f2ba",
  "cloud-snow-fill": "\f2bb",
  "cloud-snow": "\f2bc",
  "cloud-sun-fill": "\f2bd",
  "cloud-sun": "\f2be",
  "cloud-upload-fill": "\f2bf",
  "cloud-upload": "\f2c0",
  "cloud": "\f2c1",
  "clouds-fill": "\f2c2",
  "clouds": "\f2c3",
  "cloudy-fill": "\f2c4",
  "cloudy": "\f2c5",
  "code-slash": "\f2c6",
  "code-square": "\f2c7",
  "code": "\f2c8",
  "collection-fill": "\f2c9",
  "collection-play-fill": "\f2ca",
  "collection-play": "\f2cb",
  "collection": "\f2cc",
  "columns-gap": "\f2cd",
  "columns": "\f2ce",
  "command": "\f2cf",
  "compass-fill": "\f2d0",
  "compass": "\f2d1",
  "cone-striped": "\f2d2",
  "cone": "\f2d3",
  "controller": "\f2d4",
  "cpu-fill": "\f2d5",
  "cpu": "\f2d6",
  "credit-card-2-back-fill": "\f2d7",
  "credit-card-2-back": "\f2d8",
  "credit-card-2-front-fill": "\f2d9",
  "credit-card-2-front": "\f2da",
  "credit-card-fill": "\f2db",
  "credit-card": "\f2dc",
  "crop": "\f2dd",
  "cup-fill": "\f2de",
  "cup-straw": "\f2df",
  "cup": "\f2e0",
  "cursor-fill": "\f2e1",
  "cursor-text": "\f2e2",
  "cursor": "\f2e3",
  "dash-circle-dotted": "\f2e4",
  "dash-circle-fill": "\f2e5",
  "dash-circle": "\f2e6",
  "dash-square-dotted": "\f2e7",
  "dash-square-fill": "\f2e8",
  "dash-square": "\f2e9",
  "dash": "\f2ea",
  "diagram-2-fill": "\f2eb",
  "diagram-2": "\f2ec",
  "diagram-3-fill": "\f2ed",
  "diagram-3": "\f2ee",
  "diamond-fill": "\f2ef",
  "diamond-half": "\f2f0",
  "diamond": "\f2f1",
  "dice-1-fill": "\f2f2",
  "dice-1": "\f2f3",
  "dice-2-fill": "\f2f4",
  "dice-2": "\f2f5",
  "dice-3-fill": "\f2f6",
  "dice-3": "\f2f7",
  "dice-4-fill": "\f2f8",
  "dice-4": "\f2f9",
  "dice-5-fill": "\f2fa",
  "dice-5": "\f2fb",
  "dice-6-fill": "\f2fc",
  "dice-6": "\f2fd",
  "disc-fill": "\f2fe",
  "disc": "\f2ff",
  "discord": "\f300",
  "display-fill": "\f301",
  "display": "\f302",
  "distribute-horizontal": "\f303",
  "distribute-vertical": "\f304",
  "door-closed-fill": "\f305",
  "door-closed": "\f306",
  "door-open-fill": "\f307",
  "door-open": "\f308",
  "dot": "\f309",
  "download": "\f30a",
  "droplet-fill": "\f30b",
  "droplet-half": "\f30c",
  "droplet": "\f30d",
  "earbuds": "\f30e",
  "easel-fill": "\f30f",
  "easel": "\f310",
  "egg-fill": "\f311",
  "egg-fried": "\f312",
  "egg": "\f313",
  "eject-fill": "\f314",
  "eject": "\f315",
  "emoji-angry-fill": "\f316",
  "emoji-angry": "\f317",
  "emoji-dizzy-fill": "\f318",
  "emoji-dizzy": "\f319",
  "emoji-expressionless-fill": "\f31a",
  "emoji-expressionless": "\f31b",
  "emoji-frown-fill": "\f31c",
  "emoji-frown": "\f31d",
  "emoji-heart-eyes-fill": "\f31e",
  "emoji-heart-eyes": "\f31f",
  "emoji-laughing-fill": "\f320",
  "emoji-laughing": "\f321",
  "emoji-neutral-fill": "\f322",
  "emoji-neutral": "\f323",
  "emoji-smile-fill": "\f324",
  "emoji-smile-upside-down-fill": "\f325",
  "emoji-smile-upside-down": "\f326",
  "emoji-smile": "\f327",
  "emoji-sunglasses-fill": "\f328",
  "emoji-sunglasses": "\f329",
  "emoji-wink-fill": "\f32a",
  "emoji-wink": "\f32b",
  "envelope-fill": "\f32c",
  "envelope-open-fill": "\f32d",
  "envelope-open": "\f32e",
  "envelope": "\f32f",
  "eraser-fill": "\f330",
  "eraser": "\f331",
  "exclamation-circle-fill": "\f332",
  "exclamation-circle": "\f333",
  "exclamation-diamond-fill": "\f334",
  "exclamation-diamond": "\f335",
  "exclamation-octagon-fill": "\f336",
  "exclamation-octagon": "\f337",
  "exclamation-square-fill": "\f338",
  "exclamation-square": "\f339",
  "exclamation-triangle-fill": "\f33a",
  "exclamation-triangle": "\f33b",
  "exclamation": "\f33c",
  "exclude": "\f33d",
  "eye-fill": "\f33e",
  "eye-slash-fill": "\f33f",
  "eye-slash": "\f340",
  "eye": "\f341",
  "eyedropper": "\f342",
  "eyeglasses": "\f343",
  "facebook": "\f344",
  "file-arrow-down-fill": "\f345",
  "file-arrow-down": "\f346",
  "file-arrow-up-fill": "\f347",
  "file-arrow-up": "\f348",
  "file-bar-graph-fill": "\f349",
  "file-bar-graph": "\f34a",
  "file-binary-fill": "\f34b",
  "file-binary": "\f34c",
  "file-break-fill": "\f34d",
  "file-break": "\f34e",
  "file-check-fill": "\f34f",
  "file-check": "\f350",
  "file-code-fill": "\f351",
  "file-code": "\f352",
  "file-diff-fill": "\f353",
  "file-diff": "\f354",
  "file-earmark-arrow-down-fill": "\f355",
  "file-earmark-arrow-down": "\f356",
  "file-earmark-arrow-up-fill": "\f357",
  "file-earmark-arrow-up": "\f358",
  "file-earmark-bar-graph-fill": "\f359",
  "file-earmark-bar-graph": "\f35a",
  "file-earmark-binary-fill": "\f35b",
  "file-earmark-binary": "\f35c",
  "file-earmark-break-fill": "\f35d",
  "file-earmark-break": "\f35e",
  "file-earmark-check-fill": "\f35f",
  "file-earmark-check": "\f360",
  "file-earmark-code-fill": "\f361",
  "file-earmark-code": "\f362",
  "file-earmark-diff-fill": "\f363",
  "file-earmark-diff": "\f364",
  "file-earmark-easel-fill": "\f365",
  "file-earmark-easel": "\f366",
  "file-earmark-excel-fill": "\f367",
  "file-earmark-excel": "\f368",
  "file-earmark-fill": "\f369",
  "file-earmark-font-fill": "\f36a",
  "file-earmark-font": "\f36b",
  "file-earmark-image-fill": "\f36c",
  "file-earmark-image": "\f36d",
  "file-earmark-lock-fill": "\f36e",
  "file-earmark-lock": "\f36f",
  "file-earmark-lock2-fill": "\f370",
  "file-earmark-lock2": "\f371",
  "file-earmark-medical-fill": "\f372",
  "file-earmark-medical": "\f373",
  "file-earmark-minus-fill": "\f374",
  "file-earmark-minus": "\f375",
  "file-earmark-music-fill": "\f376",
  "file-earmark-music": "\f377",
  "file-earmark-person-fill": "\f378",
  "file-earmark-person": "\f379",
  "file-earmark-play-fill": "\f37a",
  "file-earmark-play": "\f37b",
  "file-earmark-plus-fill": "\f37c",
  "file-earmark-plus": "\f37d",
  "file-earmark-post-fill": "\f37e",
  "file-earmark-post": "\f37f",
  "file-earmark-ppt-fill": "\f380",
  "file-earmark-ppt": "\f381",
  "file-earmark-richtext-fill": "\f382",
  "file-earmark-richtext": "\f383",
  "file-earmark-ruled-fill": "\f384",
  "file-earmark-ruled": "\f385",
  "file-earmark-slides-fill": "\f386",
  "file-earmark-slides": "\f387",
  "file-earmark-spreadsheet-fill": "\f388",
  "file-earmark-spreadsheet": "\f389",
  "file-earmark-text-fill": "\f38a",
  "file-earmark-text": "\f38b",
  "file-earmark-word-fill": "\f38c",
  "file-earmark-word": "\f38d",
  "file-earmark-x-fill": "\f38e",
  "file-earmark-x": "\f38f",
  "file-earmark-zip-fill": "\f390",
  "file-earmark-zip": "\f391",
  "file-earmark": "\f392",
  "file-easel-fill": "\f393",
  "file-easel": "\f394",
  "file-excel-fill": "\f395",
  "file-excel": "\f396",
  "file-fill": "\f397",
  "file-font-fill": "\f398",
  "file-font": "\f399",
  "file-image-fill": "\f39a",
  "file-image": "\f39b",
  "file-lock-fill": "\f39c",
  "file-lock": "\f39d",
  "file-lock2-fill": "\f39e",
  "file-lock2": "\f39f",
  "file-medical-fill": "\f3a0",
  "file-medical": "\f3a1",
  "file-minus-fill": "\f3a2",
  "file-minus": "\f3a3",
  "file-music-fill": "\f3a4",
  "file-music": "\f3a5",
  "file-person-fill": "\f3a6",
  "file-person": "\f3a7",
  "file-play-fill": "\f3a8",
  "file-play": "\f3a9",
  "file-plus-fill": "\f3aa",
  "file-plus": "\f3ab",
  "file-post-fill": "\f3ac",
  "file-post": "\f3ad",
  "file-ppt-fill": "\f3ae",
  "file-ppt": "\f3af",
  "file-richtext-fill": "\f3b0",
  "file-richtext": "\f3b1",
  "file-ruled-fill": "\f3b2",
  "file-ruled": "\f3b3",
  "file-slides-fill": "\f3b4",
  "file-slides": "\f3b5",
  "file-spreadsheet-fill": "\f3b6",
  "file-spreadsheet": "\f3b7",
  "file-text-fill": "\f3b8",
  "file-text": "\f3b9",
  "file-word-fill": "\f3ba",
  "file-word": "\f3bb",
  "file-x-fill": "\f3bc",
  "file-x": "\f3bd",
  "file-zip-fill": "\f3be",
  "file-zip": "\f3bf",
  "file": "\f3c0",
  "files-alt": "\f3c1",
  "files": "\f3c2",
  "film": "\f3c3",
  "filter-circle-fill": "\f3c4",
  "filter-circle": "\f3c5",
  "filter-left": "\f3c6",
  "filter-right": "\f3c7",
  "filter-square-fill": "\f3c8",
  "filter-square": "\f3c9",
  "filter": "\f3ca",
  "flag-fill": "\f3cb",
  "flag": "\f3cc",
  "flower1": "\f3cd",
  "flower2": "\f3ce",
  "flower3": "\f3cf",
  "folder-check": "\f3d0",
  "folder-fill": "\f3d1",
  "folder-minus": "\f3d2",
  "folder-plus": "\f3d3",
  "folder-symlink-fill": "\f3d4",
  "folder-symlink": "\f3d5",
  "folder-x": "\f3d6",
  "folder": "\f3d7",
  "folder2-open": "\f3d8",
  "folder2": "\f3d9",
  "fonts": "\f3da",
  "forward-fill": "\f3db",
  "forward": "\f3dc",
  "front": "\f3dd",
  "fullscreen-exit": "\f3de",
  "fullscreen": "\f3df",
  "funnel-fill": "\f3e0",
  "funnel": "\f3e1",
  "gear-fill": "\f3e2",
  "gear-wide-connected": "\f3e3",
  "gear-wide": "\f3e4",
  "gear": "\f3e5",
  "gem": "\f3e6",
  "geo-alt-fill": "\f3e7",
  "geo-alt": "\f3e8",
  "geo-fill": "\f3e9",
  "geo": "\f3ea",
  "gift-fill": "\f3eb",
  "gift": "\f3ec",
  "github": "\f3ed",
  "globe": "\f3ee",
  "globe2": "\f3ef",
  "google": "\f3f0",
  "graph-down": "\f3f1",
  "graph-up": "\f3f2",
  "grid-1x2-fill": "\f3f3",
  "grid-1x2": "\f3f4",
  "grid-3x2-gap-fill": "\f3f5",
  "grid-3x2-gap": "\f3f6",
  "grid-3x2": "\f3f7",
  "grid-3x3-gap-fill": "\f3f8",
  "grid-3x3-gap": "\f3f9",
  "grid-3x3": "\f3fa",
  "grid-fill": "\f3fb",
  "grid": "\f3fc",
  "grip-horizontal": "\f3fd",
  "grip-vertical": "\f3fe",
  "hammer": "\f3ff",
  "hand-index-fill": "\f400",
  "hand-index-thumb-fill": "\f401",
  "hand-index-thumb": "\f402",
  "hand-index": "\f403",
  "hand-thumbs-down-fill": "\f404",
  "hand-thumbs-down": "\f405",
  "hand-thumbs-up-fill": "\f406",
  "hand-thumbs-up": "\f407",
  "handbag-fill": "\f408",
  "handbag": "\f409",
  "hash": "\f40a",
  "hdd-fill": "\f40b",
  "hdd-network-fill": "\f40c",
  "hdd-network": "\f40d",
  "hdd-rack-fill": "\f40e",
  "hdd-rack": "\f40f",
  "hdd-stack-fill": "\f410",
  "hdd-stack": "\f411",
  "hdd": "\f412",
  "headphones": "\f413",
  "headset": "\f414",
  "heart-fill": "\f415",
  "heart-half": "\f416",
  "heart": "\f417",
  "heptagon-fill": "\f418",
  "heptagon-half": "\f419",
  "heptagon": "\f41a",
  "hexagon-fill": "\f41b",
  "hexagon-half": "\f41c",
  "hexagon": "\f41d",
  "hourglass-bottom": "\f41e",
  "hourglass-split": "\f41f",
  "hourglass-top": "\f420",
  "hourglass": "\f421",
  "house-door-fill": "\f422",
  "house-door": "\f423",
  "house-fill": "\f424",
  "house": "\f425",
  "hr": "\f426",
  "hurricane": "\f427",
  "image-alt": "\f428",
  "image-fill": "\f429",
  "image": "\f42a",
  "images": "\f42b",
  "inbox-fill": "\f42c",
  "inbox": "\f42d",
  "inboxes-fill": "\f42e",
  "inboxes": "\f42f",
  "info-circle-fill": "\f430",
  "info-circle": "\f431",
  "info-square-fill": "\f432",
  "info-square": "\f433",
  "info": "\f434",
  "input-cursor-text": "\f435",
  "input-cursor": "\f436",
  "instagram": "\f437",
  "intersect": "\f438",
  "journal-album": "\f439",
  "journal-arrow-down": "\f43a",
  "journal-arrow-up": "\f43b",
  "journal-bookmark-fill": "\f43c",
  "journal-bookmark": "\f43d",
  "journal-check": "\f43e",
  "journal-code": "\f43f",
  "journal-medical": "\f440",
  "journal-minus": "\f441",
  "journal-plus": "\f442",
  "journal-richtext": "\f443",
  "journal-text": "\f444",
  "journal-x": "\f445",
  "journal": "\f446",
  "journals": "\f447",
  "joystick": "\f448",
  "justify-left": "\f449",
  "justify-right": "\f44a",
  "justify": "\f44b",
  "kanban-fill": "\f44c",
  "kanban": "\f44d",
  "key-fill": "\f44e",
  "key": "\f44f",
  "keyboard-fill": "\f450",
  "keyboard": "\f451",
  "ladder": "\f452",
  "lamp-fill": "\f453",
  "lamp": "\f454",
  "laptop-fill": "\f455",
  "laptop": "\f456",
  "layer-backward": "\f457",
  "layer-forward": "\f458",
  "layers-fill": "\f459",
  "layers-half": "\f45a",
  "layers": "\f45b",
  "layout-sidebar-inset-reverse": "\f45c",
  "layout-sidebar-inset": "\f45d",
  "layout-sidebar-reverse": "\f45e",
  "layout-sidebar": "\f45f",
  "layout-split": "\f460",
  "layout-text-sidebar-reverse": "\f461",
  "layout-text-sidebar": "\f462",
  "layout-text-window-reverse": "\f463",
  "layout-text-window": "\f464",
  "layout-three-columns": "\f465",
  "layout-wtf": "\f466",
  "life-preserver": "\f467",
  "lightbulb-fill": "\f468",
  "lightbulb-off-fill": "\f469",
  "lightbulb-off": "\f46a",
  "lightbulb": "\f46b",
  "lightning-charge-fill": "\f46c",
  "lightning-charge": "\f46d",
  "lightning-fill": "\f46e",
  "lightning": "\f46f",
  "link-45deg": "\f470",
  "link": "\f471",
  "linkedin": "\f472",
  "list-check": "\f473",
  "list-nested": "\f474",
  "list-ol": "\f475",
  "list-stars": "\f476",
  "list-task": "\f477",
  "list-ul": "\f478",
  "list": "\f479",
  "lock-fill": "\f47a",
  "lock": "\f47b",
  "mailbox": "\f47c",
  "mailbox2": "\f47d",
  "map-fill": "\f47e",
  "map": "\f47f",
  "markdown-fill": "\f480",
  "markdown": "\f481",
  "mask": "\f482",
  "megaphone-fill": "\f483",
  "megaphone": "\f484",
  "menu-app-fill": "\f485",
  "menu-app": "\f486",
  "menu-button-fill": "\f487",
  "menu-button-wide-fill": "\f488",
  "menu-button-wide": "\f489",
  "menu-button": "\f48a",
  "menu-down": "\f48b",
  "menu-up": "\f48c",
  "mic-fill": "\f48d",
  "mic-mute-fill": "\f48e",
  "mic-mute": "\f48f",
  "mic": "\f490",
  "minecart-loaded": "\f491",
  "minecart": "\f492",
  "moisture": "\f493",
  "moon-fill": "\f494",
  "moon-stars-fill": "\f495",
  "moon-stars": "\f496",
  "moon": "\f497",
  "mouse-fill": "\f498",
  "mouse": "\f499",
  "mouse2-fill": "\f49a",
  "mouse2": "\f49b",
  "mouse3-fill": "\f49c",
  "mouse3": "\f49d",
  "music-note-beamed": "\f49e",
  "music-note-list": "\f49f",
  "music-note": "\f4a0",
  "music-player-fill": "\f4a1",
  "music-player": "\f4a2",
  "newspaper": "\f4a3",
  "node-minus-fill": "\f4a4",
  "node-minus": "\f4a5",
  "node-plus-fill": "\f4a6",
  "node-plus": "\f4a7",
  "nut-fill": "\f4a8",
  "nut": "\f4a9",
  "octagon-fill": "\f4aa",
  "octagon-half": "\f4ab",
  "octagon": "\f4ac",
  "option": "\f4ad",
  "outlet": "\f4ae",
  "paint-bucket": "\f4af",
  "palette-fill": "\f4b0",
  "palette": "\f4b1",
  "palette2": "\f4b2",
  "paperclip": "\f4b3",
  "paragraph": "\f4b4",
  "patch-check-fill": "\f4b5",
  "patch-check": "\f4b6",
  "patch-exclamation-fill": "\f4b7",
  "patch-exclamation": "\f4b8",
  "patch-minus-fill": "\f4b9",
  "patch-minus": "\f4ba",
  "patch-plus-fill": "\f4bb",
  "patch-plus": "\f4bc",
  "patch-question-fill": "\f4bd",
  "patch-question": "\f4be",
  "pause-btn-fill": "\f4bf",
  "pause-btn": "\f4c0",
  "pause-circle-fill": "\f4c1",
  "pause-circle": "\f4c2",
  "pause-fill": "\f4c3",
  "pause": "\f4c4",
  "peace-fill": "\f4c5",
  "peace": "\f4c6",
  "pen-fill": "\f4c7",
  "pen": "\f4c8",
  "pencil-fill": "\f4c9",
  "pencil-square": "\f4ca",
  "pencil": "\f4cb",
  "pentagon-fill": "\f4cc",
  "pentagon-half": "\f4cd",
  "pentagon": "\f4ce",
  "people-fill": "\f4cf",
  "people": "\f4d0",
  "percent": "\f4d1",
  "person-badge-fill": "\f4d2",
  "person-badge": "\f4d3",
  "person-bounding-box": "\f4d4",
  "person-check-fill": "\f4d5",
  "person-check": "\f4d6",
  "person-circle": "\f4d7",
  "person-dash-fill": "\f4d8",
  "person-dash": "\f4d9",
  "person-fill": "\f4da",
  "person-lines-fill": "\f4db",
  "person-plus-fill": "\f4dc",
  "person-plus": "\f4dd",
  "person-square": "\f4de",
  "person-x-fill": "\f4df",
  "person-x": "\f4e0",
  "person": "\f4e1",
  "phone-fill": "\f4e2",
  "phone-landscape-fill": "\f4e3",
  "phone-landscape": "\f4e4",
  "phone-vibrate-fill": "\f4e5",
  "phone-vibrate": "\f4e6",
  "phone": "\f4e7",
  "pie-chart-fill": "\f4e8",
  "pie-chart": "\f4e9",
  "pin-angle-fill": "\f4ea",
  "pin-angle": "\f4eb",
  "pin-fill": "\f4ec",
  "pin": "\f4ed",
  "pip-fill": "\f4ee",
  "pip": "\f4ef",
  "play-btn-fill": "\f4f0",
  "play-btn": "\f4f1",
  "play-circle-fill": "\f4f2",
  "play-circle": "\f4f3",
  "play-fill": "\f4f4",
  "play": "\f4f5",
  "plug-fill": "\f4f6",
  "plug": "\f4f7",
  "plus-circle-dotted": "\f4f8",
  "plus-circle-fill": "\f4f9",
  "plus-circle": "\f4fa",
  "plus-square-dotted": "\f4fb",
  "plus-square-fill": "\f4fc",
  "plus-square": "\f4fd",
  "plus": "\f4fe",
  "power": "\f4ff",
  "printer-fill": "\f500",
  "printer": "\f501",
  "puzzle-fill": "\f502",
  "puzzle": "\f503",
  "question-circle-fill": "\f504",
  "question-circle": "\f505",
  "question-diamond-fill": "\f506",
  "question-diamond": "\f507",
  "question-octagon-fill": "\f508",
  "question-octagon": "\f509",
  "question-square-fill": "\f50a",
  "question-square": "\f50b",
  "question": "\f50c",
  "rainbow": "\f50d",
  "receipt-cutoff": "\f50e",
  "receipt": "\f50f",
  "reception-0": "\f510",
  "reception-1": "\f511",
  "reception-2": "\f512",
  "reception-3": "\f513",
  "reception-4": "\f514",
  "record-btn-fill": "\f515",
  "record-btn": "\f516",
  "record-circle-fill": "\f517",
  "record-circle": "\f518",
  "record-fill": "\f519",
  "record": "\f51a",
  "record2-fill": "\f51b",
  "record2": "\f51c",
  "reply-all-fill": "\f51d",
  "reply-all": "\f51e",
  "reply-fill": "\f51f",
  "reply": "\f520",
  "rss-fill": "\f521",
  "rss": "\f522",
  "rulers": "\f523",
  "save-fill": "\f524",
  "save": "\f525",
  "save2-fill": "\f526",
  "save2": "\f527",
  "scissors": "\f528",
  "screwdriver": "\f529",
  "search": "\f52a",
  "segmented-nav": "\f52b",
  "server": "\f52c",
  "share-fill": "\f52d",
  "share": "\f52e",
  "shield-check": "\f52f",
  "shield-exclamation": "\f530",
  "shield-fill-check": "\f531",
  "shield-fill-exclamation": "\f532",
  "shield-fill-minus": "\f533",
  "shield-fill-plus": "\f534",
  "shield-fill-x": "\f535",
  "shield-fill": "\f536",
  "shield-lock-fill": "\f537",
  "shield-lock": "\f538",
  "shield-minus": "\f539",
  "shield-plus": "\f53a",
  "shield-shaded": "\f53b",
  "shield-slash-fill": "\f53c",
  "shield-slash": "\f53d",
  "shield-x": "\f53e",
  "shield": "\f53f",
  "shift-fill": "\f540",
  "shift": "\f541",
  "shop-window": "\f542",
  "shop": "\f543",
  "shuffle": "\f544",
  "signpost-2-fill": "\f545",
  "signpost-2": "\f546",
  "signpost-fill": "\f547",
  "signpost-split-fill": "\f548",
  "signpost-split": "\f549",
  "signpost": "\f54a",
  "sim-fill": "\f54b",
  "sim": "\f54c",
  "skip-backward-btn-fill": "\f54d",
  "skip-backward-btn": "\f54e",
  "skip-backward-circle-fill": "\f54f",
  "skip-backward-circle": "\f550",
  "skip-backward-fill": "\f551",
  "skip-backward": "\f552",
  "skip-end-btn-fill": "\f553",
  "skip-end-btn": "\f554",
  "skip-end-circle-fill": "\f555",
  "skip-end-circle": "\f556",
  "skip-end-fill": "\f557",
  "skip-end": "\f558",
  "skip-forward-btn-fill": "\f559",
  "skip-forward-btn": "\f55a",
  "skip-forward-circle-fill": "\f55b",
  "skip-forward-circle": "\f55c",
  "skip-forward-fill": "\f55d",
  "skip-forward": "\f55e",
  "skip-start-btn-fill": "\f55f",
  "skip-start-btn": "\f560",
  "skip-start-circle-fill": "\f561",
  "skip-start-circle": "\f562",
  "skip-start-fill": "\f563",
  "skip-start": "\f564",
  "slack": "\f565",
  "slash-circle-fill": "\f566",
  "slash-circle": "\f567",
  "slash-square-fill": "\f568",
  "slash-square": "\f569",
  "slash": "\f56a",
  "sliders": "\f56b",
  "smartwatch": "\f56c",
  "snow": "\f56d",
  "snow2": "\f56e",
  "snow3": "\f56f",
  "sort-alpha-down-alt": "\f570",
  "sort-alpha-down": "\f571",
  "sort-alpha-up-alt": "\f572",
  "sort-alpha-up": "\f573",
  "sort-down-alt": "\f574",
  "sort-down": "\f575",
  "sort-numeric-down-alt": "\f576",
  "sort-numeric-down": "\f577",
  "sort-numeric-up-alt": "\f578",
  "sort-numeric-up": "\f579",
  "sort-up-alt": "\f57a",
  "sort-up": "\f57b",
  "soundwave": "\f57c",
  "speaker-fill": "\f57d",
  "speaker": "\f57e",
  "speedometer": "\f57f",
  "speedometer2": "\f580",
  "spellcheck": "\f581",
  "square-fill": "\f582",
  "square-half": "\f583",
  "square": "\f584",
  "stack": "\f585",
  "star-fill": "\f586",
  "star-half": "\f587",
  "star": "\f588",
  "stars": "\f589",
  "stickies-fill": "\f58a",
  "stickies": "\f58b",
  "sticky-fill": "\f58c",
  "sticky": "\f58d",
  "stop-btn-fill": "\f58e",
  "stop-btn": "\f58f",
  "stop-circle-fill": "\f590",
  "stop-circle": "\f591",
  "stop-fill": "\f592",
  "stop": "\f593",
  "stoplights-fill": "\f594",
  "stoplights": "\f595",
  "stopwatch-fill": "\f596",
  "stopwatch": "\f597",
  "subtract": "\f598",
  "suit-club-fill": "\f599",
  "suit-club": "\f59a",
  "suit-diamond-fill": "\f59b",
  "suit-diamond": "\f59c",
  "suit-heart-fill": "\f59d",
  "suit-heart": "\f59e",
  "suit-spade-fill": "\f59f",
  "suit-spade": "\f5a0",
  "sun-fill": "\f5a1",
  "sun": "\f5a2",
  "sunglasses": "\f5a3",
  "sunrise-fill": "\f5a4",
  "sunrise": "\f5a5",
  "sunset-fill": "\f5a6",
  "sunset": "\f5a7",
  "symmetry-horizontal": "\f5a8",
  "symmetry-vertical": "\f5a9",
  "table": "\f5aa",
  "tablet-fill": "\f5ab",
  "tablet-landscape-fill": "\f5ac",
  "tablet-landscape": "\f5ad",
  "tablet": "\f5ae",
  "tag-fill": "\f5af",
  "tag": "\f5b0",
  "tags-fill": "\f5b1",
  "tags": "\f5b2",
  "telegram": "\f5b3",
  "telephone-fill": "\f5b4",
  "telephone-forward-fill": "\f5b5",
  "telephone-forward": "\f5b6",
  "telephone-inbound-fill": "\f5b7",
  "telephone-inbound": "\f5b8",
  "telephone-minus-fill": "\f5b9",
  "telephone-minus": "\f5ba",
  "telephone-outbound-fill": "\f5bb",
  "telephone-outbound": "\f5bc",
  "telephone-plus-fill": "\f5bd",
  "telephone-plus": "\f5be",
  "telephone-x-fill": "\f5bf",
  "telephone-x": "\f5c0",
  "telephone": "\f5c1",
  "terminal-fill": "\f5c2",
  "terminal": "\f5c3",
  "text-center": "\f5c4",
  "text-indent-left": "\f5c5",
  "text-indent-right": "\f5c6",
  "text-left": "\f5c7",
  "text-paragraph": "\f5c8",
  "text-right": "\f5c9",
  "textarea-resize": "\f5ca",
  "textarea-t": "\f5cb",
  "textarea": "\f5cc",
  "thermometer-half": "\f5cd",
  "thermometer-high": "\f5ce",
  "thermometer-low": "\f5cf",
  "thermometer-snow": "\f5d0",
  "thermometer-sun": "\f5d1",
  "thermometer": "\f5d2",
  "three-dots-vertical": "\f5d3",
  "three-dots": "\f5d4",
  "toggle-off": "\f5d5",
  "toggle-on": "\f5d6",
  "toggle2-off": "\f5d7",
  "toggle2-on": "\f5d8",
  "toggles": "\f5d9",
  "toggles2": "\f5da",
  "tools": "\f5db",
  "tornado": "\f5dc",
  "trash-fill": "\f5dd",
  "trash": "\f5de",
  "trash2-fill": "\f5df",
  "trash2": "\f5e0",
  "tree-fill": "\f5e1",
  "tree": "\f5e2",
  "triangle-fill": "\f5e3",
  "triangle-half": "\f5e4",
  "triangle": "\f5e5",
  "trophy-fill": "\f5e6",
  "trophy": "\f5e7",
  "tropical-storm": "\f5e8",
  "truck-flatbed": "\f5e9",
  "truck": "\f5ea",
  "tsunami": "\f5eb",
  "tv-fill": "\f5ec",
  "tv": "\f5ed",
  "twitch": "\f5ee",
  "twitter": "\f5ef",
  "type-bold": "\f5f0",
  "type-h1": "\f5f1",
  "type-h2": "\f5f2",
  "type-h3": "\f5f3",
  "type-italic": "\f5f4",
  "type-strikethrough": "\f5f5",
  "type-underline": "\f5f6",
  "type": "\f5f7",
  "ui-checks-grid": "\f5f8",
  "ui-checks": "\f5f9",
  "ui-radios-grid": "\f5fa",
  "ui-radios": "\f5fb",
  "umbrella-fill": "\f5fc",
  "umbrella": "\f5fd",
  "union": "\f5fe",
  "unlock-fill": "\f5ff",
  "unlock": "\f600",
  "upc-scan": "\f601",
  "upc": "\f602",
  "upload": "\f603",
  "vector-pen": "\f604",
  "view-list": "\f605",
  "view-stacked": "\f606",
  "vinyl-fill": "\f607",
  "vinyl": "\f608",
  "voicemail": "\f609",
  "volume-down-fill": "\f60a",
  "volume-down": "\f60b",
  "volume-mute-fill": "\f60c",
  "volume-mute": "\f60d",
  "volume-off-fill": "\f60e",
  "volume-off": "\f60f",
  "volume-up-fill": "\f610",
  "volume-up": "\f611",
  "vr": "\f612",
  "wallet-fill": "\f613",
  "wallet": "\f614",
  "wallet2": "\f615",
  "watch": "\f616",
  "water": "\f617",
  "whatsapp": "\f618",
  "wifi-1": "\f619",
  "wifi-2": "\f61a",
  "wifi-off": "\f61b",
  "wifi": "\f61c",
  "wind": "\f61d",
  "window-dock": "\f61e",
  "window-sidebar": "\f61f",
  "window": "\f620",
  "wrench": "\f621",
  "x-circle-fill": "\f622",
  "x-circle": "\f623",
  "x-diamond-fill": "\f624",
  "x-diamond": "\f625",
  "x-octagon-fill": "\f626",
  "x-octagon": "\f627",
  "x-square-fill": "\f628",
  "x-square": "\f629",
  "x": "\f62a",
  "youtube": "\f62b",
  "zoom-in": "\f62c",
  "zoom-out": "\f62d",
  "bank": "\f62e",
  "bank2": "\f62f",
  "bell-slash-fill": "\f630",
  "bell-slash": "\f631",
  "cash-coin": "\f632",
  "check-lg": "\f633",
  "coin": "\f634",
  "currency-bitcoin": "\f635",
  "currency-dollar": "\f636",
  "currency-euro": "\f637",
  "currency-exchange": "\f638",
  "currency-pound": "\f639",
  "currency-yen": "\f63a",
  "dash-lg": "\f63b",
  "exclamation-lg": "\f63c",
  "file-earmark-pdf-fill": "\f63d",
  "file-earmark-pdf": "\f63e",
  "file-pdf-fill": "\f63f",
  "file-pdf": "\f640",
  "gender-ambiguous": "\f641",
  "gender-female": "\f642",
  "gender-male": "\f643",
  "gender-trans": "\f644",
  "headset-vr": "\f645",
  "info-lg": "\f646",
  "mastodon": "\f647",
  "messenger": "\f648",
  "piggy-bank-fill": "\f649",
  "piggy-bank": "\f64a",
  "pin-map-fill": "\f64b",
  "pin-map": "\f64c",
  "plus-lg": "\f64d",
  "question-lg": "\f64e",
  "recycle": "\f64f",
  "reddit": "\f650",
  "safe-fill": "\f651",
  "safe2-fill": "\f652",
  "safe2": "\f653",
  "sd-card-fill": "\f654",
  "sd-card": "\f655",
  "skype": "\f656",
  "slash-lg": "\f657",
  "translate": "\f658",
  "x-lg": "\f659",
  "safe": "\f65a",
  "apple": "\f65b",
  "microsoft": "\f65d",
  "windows": "\f65e",
  "behance": "\f65c",
  "dribbble": "\f65f",
  "line": "\f660",
  "medium": "\f661",
  "paypal": "\f662",
  "pinterest": "\f663",
  "signal": "\f664",
  "snapchat": "\f665",
  "spotify": "\f666",
  "stack-overflow": "\f667",
  "strava": "\f668",
  "wordpress": "\f669",
  "vimeo": "\f66a",
  "activity": "\f66b",
  "easel2-fill": "\f66c",
  "easel2": "\f66d",
  "easel3-fill": "\f66e",
  "easel3": "\f66f",
  "fan": "\f670",
  "fingerprint": "\f671",
  "graph-down-arrow": "\f672",
  "graph-up-arrow": "\f673",
  "hypnotize": "\f674",
  "magic": "\f675",
  "person-rolodex": "\f676",
  "person-video": "\f677",
  "person-video2": "\f678",
  "person-video3": "\f679",
  "person-workspace": "\f67a",
  "radioactive": "\f67b",
  "webcam-fill": "\f67c",
  "webcam": "\f67d",
  "yin-yang": "\f67e",
  "bandaid-fill": "\f680",
  "bandaid": "\f681",
  "bluetooth": "\f682",
  "body-text": "\f683",
  "boombox": "\f684",
  "boxes": "\f685",
  "dpad-fill": "\f686",
  "dpad": "\f687",
  "ear-fill": "\f688",
  "ear": "\f689",
  "envelope-check-1": "\f68a",
  "envelope-check-fill": "\f68b",
  "envelope-check": "\f68c",
  "envelope-dash-1": "\f68d",
  "envelope-dash-fill": "\f68e",
  "envelope-dash": "\f68f",
  "envelope-exclamation-1": "\f690",
  "envelope-exclamation-fill": "\f691",
  "envelope-exclamation": "\f692",
  "envelope-plus-fill": "\f693",
  "envelope-plus": "\f694",
  "envelope-slash-1": "\f695",
  "envelope-slash-fill": "\f696",
  "envelope-slash": "\f697",
  "envelope-x-1": "\f698",
  "envelope-x-fill": "\f699",
  "envelope-x": "\f69a",
  "explicit-fill": "\f69b",
  "explicit": "\f69c",
  "git": "\f69d",
  "infinity": "\f69e",
  "list-columns-reverse": "\f69f",
  "list-columns": "\f6a0",
  "meta": "\f6a1",
  "mortorboard-fill": "\f6a2",
  "mortorboard": "\f6a3",
  "nintendo-switch": "\f6a4",
  "pc-display-horizontal": "\f6a5",
  "pc-display": "\f6a6",
  "pc-horizontal": "\f6a7",
  "pc": "\f6a8",
  "playstation": "\f6a9",
  "plus-slash-minus": "\f6aa",
  "projector-fill": "\f6ab",
  "projector": "\f6ac",
  "qr-code-scan": "\f6ad",
  "qr-code": "\f6ae",
  "quora": "\f6af",
  "quote": "\f6b0",
  "robot": "\f6b1",
  "send-check-fill": "\f6b2",
  "send-check": "\f6b3",
  "send-dash-fill": "\f6b4",
  "send-dash": "\f6b5",
  "send-exclamation-1": "\f6b6",
  "send-exclamation-fill": "\f6b7",
  "send-exclamation": "\f6b8",
  "send-fill": "\f6b9",
  "send-plus-fill": "\f6ba",
  "send-plus": "\f6bb",
  "send-slash-fill": "\f6bc",
  "send-slash": "\f6bd",
  "send-x-fill": "\f6be",
  "send-x": "\f6bf",
  "send": "\f6c0",
  "steam": "\f6c1",
  "terminal-dash-1": "\f6c2",
  "terminal-dash": "\f6c3",
  "terminal-plus": "\f6c4",
  "terminal-split": "\f6c5",
  "ticket-detailed-fill": "\f6c6",
  "ticket-detailed": "\f6c7",
  "ticket-fill": "\f6c8",
  "ticket-perforated-fill": "\f6c9",
  "ticket-perforated": "\f6ca",
  "ticket": "\f6cb",
  "tiktok": "\f6cc",
  "window-dash": "\f6cd",
  "window-desktop": "\f6ce",
  "window-fullscreen": "\f6cf",
  "window-plus": "\f6d0",
  "window-split": "\f6d1",
  "window-stack": "\f6d2",
  "window-x": "\f6d3",
  "xbox": "\f6d4",
  "ethernet": "\f6d5",
  "hdmi-fill": "\f6d6",
  "hdmi": "\f6d7",
  "usb-c-fill": "\f6d8",
  "usb-c": "\f6d9",
  "usb-fill": "\f6da",
  "usb-plug-fill": "\f6db",
  "usb-plug": "\f6dc",
  "usb-symbol": "\f6dd",
  "usb": "\f6de",
  "boombox-fill": "\f6df",
  "displayport-1": "\f6e0",
  "displayport": "\f6e1",
  "gpu-card": "\f6e2",
  "memory": "\f6e3",
  "modem-fill": "\f6e4",
  "modem": "\f6e5",
  "motherboard-fill": "\f6e6",
  "motherboard": "\f6e7",
  "optical-audio-fill": "\f6e8",
  "optical-audio": "\f6e9",
  "pci-card": "\f6ea",
  "router-fill": "\f6eb",
  "router": "\f6ec",
  "ssd-fill": "\f6ed",
  "ssd": "\f6ee",
  "thunderbolt-fill": "\f6ef",
  "thunderbolt": "\f6f0",
  "usb-drive-fill": "\f6f1",
  "usb-drive": "\f6f2",
  "usb-micro-fill": "\f6f3",
  "usb-micro": "\f6f4",
  "usb-mini-fill": "\f6f5",
  "usb-mini": "\f6f6",
  "cloud-haze2": "\f6f7",
  "device-hdd-fill": "\f6f8",
  "device-hdd": "\f6f9",
  "device-ssd-fill": "\f6fa",
  "device-ssd": "\f6fb",
  "displayport-fill": "\f6fc",
  "mortarboard-fill": "\f6fd",
  "mortarboard": "\f6fe",
  "terminal-x": "\f6ff",
  "arrow-through-heart-fill": "\f700",
  "arrow-through-heart": "\f701",
  "badge-sd-fill": "\f702",
  "badge-sd": "\f703",
  "bag-heart-fill": "\f704",
  "bag-heart": "\f705",
  "balloon-fill": "\f706",
  "balloon-heart-fill": "\f707",
  "balloon-heart": "\f708",
  "balloon": "\f709",
  "box2-fill": "\f70a",
  "box2-heart-fill": "\f70b",
  "box2-heart": "\f70c",
  "box2": "\f70d",
  "braces-asterisk": "\f70e",
  "calendar-heart-fill": "\f70f",
  "calendar-heart": "\f710",
  "calendar2-heart-fill": "\f711",
  "calendar2-heart": "\f712",
  "chat-heart-fill": "\f713",
  "chat-heart": "\f714",
  "chat-left-heart-fill": "\f715",
  "chat-left-heart": "\f716",
  "chat-right-heart-fill": "\f717",
  "chat-right-heart": "\f718",
  "chat-square-heart-fill": "\f719",
  "chat-square-heart": "\f71a",
  "clipboard-check-fill": "\f71b",
  "clipboard-data-fill": "\f71c",
  "clipboard-fill": "\f71d",
  "clipboard-heart-fill": "\f71e",
  "clipboard-heart": "\f71f",
  "clipboard-minus-fill": "\f720",
  "clipboard-plus-fill": "\f721",
  "clipboard-pulse": "\f722",
  "clipboard-x-fill": "\f723",
  "clipboard2-check-fill": "\f724",
  "clipboard2-check": "\f725",
  "clipboard2-data-fill": "\f726",
  "clipboard2-data": "\f727",
  "clipboard2-fill": "\f728",
  "clipboard2-heart-fill": "\f729",
  "clipboard2-heart": "\f72a",
  "clipboard2-minus-fill": "\f72b",
  "clipboard2-minus": "\f72c",
  "clipboard2-plus-fill": "\f72d",
  "clipboard2-plus": "\f72e",
  "clipboard2-pulse-fill": "\f72f",
  "clipboard2-pulse": "\f730",
  "clipboard2-x-fill": "\f731",
  "clipboard2-x": "\f732",
  "clipboard2": "\f733",
  "emoji-kiss-fill": "\f734",
  "emoji-kiss": "\f735",
  "envelope-heart-fill": "\f736",
  "envelope-heart": "\f737",
  "envelope-open-heart-fill": "\f738",
  "envelope-open-heart": "\f739",
  "envelope-paper-fill": "\f73a",
  "envelope-paper-heart-fill": "\f73b",
  "envelope-paper-heart": "\f73c",
  "envelope-paper": "\f73d",
  "filetype-aac": "\f73e",
  "filetype-ai": "\f73f",
  "filetype-bmp": "\f740",
  "filetype-cs": "\f741",
  "filetype-css": "\f742",
  "filetype-csv": "\f743",
  "filetype-doc": "\f744",
  "filetype-docx": "\f745",
  "filetype-exe": "\f746",
  "filetype-gif": "\f747",
  "filetype-heic": "\f748",
  "filetype-html": "\f749",
  "filetype-java": "\f74a",
  "filetype-jpg": "\f74b",
  "filetype-js": "\f74c",
  "filetype-jsx": "\f74d",
  "filetype-key": "\f74e",
  "filetype-m4p": "\f74f",
  "filetype-md": "\f750",
  "filetype-mdx": "\f751",
  "filetype-mov": "\f752",
  "filetype-mp3": "\f753",
  "filetype-mp4": "\f754",
  "filetype-otf": "\f755",
  "filetype-pdf": "\f756",
  "filetype-php": "\f757",
  "filetype-png": "\f758",
  "filetype-ppt-1": "\f759",
  "filetype-ppt": "\f75a",
  "filetype-psd": "\f75b",
  "filetype-py": "\f75c",
  "filetype-raw": "\f75d",
  "filetype-rb": "\f75e",
  "filetype-sass": "\f75f",
  "filetype-scss": "\f760",
  "filetype-sh": "\f761",
  "filetype-svg": "\f762",
  "filetype-tiff": "\f763",
  "filetype-tsx": "\f764",
  "filetype-ttf": "\f765",
  "filetype-txt": "\f766",
  "filetype-wav": "\f767",
  "filetype-woff": "\f768",
  "filetype-xls-1": "\f769",
  "filetype-xls": "\f76a",
  "filetype-xml": "\f76b",
  "filetype-yml": "\f76c",
  "heart-arrow": "\f76d",
  "heart-pulse-fill": "\f76e",
  "heart-pulse": "\f76f",
  "heartbreak-fill": "\f770",
  "heartbreak": "\f771",
  "hearts": "\f772",
  "hospital-fill": "\f773",
  "hospital": "\f774",
  "house-heart-fill": "\f775",
  "house-heart": "\f776",
  "incognito": "\f777",
  "magnet-fill": "\f778",
  "magnet": "\f779",
  "person-heart": "\f77a",
  "person-hearts": "\f77b",
  "phone-flip": "\f77c",
  "plugin": "\f77d",
  "postage-fill": "\f77e",
  "postage-heart-fill": "\f77f",
  "postage-heart": "\f780",
  "postage": "\f781",
  "postcard-fill": "\f782",
  "postcard-heart-fill": "\f783",
  "postcard-heart": "\f784",
  "postcard": "\f785",
  "search-heart-fill": "\f786",
  "search-heart": "\f787",
  "sliders2-vertical": "\f788",
  "sliders2": "\f789",
  "trash3-fill": "\f78a",
  "trash3": "\f78b",
  "valentine": "\f78c",
  "valentine2": "\f78d",
  "wrench-adjustable-circle-fill": "\f78e",
  "wrench-adjustable-circle": "\f78f",
  "wrench-adjustable": "\f790",
  "filetype-json": "\f791",
  "filetype-pptx": "\f792",
  "filetype-xlsx": "\f793",
  "1-circle-1": "\f794",
  "1-circle-fill-1": "\f795",
  "1-circle-fill": "\f796",
  "1-circle": "\f797",
  "1-square-fill": "\f798",
  "1-square": "\f799",
  "2-circle-1": "\f79a",
  "2-circle-fill-1": "\f79b",
  "2-circle-fill": "\f79c",
  "2-circle": "\f79d",
  "2-square-fill": "\f79e",
  "2-square": "\f79f",
  "3-circle-1": "\f7a0",
  "3-circle-fill-1": "\f7a1",
  "3-circle-fill": "\f7a2",
  "3-circle": "\f7a3",
  "3-square-fill": "\f7a4",
  "3-square": "\f7a5",
  "4-circle-1": "\f7a6",
  "4-circle-fill-1": "\f7a7",
  "4-circle-fill": "\f7a8",
  "4-circle": "\f7a9",
  "4-square-fill": "\f7aa",
  "4-square": "\f7ab",
  "5-circle-1": "\f7ac",
  "5-circle-fill-1": "\f7ad",
  "5-circle-fill": "\f7ae",
  "5-circle": "\f7af",
  "5-square-fill": "\f7b0",
  "5-square": "\f7b1",
  "6-circle-1": "\f7b2",
  "6-circle-fill-1": "\f7b3",
  "6-circle-fill": "\f7b4",
  "6-circle": "\f7b5",
  "6-square-fill": "\f7b6",
  "6-square": "\f7b7",
  "7-circle-1": "\f7b8",
  "7-circle-fill-1": "\f7b9",
  "7-circle-fill": "\f7ba",
  "7-circle": "\f7bb",
  "7-square-fill": "\f7bc",
  "7-square": "\f7bd",
  "8-circle-1": "\f7be",
  "8-circle-fill-1": "\f7bf",
  "8-circle-fill": "\f7c0",
  "8-circle": "\f7c1",
  "8-square-fill": "\f7c2",
  "8-square": "\f7c3",
  "9-circle-1": "\f7c4",
  "9-circle-fill-1": "\f7c5",
  "9-circle-fill": "\f7c6",
  "9-circle": "\f7c7",
  "9-square-fill": "\f7c8",
  "9-square": "\f7c9",
  "airplane-engines-fill": "\f7ca",
  "airplane-engines": "\f7cb",
  "airplane-fill": "\f7cc",
  "airplane": "\f7cd",
  "alexa": "\f7ce",
  "alipay": "\f7cf",
  "android": "\f7d0",
  "android2": "\f7d1",
  "box-fill": "\f7d2",
  "box-seam-fill": "\f7d3",
  "browser-chrome": "\f7d4",
  "browser-edge": "\f7d5",
  "browser-firefox": "\f7d6",
  "browser-safari": "\f7d7",
  "c-circle-1": "\f7d8",
  "c-circle-fill-1": "\f7d9",
  "c-circle-fill": "\f7da",
  "c-circle": "\f7db",
  "c-square-fill": "\f7dc",
  "c-square": "\f7dd",
  "capsule-pill": "\f7de",
  "capsule": "\f7df",
  "car-front-fill": "\f7e0",
  "car-front": "\f7e1",
  "cassette-fill": "\f7e2",
  "cassette": "\f7e3",
  "cc-circle-1": "\f7e4",
  "cc-circle-fill-1": "\f7e5",
  "cc-circle-fill": "\f7e6",
  "cc-circle": "\f7e7",
  "cc-square-fill": "\f7e8",
  "cc-square": "\f7e9",
  "cup-hot-fill": "\f7ea",
  "cup-hot": "\f7eb",
  "currency-rupee": "\f7ec",
  "dropbox": "\f7ed",
  "escape": "\f7ee",
  "fast-forward-btn-fill": "\f7ef",
  "fast-forward-btn": "\f7f0",
  "fast-forward-circle-fill": "\f7f1",
  "fast-forward-circle": "\f7f2",
  "fast-forward-fill": "\f7f3",
  "fast-forward": "\f7f4",
  "filetype-sql": "\f7f5",
  "fire": "\f7f6",
  "google-play": "\f7f7",
  "h-circle-1": "\f7f8",
  "h-circle-fill-1": "\f7f9",
  "h-circle-fill": "\f7fa",
  "h-circle": "\f7fb",
  "h-square-fill": "\f7fc",
  "h-square": "\f7fd",
  "indent": "\f7fe",
  "lungs-fill": "\f7ff",
  "lungs": "\f800",
  "microsoft-teams": "\f801",
  "p-circle-1": "\f802",
  "p-circle-fill-1": "\f803",
  "p-circle-fill": "\f804",
  "p-circle": "\f805",
  "p-square-fill": "\f806",
  "p-square": "\f807",
  "pass-fill": "\f808",
  "pass": "\f809",
  "prescription": "\f80a",
  "prescription2": "\f80b",
  "r-circle-1": "\f80c",
  "r-circle-fill-1": "\f80d",
  "r-circle-fill": "\f80e",
  "r-circle": "\f80f",
  "r-square-fill": "\f810",
  "r-square": "\f811",
  "repeat-1": "\f812",
  "repeat": "\f813",
  "rewind-btn-fill": "\f814",
  "rewind-btn": "\f815",
  "rewind-circle-fill": "\f816",
  "rewind-circle": "\f817",
  "rewind-fill": "\f818",
  "rewind": "\f819",
  "train-freight-front-fill": "\f81a",
  "train-freight-front": "\f81b",
  "train-front-fill": "\f81c",
  "train-front": "\f81d",
  "train-lightrail-front-fill": "\f81e",
  "train-lightrail-front": "\f81f",
  "truck-front-fill": "\f820",
  "truck-front": "\f821",
  "ubuntu": "\f822",
  "unindent": "\f823",
  "unity": "\f824",
  "universal-access-circle": "\f825",
  "universal-access": "\f826",
  "virus": "\f827",
  "virus2": "\f828",
  "wechat": "\f829",
  "yelp": "\f82a",
  "sign-stop-fill": "\f82b",
  "sign-stop-lights-fill": "\f82c",
  "sign-stop-lights": "\f82d",
  "sign-stop": "\f82e",
  "sign-turn-left-fill": "\f82f",
  "sign-turn-left": "\f830",
  "sign-turn-right-fill": "\f831",
  "sign-turn-right": "\f832",
  "sign-turn-slight-left-fill": "\f833",
  "sign-turn-slight-left": "\f834",
  "sign-turn-slight-right-fill": "\f835",
  "sign-turn-slight-right": "\f836",
  "sign-yield-fill": "\f837",
  "sign-yield": "\f838",
  "ev-station-fill": "\f839",
  "ev-station": "\f83a",
  "fuel-pump-diesel-fill": "\f83b",
  "fuel-pump-diesel": "\f83c",
  "fuel-pump-fill": "\f83d",
  "fuel-pump": "\f83e",
  "0-circle-fill": "\f83f",
  "0-circle": "\f840",
  "0-square-fill": "\f841",
  "0-square": "\f842",
  "rocket-fill": "\f843",
  "rocket-takeoff-fill": "\f844",
  "rocket-takeoff": "\f845",
  "rocket": "\f846",
  "stripe": "\f847",
  "subscript": "\f848",
  "superscript": "\f849",
  "trello": "\f84a",
  "envelope-at-fill": "\f84b",
  "envelope-at": "\f84c",
  "regex": "\f84d",
  "text-wrap": "\f84e",
  "sign-dead-end-fill": "\f84f",
  "sign-dead-end": "\f850",
  "sign-do-not-enter-fill": "\f851",
  "sign-do-not-enter": "\f852",
  "sign-intersection-fill": "\f853",
  "sign-intersection-side-fill": "\f854",
  "sign-intersection-side": "\f855",
  "sign-intersection-t-fill": "\f856",
  "sign-intersection-t": "\f857",
  "sign-intersection-y-fill": "\f858",
  "sign-intersection-y": "\f859",
  "sign-intersection": "\f85a",
  "sign-merge-left-fill": "\f85b",
  "sign-merge-left": "\f85c",
  "sign-merge-right-fill": "\f85d",
  "sign-merge-right": "\f85e",
  "sign-no-left-turn-fill": "\f85f",
  "sign-no-left-turn": "\f860",
  "sign-no-parking-fill": "\f861",
  "sign-no-parking": "\f862",
  "sign-no-right-turn-fill": "\f863",
  "sign-no-right-turn": "\f864",
  "sign-railroad-fill": "\f865",
  "sign-railroad": "\f866",
  "building-add": "\f867",
  "building-check": "\f868",
  "building-dash": "\f869",
  "building-down": "\f86a",
  "building-exclamation": "\f86b",
  "building-fill-add": "\f86c",
  "building-fill-check": "\f86d",
  "building-fill-dash": "\f86e",
  "building-fill-down": "\f86f",
  "building-fill-exclamation": "\f870",
  "building-fill-gear": "\f871",
  "building-fill-lock": "\f872",
  "building-fill-slash": "\f873",
  "building-fill-up": "\f874",
  "building-fill-x": "\f875",
  "building-fill": "\f876",
  "building-gear": "\f877",
  "building-lock": "\f878",
  "building-slash": "\f879",
  "building-up": "\f87a",
  "building-x": "\f87b",
  "buildings-fill": "\f87c",
  "buildings": "\f87d",
  "bus-front-fill": "\f87e",
  "bus-front": "\f87f",
  "ev-front-fill": "\f880",
  "ev-front": "\f881",
  "globe-americas": "\f882",
  "globe-asia-australia": "\f883",
  "globe-central-south-asia": "\f884",
  "globe-europe-africa": "\f885",
  "house-add-fill": "\f886",
  "house-add": "\f887",
  "house-check-fill": "\f888",
  "house-check": "\f889",
  "house-dash-fill": "\f88a",
  "house-dash": "\f88b",
  "house-down-fill": "\f88c",
  "house-down": "\f88d",
  "house-exclamation-fill": "\f88e",
  "house-exclamation": "\f88f",
  "house-gear-fill": "\f890",
  "house-gear": "\f891",
  "house-lock-fill": "\f892",
  "house-lock": "\f893",
  "house-slash-fill": "\f894",
  "house-slash": "\f895",
  "house-up-fill": "\f896",
  "house-up": "\f897",
  "house-x-fill": "\f898",
  "house-x": "\f899",
  "person-add": "\f89a",
  "person-down": "\f89b",
  "person-exclamation": "\f89c",
  "person-fill-add": "\f89d",
  "person-fill-check": "\f89e",
  "person-fill-dash": "\f89f",
  "person-fill-down": "\f8a0",
  "person-fill-exclamation": "\f8a1",
  "person-fill-gear": "\f8a2",
  "person-fill-lock": "\f8a3",
  "person-fill-slash": "\f8a4",
  "person-fill-up": "\f8a5",
  "person-fill-x": "\f8a6",
  "person-gear": "\f8a7",
  "person-lock": "\f8a8",
  "person-slash": "\f8a9",
  "person-up": "\f8aa",
  "scooter": "\f8ab",
  "taxi-front-fill": "\f8ac",
  "taxi-front": "\f8ad",
  "amd": "\f8ae",
  "database-add": "\f8af",
  "database-check": "\f8b0",
  "database-dash": "\f8b1",
  "database-down": "\f8b2",
  "database-exclamation": "\f8b3",
  "database-fill-add": "\f8b4",
  "database-fill-check": "\f8b5",
  "database-fill-dash": "\f8b6",
  "database-fill-down": "\f8b7",
  "database-fill-exclamation": "\f8b8",
  "database-fill-gear": "\f8b9",
  "database-fill-lock": "\f8ba",
  "database-fill-slash": "\f8bb",
  "database-fill-up": "\f8bc",
  "database-fill-x": "\f8bd",
  "database-fill": "\f8be",
  "database-gear": "\f8bf",
  "database-lock": "\f8c0",
  "database-slash": "\f8c1",
  "database-up": "\f8c2",
  "database-x": "\f8c3",
  "database": "\f8c4",
  "houses-fill": "\f8c5",
  "houses": "\f8c6",
  "nvidia": "\f8c7",
  "person-vcard-fill": "\f8c8",
  "person-vcard": "\f8c9",
  "sina-weibo": "\f8ca",
  "tencent-qq": "\f8cb",
  "wikipedia": "\f8cc",
);

.bi-123::before { content: map-get($bootstrap-icons-map, "123"); }
.bi-alarm-fill::before { content: map-get($bootstrap-icons-map, "alarm-fill"); }
.bi-alarm::before { content: map-get($bootstrap-icons-map, "alarm"); }
.bi-align-bottom::before { content: map-get($bootstrap-icons-map, "align-bottom"); }
.bi-align-center::before { content: map-get($bootstrap-icons-map, "align-center"); }
.bi-align-end::before { content: map-get($bootstrap-icons-map, "align-end"); }
.bi-align-middle::before { content: map-get($bootstrap-icons-map, "align-middle"); }
.bi-align-start::before { content: map-get($bootstrap-icons-map, "align-start"); }
.bi-align-top::before { content: map-get($bootstrap-icons-map, "align-top"); }
.bi-alt::before { content: map-get($bootstrap-icons-map, "alt"); }
.bi-app-indicator::before { content: map-get($bootstrap-icons-map, "app-indicator"); }
.bi-app::before { content: map-get($bootstrap-icons-map, "app"); }
.bi-archive-fill::before { content: map-get($bootstrap-icons-map, "archive-fill"); }
.bi-archive::before { content: map-get($bootstrap-icons-map, "archive"); }
.bi-arrow-90deg-down::before { content: map-get($bootstrap-icons-map, "arrow-90deg-down"); }
.bi-arrow-90deg-left::before { content: map-get($bootstrap-icons-map, "arrow-90deg-left"); }
.bi-arrow-90deg-right::before { content: map-get($bootstrap-icons-map, "arrow-90deg-right"); }
.bi-arrow-90deg-up::before { content: map-get($bootstrap-icons-map, "arrow-90deg-up"); }
.bi-arrow-bar-down::before { content: map-get($bootstrap-icons-map, "arrow-bar-down"); }
.bi-arrow-bar-left::before { content: map-get($bootstrap-icons-map, "arrow-bar-left"); }
.bi-arrow-bar-right::before { content: map-get($bootstrap-icons-map, "arrow-bar-right"); }
.bi-arrow-bar-up::before { content: map-get($bootstrap-icons-map, "arrow-bar-up"); }
.bi-arrow-clockwise::before { content: map-get($bootstrap-icons-map, "arrow-clockwise"); }
.bi-arrow-counterclockwise::before { content: map-get($bootstrap-icons-map, "arrow-counterclockwise"); }
.bi-arrow-down-circle-fill::before { content: map-get($bootstrap-icons-map, "arrow-down-circle-fill"); }
.bi-arrow-down-circle::before { content: map-get($bootstrap-icons-map, "arrow-down-circle"); }
.bi-arrow-down-left-circle-fill::before { content: map-get($bootstrap-icons-map, "arrow-down-left-circle-fill"); }
.bi-arrow-down-left-circle::before { content: map-get($bootstrap-icons-map, "arrow-down-left-circle"); }
.bi-arrow-down-left-square-fill::before { content: map-get($bootstrap-icons-map, "arrow-down-left-square-fill"); }
.bi-arrow-down-left-square::before { content: map-get($bootstrap-icons-map, "arrow-down-left-square"); }
.bi-arrow-down-left::before { content: map-get($bootstrap-icons-map, "arrow-down-left"); }
.bi-arrow-down-right-circle-fill::before { content: map-get($bootstrap-icons-map, "arrow-down-right-circle-fill"); }
.bi-arrow-down-right-circle::before { content: map-get($bootstrap-icons-map, "arrow-down-right-circle"); }
.bi-arrow-down-right-square-fill::before { content: map-get($bootstrap-icons-map, "arrow-down-right-square-fill"); }
.bi-arrow-down-right-square::before { content: map-get($bootstrap-icons-map, "arrow-down-right-square"); }
.bi-arrow-down-right::before { content: map-get($bootstrap-icons-map, "arrow-down-right"); }
.bi-arrow-down-short::before { content: map-get($bootstrap-icons-map, "arrow-down-short"); }
.bi-arrow-down-square-fill::before { content: map-get($bootstrap-icons-map, "arrow-down-square-fill"); }
.bi-arrow-down-square::before { content: map-get($bootstrap-icons-map, "arrow-down-square"); }
.bi-arrow-down-up::before { content: map-get($bootstrap-icons-map, "arrow-down-up"); }
.bi-arrow-down::before { content: map-get($bootstrap-icons-map, "arrow-down"); }
.bi-arrow-left-circle-fill::before { content: map-get($bootstrap-icons-map, "arrow-left-circle-fill"); }
.bi-arrow-left-circle::before { content: map-get($bootstrap-icons-map, "arrow-left-circle"); }
.bi-arrow-left-right::before { content: map-get($bootstrap-icons-map, "arrow-left-right"); }
.bi-arrow-left-short::before { content: map-get($bootstrap-icons-map, "arrow-left-short"); }
.bi-arrow-left-square-fill::before { content: map-get($bootstrap-icons-map, "arrow-left-square-fill"); }
.bi-arrow-left-square::before { content: map-get($bootstrap-icons-map, "arrow-left-square"); }
.bi-arrow-left::before { content: map-get($bootstrap-icons-map, "arrow-left"); }
.bi-arrow-repeat::before { content: map-get($bootstrap-icons-map, "arrow-repeat"); }
.bi-arrow-return-left::before { content: map-get($bootstrap-icons-map, "arrow-return-left"); }
.bi-arrow-return-right::before { content: map-get($bootstrap-icons-map, "arrow-return-right"); }
.bi-arrow-right-circle-fill::before { content: map-get($bootstrap-icons-map, "arrow-right-circle-fill"); }
.bi-arrow-right-circle::before { content: map-get($bootstrap-icons-map, "arrow-right-circle"); }
.bi-arrow-right-short::before { content: map-get($bootstrap-icons-map, "arrow-right-short"); }
.bi-arrow-right-square-fill::before { content: map-get($bootstrap-icons-map, "arrow-right-square-fill"); }
.bi-arrow-right-square::before { content: map-get($bootstrap-icons-map, "arrow-right-square"); }
.bi-arrow-right::before { content: map-get($bootstrap-icons-map, "arrow-right"); }
.bi-arrow-up-circle-fill::before { content: map-get($bootstrap-icons-map, "arrow-up-circle-fill"); }
.bi-arrow-up-circle::before { content: map-get($bootstrap-icons-map, "arrow-up-circle"); }
.bi-arrow-up-left-circle-fill::before { content: map-get($bootstrap-icons-map, "arrow-up-left-circle-fill"); }
.bi-arrow-up-left-circle::before { content: map-get($bootstrap-icons-map, "arrow-up-left-circle"); }
.bi-arrow-up-left-square-fill::before { content: map-get($bootstrap-icons-map, "arrow-up-left-square-fill"); }
.bi-arrow-up-left-square::before { content: map-get($bootstrap-icons-map, "arrow-up-left-square"); }
.bi-arrow-up-left::before { content: map-get($bootstrap-icons-map, "arrow-up-left"); }
.bi-arrow-up-right-circle-fill::before { content: map-get($bootstrap-icons-map, "arrow-up-right-circle-fill"); }
.bi-arrow-up-right-circle::before { content: map-get($bootstrap-icons-map, "arrow-up-right-circle"); }
.bi-arrow-up-right-square-fill::before { content: map-get($bootstrap-icons-map, "arrow-up-right-square-fill"); }
.bi-arrow-up-right-square::before { content: map-get($bootstrap-icons-map, "arrow-up-right-square"); }
.bi-arrow-up-right::before { content: map-get($bootstrap-icons-map, "arrow-up-right"); }
.bi-arrow-up-short::before { content: map-get($bootstrap-icons-map, "arrow-up-short"); }
.bi-arrow-up-square-fill::before { content: map-get($bootstrap-icons-map, "arrow-up-square-fill"); }
.bi-arrow-up-square::before { content: map-get($bootstrap-icons-map, "arrow-up-square"); }
.bi-arrow-up::before { content: map-get($bootstrap-icons-map, "arrow-up"); }
.bi-arrows-angle-contract::before { content: map-get($bootstrap-icons-map, "arrows-angle-contract"); }
.bi-arrows-angle-expand::before { content: map-get($bootstrap-icons-map, "arrows-angle-expand"); }
.bi-arrows-collapse::before { content: map-get($bootstrap-icons-map, "arrows-collapse"); }
.bi-arrows-expand::before { content: map-get($bootstrap-icons-map, "arrows-expand"); }
.bi-arrows-fullscreen::before { content: map-get($bootstrap-icons-map, "arrows-fullscreen"); }
.bi-arrows-move::before { content: map-get($bootstrap-icons-map, "arrows-move"); }
.bi-aspect-ratio-fill::before { content: map-get($bootstrap-icons-map, "aspect-ratio-fill"); }
.bi-aspect-ratio::before { content: map-get($bootstrap-icons-map, "aspect-ratio"); }
.bi-asterisk::before { content: map-get($bootstrap-icons-map, "asterisk"); }
.bi-at::before { content: map-get($bootstrap-icons-map, "at"); }
.bi-award-fill::before { content: map-get($bootstrap-icons-map, "award-fill"); }
.bi-award::before { content: map-get($bootstrap-icons-map, "award"); }
.bi-back::before { content: map-get($bootstrap-icons-map, "back"); }
.bi-backspace-fill::before { content: map-get($bootstrap-icons-map, "backspace-fill"); }
.bi-backspace-reverse-fill::before { content: map-get($bootstrap-icons-map, "backspace-reverse-fill"); }
.bi-backspace-reverse::before { content: map-get($bootstrap-icons-map, "backspace-reverse"); }
.bi-backspace::before { content: map-get($bootstrap-icons-map, "backspace"); }
.bi-badge-3d-fill::before { content: map-get($bootstrap-icons-map, "badge-3d-fill"); }
.bi-badge-3d::before { content: map-get($bootstrap-icons-map, "badge-3d"); }
.bi-badge-4k-fill::before { content: map-get($bootstrap-icons-map, "badge-4k-fill"); }
.bi-badge-4k::before { content: map-get($bootstrap-icons-map, "badge-4k"); }
.bi-badge-8k-fill::before { content: map-get($bootstrap-icons-map, "badge-8k-fill"); }
.bi-badge-8k::before { content: map-get($bootstrap-icons-map, "badge-8k"); }
.bi-badge-ad-fill::before { content: map-get($bootstrap-icons-map, "badge-ad-fill"); }
.bi-badge-ad::before { content: map-get($bootstrap-icons-map, "badge-ad"); }
.bi-badge-ar-fill::before { content: map-get($bootstrap-icons-map, "badge-ar-fill"); }
.bi-badge-ar::before { content: map-get($bootstrap-icons-map, "badge-ar"); }
.bi-badge-cc-fill::before { content: map-get($bootstrap-icons-map, "badge-cc-fill"); }
.bi-badge-cc::before { content: map-get($bootstrap-icons-map, "badge-cc"); }
.bi-badge-hd-fill::before { content: map-get($bootstrap-icons-map, "badge-hd-fill"); }
.bi-badge-hd::before { content: map-get($bootstrap-icons-map, "badge-hd"); }
.bi-badge-tm-fill::before { content: map-get($bootstrap-icons-map, "badge-tm-fill"); }
.bi-badge-tm::before { content: map-get($bootstrap-icons-map, "badge-tm"); }
.bi-badge-vo-fill::before { content: map-get($bootstrap-icons-map, "badge-vo-fill"); }
.bi-badge-vo::before { content: map-get($bootstrap-icons-map, "badge-vo"); }
.bi-badge-vr-fill::before { content: map-get($bootstrap-icons-map, "badge-vr-fill"); }
.bi-badge-vr::before { content: map-get($bootstrap-icons-map, "badge-vr"); }
.bi-badge-wc-fill::before { content: map-get($bootstrap-icons-map, "badge-wc-fill"); }
.bi-badge-wc::before { content: map-get($bootstrap-icons-map, "badge-wc"); }
.bi-bag-check-fill::before { content: map-get($bootstrap-icons-map, "bag-check-fill"); }
.bi-bag-check::before { content: map-get($bootstrap-icons-map, "bag-check"); }
.bi-bag-dash-fill::before { content: map-get($bootstrap-icons-map, "bag-dash-fill"); }
.bi-bag-dash::before { content: map-get($bootstrap-icons-map, "bag-dash"); }
.bi-bag-fill::before { content: map-get($bootstrap-icons-map, "bag-fill"); }
.bi-bag-plus-fill::before { content: map-get($bootstrap-icons-map, "bag-plus-fill"); }
.bi-bag-plus::before { content: map-get($bootstrap-icons-map, "bag-plus"); }
.bi-bag-x-fill::before { content: map-get($bootstrap-icons-map, "bag-x-fill"); }
.bi-bag-x::before { content: map-get($bootstrap-icons-map, "bag-x"); }
.bi-bag::before { content: map-get($bootstrap-icons-map, "bag"); }
.bi-bar-chart-fill::before { content: map-get($bootstrap-icons-map, "bar-chart-fill"); }
.bi-bar-chart-line-fill::before { content: map-get($bootstrap-icons-map, "bar-chart-line-fill"); }
.bi-bar-chart-line::before { content: map-get($bootstrap-icons-map, "bar-chart-line"); }
.bi-bar-chart-steps::before { content: map-get($bootstrap-icons-map, "bar-chart-steps"); }
.bi-bar-chart::before { content: map-get($bootstrap-icons-map, "bar-chart"); }
.bi-basket-fill::before { content: map-get($bootstrap-icons-map, "basket-fill"); }
.bi-basket::before { content: map-get($bootstrap-icons-map, "basket"); }
.bi-basket2-fill::before { content: map-get($bootstrap-icons-map, "basket2-fill"); }
.bi-basket2::before { content: map-get($bootstrap-icons-map, "basket2"); }
.bi-basket3-fill::before { content: map-get($bootstrap-icons-map, "basket3-fill"); }
.bi-basket3::before { content: map-get($bootstrap-icons-map, "basket3"); }
.bi-battery-charging::before { content: map-get($bootstrap-icons-map, "battery-charging"); }
.bi-battery-full::before { content: map-get($bootstrap-icons-map, "battery-full"); }
.bi-battery-half::before { content: map-get($bootstrap-icons-map, "battery-half"); }
.bi-battery::before { content: map-get($bootstrap-icons-map, "battery"); }
.bi-bell-fill::before { content: map-get($bootstrap-icons-map, "bell-fill"); }
.bi-bell::before { content: map-get($bootstrap-icons-map, "bell"); }
.bi-bezier::before { content: map-get($bootstrap-icons-map, "bezier"); }
.bi-bezier2::before { content: map-get($bootstrap-icons-map, "bezier2"); }
.bi-bicycle::before { content: map-get($bootstrap-icons-map, "bicycle"); }
.bi-binoculars-fill::before { content: map-get($bootstrap-icons-map, "binoculars-fill"); }
.bi-binoculars::before { content: map-get($bootstrap-icons-map, "binoculars"); }
.bi-blockquote-left::before { content: map-get($bootstrap-icons-map, "blockquote-left"); }
.bi-blockquote-right::before { content: map-get($bootstrap-icons-map, "blockquote-right"); }
.bi-book-fill::before { content: map-get($bootstrap-icons-map, "book-fill"); }
.bi-book-half::before { content: map-get($bootstrap-icons-map, "book-half"); }
.bi-book::before { content: map-get($bootstrap-icons-map, "book"); }
.bi-bookmark-check-fill::before { content: map-get($bootstrap-icons-map, "bookmark-check-fill"); }
.bi-bookmark-check::before { content: map-get($bootstrap-icons-map, "bookmark-check"); }
.bi-bookmark-dash-fill::before { content: map-get($bootstrap-icons-map, "bookmark-dash-fill"); }
.bi-bookmark-dash::before { content: map-get($bootstrap-icons-map, "bookmark-dash"); }
.bi-bookmark-fill::before { content: map-get($bootstrap-icons-map, "bookmark-fill"); }
.bi-bookmark-heart-fill::before { content: map-get($bootstrap-icons-map, "bookmark-heart-fill"); }
.bi-bookmark-heart::before { content: map-get($bootstrap-icons-map, "bookmark-heart"); }
.bi-bookmark-plus-fill::before { content: map-get($bootstrap-icons-map, "bookmark-plus-fill"); }
.bi-bookmark-plus::before { content: map-get($bootstrap-icons-map, "bookmark-plus"); }
.bi-bookmark-star-fill::before { content: map-get($bootstrap-icons-map, "bookmark-star-fill"); }
.bi-bookmark-star::before { content: map-get($bootstrap-icons-map, "bookmark-star"); }
.bi-bookmark-x-fill::before { content: map-get($bootstrap-icons-map, "bookmark-x-fill"); }
.bi-bookmark-x::before { content: map-get($bootstrap-icons-map, "bookmark-x"); }
.bi-bookmark::before { content: map-get($bootstrap-icons-map, "bookmark"); }
.bi-bookmarks-fill::before { content: map-get($bootstrap-icons-map, "bookmarks-fill"); }
.bi-bookmarks::before { content: map-get($bootstrap-icons-map, "bookmarks"); }
.bi-bookshelf::before { content: map-get($bootstrap-icons-map, "bookshelf"); }
.bi-bootstrap-fill::before { content: map-get($bootstrap-icons-map, "bootstrap-fill"); }
.bi-bootstrap-reboot::before { content: map-get($bootstrap-icons-map, "bootstrap-reboot"); }
.bi-bootstrap::before { content: map-get($bootstrap-icons-map, "bootstrap"); }
.bi-border-all::before { content: map-get($bootstrap-icons-map, "border-all"); }
.bi-border-bottom::before { content: map-get($bootstrap-icons-map, "border-bottom"); }
.bi-border-center::before { content: map-get($bootstrap-icons-map, "border-center"); }
.bi-border-inner::before { content: map-get($bootstrap-icons-map, "border-inner"); }
.bi-border-left::before { content: map-get($bootstrap-icons-map, "border-left"); }
.bi-border-middle::before { content: map-get($bootstrap-icons-map, "border-middle"); }
.bi-border-outer::before { content: map-get($bootstrap-icons-map, "border-outer"); }
.bi-border-right::before { content: map-get($bootstrap-icons-map, "border-right"); }
.bi-border-style::before { content: map-get($bootstrap-icons-map, "border-style"); }
.bi-border-top::before { content: map-get($bootstrap-icons-map, "border-top"); }
.bi-border-width::before { content: map-get($bootstrap-icons-map, "border-width"); }
.bi-border::before { content: map-get($bootstrap-icons-map, "border"); }
.bi-bounding-box-circles::before { content: map-get($bootstrap-icons-map, "bounding-box-circles"); }
.bi-bounding-box::before { content: map-get($bootstrap-icons-map, "bounding-box"); }
.bi-box-arrow-down-left::before { content: map-get($bootstrap-icons-map, "box-arrow-down-left"); }
.bi-box-arrow-down-right::before { content: map-get($bootstrap-icons-map, "box-arrow-down-right"); }
.bi-box-arrow-down::before { content: map-get($bootstrap-icons-map, "box-arrow-down"); }
.bi-box-arrow-in-down-left::before { content: map-get($bootstrap-icons-map, "box-arrow-in-down-left"); }
.bi-box-arrow-in-down-right::before { content: map-get($bootstrap-icons-map, "box-arrow-in-down-right"); }
.bi-box-arrow-in-down::before { content: map-get($bootstrap-icons-map, "box-arrow-in-down"); }
.bi-box-arrow-in-left::before { content: map-get($bootstrap-icons-map, "box-arrow-in-left"); }
.bi-box-arrow-in-right::before { content: map-get($bootstrap-icons-map, "box-arrow-in-right"); }
.bi-box-arrow-in-up-left::before { content: map-get($bootstrap-icons-map, "box-arrow-in-up-left"); }
.bi-box-arrow-in-up-right::before { content: map-get($bootstrap-icons-map, "box-arrow-in-up-right"); }
.bi-box-arrow-in-up::before { content: map-get($bootstrap-icons-map, "box-arrow-in-up"); }
.bi-box-arrow-left::before { content: map-get($bootstrap-icons-map, "box-arrow-left"); }
.bi-box-arrow-right::before { content: map-get($bootstrap-icons-map, "box-arrow-right"); }
.bi-box-arrow-up-left::before { content: map-get($bootstrap-icons-map, "box-arrow-up-left"); }
.bi-box-arrow-up-right::before { content: map-get($bootstrap-icons-map, "box-arrow-up-right"); }
.bi-box-arrow-up::before { content: map-get($bootstrap-icons-map, "box-arrow-up"); }
.bi-box-seam::before { content: map-get($bootstrap-icons-map, "box-seam"); }
.bi-box::before { content: map-get($bootstrap-icons-map, "box"); }
.bi-braces::before { content: map-get($bootstrap-icons-map, "braces"); }
.bi-bricks::before { content: map-get($bootstrap-icons-map, "bricks"); }
.bi-briefcase-fill::before { content: map-get($bootstrap-icons-map, "briefcase-fill"); }
.bi-briefcase::before { content: map-get($bootstrap-icons-map, "briefcase"); }
.bi-brightness-alt-high-fill::before { content: map-get($bootstrap-icons-map, "brightness-alt-high-fill"); }
.bi-brightness-alt-high::before { content: map-get($bootstrap-icons-map, "brightness-alt-high"); }
.bi-brightness-alt-low-fill::before { content: map-get($bootstrap-icons-map, "brightness-alt-low-fill"); }
.bi-brightness-alt-low::before { content: map-get($bootstrap-icons-map, "brightness-alt-low"); }
.bi-brightness-high-fill::before { content: map-get($bootstrap-icons-map, "brightness-high-fill"); }
.bi-brightness-high::before { content: map-get($bootstrap-icons-map, "brightness-high"); }
.bi-brightness-low-fill::before { content: map-get($bootstrap-icons-map, "brightness-low-fill"); }
.bi-brightness-low::before { content: map-get($bootstrap-icons-map, "brightness-low"); }
.bi-broadcast-pin::before { content: map-get($bootstrap-icons-map, "broadcast-pin"); }
.bi-broadcast::before { content: map-get($bootstrap-icons-map, "broadcast"); }
.bi-brush-fill::before { content: map-get($bootstrap-icons-map, "brush-fill"); }
.bi-brush::before { content: map-get($bootstrap-icons-map, "brush"); }
.bi-bucket-fill::before { content: map-get($bootstrap-icons-map, "bucket-fill"); }
.bi-bucket::before { content: map-get($bootstrap-icons-map, "bucket"); }
.bi-bug-fill::before { content: map-get($bootstrap-icons-map, "bug-fill"); }
.bi-bug::before { content: map-get($bootstrap-icons-map, "bug"); }
.bi-building::before { content: map-get($bootstrap-icons-map, "building"); }
.bi-bullseye::before { content: map-get($bootstrap-icons-map, "bullseye"); }
.bi-calculator-fill::before { content: map-get($bootstrap-icons-map, "calculator-fill"); }
.bi-calculator::before { content: map-get($bootstrap-icons-map, "calculator"); }
.bi-calendar-check-fill::before { content: map-get($bootstrap-icons-map, "calendar-check-fill"); }
.bi-calendar-check::before { content: map-get($bootstrap-icons-map, "calendar-check"); }
.bi-calendar-date-fill::before { content: map-get($bootstrap-icons-map, "calendar-date-fill"); }
.bi-calendar-date::before { content: map-get($bootstrap-icons-map, "calendar-date"); }
.bi-calendar-day-fill::before { content: map-get($bootstrap-icons-map, "calendar-day-fill"); }
.bi-calendar-day::before { content: map-get($bootstrap-icons-map, "calendar-day"); }
.bi-calendar-event-fill::before { content: map-get($bootstrap-icons-map, "calendar-event-fill"); }
.bi-calendar-event::before { content: map-get($bootstrap-icons-map, "calendar-event"); }
.bi-calendar-fill::before { content: map-get($bootstrap-icons-map, "calendar-fill"); }
.bi-calendar-minus-fill::before { content: map-get($bootstrap-icons-map, "calendar-minus-fill"); }
.bi-calendar-minus::before { content: map-get($bootstrap-icons-map, "calendar-minus"); }
.bi-calendar-month-fill::before { content: map-get($bootstrap-icons-map, "calendar-month-fill"); }
.bi-calendar-month::before { content: map-get($bootstrap-icons-map, "calendar-month"); }
.bi-calendar-plus-fill::before { content: map-get($bootstrap-icons-map, "calendar-plus-fill"); }
.bi-calendar-plus::before { content: map-get($bootstrap-icons-map, "calendar-plus"); }
.bi-calendar-range-fill::before { content: map-get($bootstrap-icons-map, "calendar-range-fill"); }
.bi-calendar-range::before { content: map-get($bootstrap-icons-map, "calendar-range"); }
.bi-calendar-week-fill::before { content: map-get($bootstrap-icons-map, "calendar-week-fill"); }
.bi-calendar-week::before { content: map-get($bootstrap-icons-map, "calendar-week"); }
.bi-calendar-x-fill::before { content: map-get($bootstrap-icons-map, "calendar-x-fill"); }
.bi-calendar-x::before { content: map-get($bootstrap-icons-map, "calendar-x"); }
.bi-calendar::before { content: map-get($bootstrap-icons-map, "calendar"); }
.bi-calendar2-check-fill::before { content: map-get($bootstrap-icons-map, "calendar2-check-fill"); }
.bi-calendar2-check::before { content: map-get($bootstrap-icons-map, "calendar2-check"); }
.bi-calendar2-date-fill::before { content: map-get($bootstrap-icons-map, "calendar2-date-fill"); }
.bi-calendar2-date::before { content: map-get($bootstrap-icons-map, "calendar2-date"); }
.bi-calendar2-day-fill::before { content: map-get($bootstrap-icons-map, "calendar2-day-fill"); }
.bi-calendar2-day::before { content: map-get($bootstrap-icons-map, "calendar2-day"); }
.bi-calendar2-event-fill::before { content: map-get($bootstrap-icons-map, "calendar2-event-fill"); }
.bi-calendar2-event::before { content: map-get($bootstrap-icons-map, "calendar2-event"); }
.bi-calendar2-fill::before { content: map-get($bootstrap-icons-map, "calendar2-fill"); }
.bi-calendar2-minus-fill::before { content: map-get($bootstrap-icons-map, "calendar2-minus-fill"); }
.bi-calendar2-minus::before { content: map-get($bootstrap-icons-map, "calendar2-minus"); }
.bi-calendar2-month-fill::before { content: map-get($bootstrap-icons-map, "calendar2-month-fill"); }
.bi-calendar2-month::before { content: map-get($bootstrap-icons-map, "calendar2-month"); }
.bi-calendar2-plus-fill::before { content: map-get($bootstrap-icons-map, "calendar2-plus-fill"); }
.bi-calendar2-plus::before { content: map-get($bootstrap-icons-map, "calendar2-plus"); }
.bi-calendar2-range-fill::before { content: map-get($bootstrap-icons-map, "calendar2-range-fill"); }
.bi-calendar2-range::before { content: map-get($bootstrap-icons-map, "calendar2-range"); }
.bi-calendar2-week-fill::before { content: map-get($bootstrap-icons-map, "calendar2-week-fill"); }
.bi-calendar2-week::before { content: map-get($bootstrap-icons-map, "calendar2-week"); }
.bi-calendar2-x-fill::before { content: map-get($bootstrap-icons-map, "calendar2-x-fill"); }
.bi-calendar2-x::before { content: map-get($bootstrap-icons-map, "calendar2-x"); }
.bi-calendar2::before { content: map-get($bootstrap-icons-map, "calendar2"); }
.bi-calendar3-event-fill::before { content: map-get($bootstrap-icons-map, "calendar3-event-fill"); }
.bi-calendar3-event::before { content: map-get($bootstrap-icons-map, "calendar3-event"); }
.bi-calendar3-fill::before { content: map-get($bootstrap-icons-map, "calendar3-fill"); }
.bi-calendar3-range-fill::before { content: map-get($bootstrap-icons-map, "calendar3-range-fill"); }
.bi-calendar3-range::before { content: map-get($bootstrap-icons-map, "calendar3-range"); }
.bi-calendar3-week-fill::before { content: map-get($bootstrap-icons-map, "calendar3-week-fill"); }
.bi-calendar3-week::before { content: map-get($bootstrap-icons-map, "calendar3-week"); }
.bi-calendar3::before { content: map-get($bootstrap-icons-map, "calendar3"); }
.bi-calendar4-event::before { content: map-get($bootstrap-icons-map, "calendar4-event"); }
.bi-calendar4-range::before { content: map-get($bootstrap-icons-map, "calendar4-range"); }
.bi-calendar4-week::before { content: map-get($bootstrap-icons-map, "calendar4-week"); }
.bi-calendar4::before { content: map-get($bootstrap-icons-map, "calendar4"); }
.bi-camera-fill::before { content: map-get($bootstrap-icons-map, "camera-fill"); }
.bi-camera-reels-fill::before { content: map-get($bootstrap-icons-map, "camera-reels-fill"); }
.bi-camera-reels::before { content: map-get($bootstrap-icons-map, "camera-reels"); }
.bi-camera-video-fill::before { content: map-get($bootstrap-icons-map, "camera-video-fill"); }
.bi-camera-video-off-fill::before { content: map-get($bootstrap-icons-map, "camera-video-off-fill"); }
.bi-camera-video-off::before { content: map-get($bootstrap-icons-map, "camera-video-off"); }
.bi-camera-video::before { content: map-get($bootstrap-icons-map, "camera-video"); }
.bi-camera::before { content: map-get($bootstrap-icons-map, "camera"); }
.bi-camera2::before { content: map-get($bootstrap-icons-map, "camera2"); }
.bi-capslock-fill::before { content: map-get($bootstrap-icons-map, "capslock-fill"); }
.bi-capslock::before { content: map-get($bootstrap-icons-map, "capslock"); }
.bi-card-checklist::before { content: map-get($bootstrap-icons-map, "card-checklist"); }
.bi-card-heading::before { content: map-get($bootstrap-icons-map, "card-heading"); }
.bi-card-image::before { content: map-get($bootstrap-icons-map, "card-image"); }
.bi-card-list::before { content: map-get($bootstrap-icons-map, "card-list"); }
.bi-card-text::before { content: map-get($bootstrap-icons-map, "card-text"); }
.bi-caret-down-fill::before { content: map-get($bootstrap-icons-map, "caret-down-fill"); }
.bi-caret-down-square-fill::before { content: map-get($bootstrap-icons-map, "caret-down-square-fill"); }
.bi-caret-down-square::before { content: map-get($bootstrap-icons-map, "caret-down-square"); }
.bi-caret-down::before { content: map-get($bootstrap-icons-map, "caret-down"); }
.bi-caret-left-fill::before { content: map-get($bootstrap-icons-map, "caret-left-fill"); }
.bi-caret-left-square-fill::before { content: map-get($bootstrap-icons-map, "caret-left-square-fill"); }
.bi-caret-left-square::before { content: map-get($bootstrap-icons-map, "caret-left-square"); }
.bi-caret-left::before { content: map-get($bootstrap-icons-map, "caret-left"); }
.bi-caret-right-fill::before { content: map-get($bootstrap-icons-map, "caret-right-fill"); }
.bi-caret-right-square-fill::before { content: map-get($bootstrap-icons-map, "caret-right-square-fill"); }
.bi-caret-right-square::before { content: map-get($bootstrap-icons-map, "caret-right-square"); }
.bi-caret-right::before { content: map-get($bootstrap-icons-map, "caret-right"); }
.bi-caret-up-fill::before { content: map-get($bootstrap-icons-map, "caret-up-fill"); }
.bi-caret-up-square-fill::before { content: map-get($bootstrap-icons-map, "caret-up-square-fill"); }
.bi-caret-up-square::before { content: map-get($bootstrap-icons-map, "caret-up-square"); }
.bi-caret-up::before { content: map-get($bootstrap-icons-map, "caret-up"); }
.bi-cart-check-fill::before { content: map-get($bootstrap-icons-map, "cart-check-fill"); }
.bi-cart-check::before { content: map-get($bootstrap-icons-map, "cart-check"); }
.bi-cart-dash-fill::before { content: map-get($bootstrap-icons-map, "cart-dash-fill"); }
.bi-cart-dash::before { content: map-get($bootstrap-icons-map, "cart-dash"); }
.bi-cart-fill::before { content: map-get($bootstrap-icons-map, "cart-fill"); }
.bi-cart-plus-fill::before { content: map-get($bootstrap-icons-map, "cart-plus-fill"); }
.bi-cart-plus::before { content: map-get($bootstrap-icons-map, "cart-plus"); }
.bi-cart-x-fill::before { content: map-get($bootstrap-icons-map, "cart-x-fill"); }
.bi-cart-x::before { content: map-get($bootstrap-icons-map, "cart-x"); }
.bi-cart::before { content: map-get($bootstrap-icons-map, "cart"); }
.bi-cart2::before { content: map-get($bootstrap-icons-map, "cart2"); }
.bi-cart3::before { content: map-get($bootstrap-icons-map, "cart3"); }
.bi-cart4::before { content: map-get($bootstrap-icons-map, "cart4"); }
.bi-cash-stack::before { content: map-get($bootstrap-icons-map, "cash-stack"); }
.bi-cash::before { content: map-get($bootstrap-icons-map, "cash"); }
.bi-cast::before { content: map-get($bootstrap-icons-map, "cast"); }
.bi-chat-dots-fill::before { content: map-get($bootstrap-icons-map, "chat-dots-fill"); }
.bi-chat-dots::before { content: map-get($bootstrap-icons-map, "chat-dots"); }
.bi-chat-fill::before { content: map-get($bootstrap-icons-map, "chat-fill"); }
.bi-chat-left-dots-fill::before { content: map-get($bootstrap-icons-map, "chat-left-dots-fill"); }
.bi-chat-left-dots::before { content: map-get($bootstrap-icons-map, "chat-left-dots"); }
.bi-chat-left-fill::before { content: map-get($bootstrap-icons-map, "chat-left-fill"); }
.bi-chat-left-quote-fill::before { content: map-get($bootstrap-icons-map, "chat-left-quote-fill"); }
.bi-chat-left-quote::before { content: map-get($bootstrap-icons-map, "chat-left-quote"); }
.bi-chat-left-text-fill::before { content: map-get($bootstrap-icons-map, "chat-left-text-fill"); }
.bi-chat-left-text::before { content: map-get($bootstrap-icons-map, "chat-left-text"); }
.bi-chat-left::before { content: map-get($bootstrap-icons-map, "chat-left"); }
.bi-chat-quote-fill::before { content: map-get($bootstrap-icons-map, "chat-quote-fill"); }
.bi-chat-quote::before { content: map-get($bootstrap-icons-map, "chat-quote"); }
.bi-chat-right-dots-fill::before { content: map-get($bootstrap-icons-map, "chat-right-dots-fill"); }
.bi-chat-right-dots::before { content: map-get($bootstrap-icons-map, "chat-right-dots"); }
.bi-chat-right-fill::before { content: map-get($bootstrap-icons-map, "chat-right-fill"); }
.bi-chat-right-quote-fill::before { content: map-get($bootstrap-icons-map, "chat-right-quote-fill"); }
.bi-chat-right-quote::before { content: map-get($bootstrap-icons-map, "chat-right-quote"); }
.bi-chat-right-text-fill::before { content: map-get($bootstrap-icons-map, "chat-right-text-fill"); }
.bi-chat-right-text::before { content: map-get($bootstrap-icons-map, "chat-right-text"); }
.bi-chat-right::before { content: map-get($bootstrap-icons-map, "chat-right"); }
.bi-chat-square-dots-fill::before { content: map-get($bootstrap-icons-map, "chat-square-dots-fill"); }
.bi-chat-square-dots::before { content: map-get($bootstrap-icons-map, "chat-square-dots"); }
.bi-chat-square-fill::before { content: map-get($bootstrap-icons-map, "chat-square-fill"); }
.bi-chat-square-quote-fill::before { content: map-get($bootstrap-icons-map, "chat-square-quote-fill"); }
.bi-chat-square-quote::before { content: map-get($bootstrap-icons-map, "chat-square-quote"); }
.bi-chat-square-text-fill::before { content: map-get($bootstrap-icons-map, "chat-square-text-fill"); }
.bi-chat-square-text::before { content: map-get($bootstrap-icons-map, "chat-square-text"); }
.bi-chat-square::before { content: map-get($bootstrap-icons-map, "chat-square"); }
.bi-chat-text-fill::before { content: map-get($bootstrap-icons-map, "chat-text-fill"); }
.bi-chat-text::before { content: map-get($bootstrap-icons-map, "chat-text"); }
.bi-chat::before { content: map-get($bootstrap-icons-map, "chat"); }
.bi-check-all::before { content: map-get($bootstrap-icons-map, "check-all"); }
.bi-check-circle-fill::before { content: map-get($bootstrap-icons-map, "check-circle-fill"); }
.bi-check-circle::before { content: map-get($bootstrap-icons-map, "check-circle"); }
.bi-check-square-fill::before { content: map-get($bootstrap-icons-map, "check-square-fill"); }
.bi-check-square::before { content: map-get($bootstrap-icons-map, "check-square"); }
.bi-check::before { content: map-get($bootstrap-icons-map, "check"); }
.bi-check2-all::before { content: map-get($bootstrap-icons-map, "check2-all"); }
.bi-check2-circle::before { content: map-get($bootstrap-icons-map, "check2-circle"); }
.bi-check2-square::before { content: map-get($bootstrap-icons-map, "check2-square"); }
.bi-check2::before { content: map-get($bootstrap-icons-map, "check2"); }
.bi-chevron-bar-contract::before { content: map-get($bootstrap-icons-map, "chevron-bar-contract"); }
.bi-chevron-bar-down::before { content: map-get($bootstrap-icons-map, "chevron-bar-down"); }
.bi-chevron-bar-expand::before { content: map-get($bootstrap-icons-map, "chevron-bar-expand"); }
.bi-chevron-bar-left::before { content: map-get($bootstrap-icons-map, "chevron-bar-left"); }
.bi-chevron-bar-right::before { content: map-get($bootstrap-icons-map, "chevron-bar-right"); }
.bi-chevron-bar-up::before { content: map-get($bootstrap-icons-map, "chevron-bar-up"); }
.bi-chevron-compact-down::before { content: map-get($bootstrap-icons-map, "chevron-compact-down"); }
.bi-chevron-compact-left::before { content: map-get($bootstrap-icons-map, "chevron-compact-left"); }
.bi-chevron-compact-right::before { content: map-get($bootstrap-icons-map, "chevron-compact-right"); }
.bi-chevron-compact-up::before { content: map-get($bootstrap-icons-map, "chevron-compact-up"); }
.bi-chevron-contract::before { content: map-get($bootstrap-icons-map, "chevron-contract"); }
.bi-chevron-double-down::before { content: map-get($bootstrap-icons-map, "chevron-double-down"); }
.bi-chevron-double-left::before { content: map-get($bootstrap-icons-map, "chevron-double-left"); }
.bi-chevron-double-right::before { content: map-get($bootstrap-icons-map, "chevron-double-right"); }
.bi-chevron-double-up::before { content: map-get($bootstrap-icons-map, "chevron-double-up"); }
.bi-chevron-down::before { content: map-get($bootstrap-icons-map, "chevron-down"); }
.bi-chevron-expand::before { content: map-get($bootstrap-icons-map, "chevron-expand"); }
.bi-chevron-left::before { content: map-get($bootstrap-icons-map, "chevron-left"); }
.bi-chevron-right::before { content: map-get($bootstrap-icons-map, "chevron-right"); }
.bi-chevron-up::before { content: map-get($bootstrap-icons-map, "chevron-up"); }
.bi-circle-fill::before { content: map-get($bootstrap-icons-map, "circle-fill"); }
.bi-circle-half::before { content: map-get($bootstrap-icons-map, "circle-half"); }
.bi-circle-square::before { content: map-get($bootstrap-icons-map, "circle-square"); }
.bi-circle::before { content: map-get($bootstrap-icons-map, "circle"); }
.bi-clipboard-check::before { content: map-get($bootstrap-icons-map, "clipboard-check"); }
.bi-clipboard-data::before { content: map-get($bootstrap-icons-map, "clipboard-data"); }
.bi-clipboard-minus::before { content: map-get($bootstrap-icons-map, "clipboard-minus"); }
.bi-clipboard-plus::before { content: map-get($bootstrap-icons-map, "clipboard-plus"); }
.bi-clipboard-x::before { content: map-get($bootstrap-icons-map, "clipboard-x"); }
.bi-clipboard::before { content: map-get($bootstrap-icons-map, "clipboard"); }
.bi-clock-fill::before { content: map-get($bootstrap-icons-map, "clock-fill"); }
.bi-clock-history::before { content: map-get($bootstrap-icons-map, "clock-history"); }
.bi-clock::before { content: map-get($bootstrap-icons-map, "clock"); }
.bi-cloud-arrow-down-fill::before { content: map-get($bootstrap-icons-map, "cloud-arrow-down-fill"); }
.bi-cloud-arrow-down::before { content: map-get($bootstrap-icons-map, "cloud-arrow-down"); }
.bi-cloud-arrow-up-fill::before { content: map-get($bootstrap-icons-map, "cloud-arrow-up-fill"); }
.bi-cloud-arrow-up::before { content: map-get($bootstrap-icons-map, "cloud-arrow-up"); }
.bi-cloud-check-fill::before { content: map-get($bootstrap-icons-map, "cloud-check-fill"); }
.bi-cloud-check::before { content: map-get($bootstrap-icons-map, "cloud-check"); }
.bi-cloud-download-fill::before { content: map-get($bootstrap-icons-map, "cloud-download-fill"); }
.bi-cloud-download::before { content: map-get($bootstrap-icons-map, "cloud-download"); }
.bi-cloud-drizzle-fill::before { content: map-get($bootstrap-icons-map, "cloud-drizzle-fill"); }
.bi-cloud-drizzle::before { content: map-get($bootstrap-icons-map, "cloud-drizzle"); }
.bi-cloud-fill::before { content: map-get($bootstrap-icons-map, "cloud-fill"); }
.bi-cloud-fog-fill::before { content: map-get($bootstrap-icons-map, "cloud-fog-fill"); }
.bi-cloud-fog::before { content: map-get($bootstrap-icons-map, "cloud-fog"); }
.bi-cloud-fog2-fill::before { content: map-get($bootstrap-icons-map, "cloud-fog2-fill"); }
.bi-cloud-fog2::before { content: map-get($bootstrap-icons-map, "cloud-fog2"); }
.bi-cloud-hail-fill::before { content: map-get($bootstrap-icons-map, "cloud-hail-fill"); }
.bi-cloud-hail::before { content: map-get($bootstrap-icons-map, "cloud-hail"); }
.bi-cloud-haze-1::before { content: map-get($bootstrap-icons-map, "cloud-haze-1"); }
.bi-cloud-haze-fill::before { content: map-get($bootstrap-icons-map, "cloud-haze-fill"); }
.bi-cloud-haze::before { content: map-get($bootstrap-icons-map, "cloud-haze"); }
.bi-cloud-haze2-fill::before { content: map-get($bootstrap-icons-map, "cloud-haze2-fill"); }
.bi-cloud-lightning-fill::before { content: map-get($bootstrap-icons-map, "cloud-lightning-fill"); }
.bi-cloud-lightning-rain-fill::before { content: map-get($bootstrap-icons-map, "cloud-lightning-rain-fill"); }
.bi-cloud-lightning-rain::before { content: map-get($bootstrap-icons-map, "cloud-lightning-rain"); }
.bi-cloud-lightning::before { content: map-get($bootstrap-icons-map, "cloud-lightning"); }
.bi-cloud-minus-fill::before { content: map-get($bootstrap-icons-map, "cloud-minus-fill"); }
.bi-cloud-minus::before { content: map-get($bootstrap-icons-map, "cloud-minus"); }
.bi-cloud-moon-fill::before { content: map-get($bootstrap-icons-map, "cloud-moon-fill"); }
.bi-cloud-moon::before { content: map-get($bootstrap-icons-map, "cloud-moon"); }
.bi-cloud-plus-fill::before { content: map-get($bootstrap-icons-map, "cloud-plus-fill"); }
.bi-cloud-plus::before { content: map-get($bootstrap-icons-map, "cloud-plus"); }
.bi-cloud-rain-fill::before { content: map-get($bootstrap-icons-map, "cloud-rain-fill"); }
.bi-cloud-rain-heavy-fill::before { content: map-get($bootstrap-icons-map, "cloud-rain-heavy-fill"); }
.bi-cloud-rain-heavy::before { content: map-get($bootstrap-icons-map, "cloud-rain-heavy"); }
.bi-cloud-rain::before { content: map-get($bootstrap-icons-map, "cloud-rain"); }
.bi-cloud-slash-fill::before { content: map-get($bootstrap-icons-map, "cloud-slash-fill"); }
.bi-cloud-slash::before { content: map-get($bootstrap-icons-map, "cloud-slash"); }
.bi-cloud-sleet-fill::before { content: map-get($bootstrap-icons-map, "cloud-sleet-fill"); }
.bi-cloud-sleet::before { content: map-get($bootstrap-icons-map, "cloud-sleet"); }
.bi-cloud-snow-fill::before { content: map-get($bootstrap-icons-map, "cloud-snow-fill"); }
.bi-cloud-snow::before { content: map-get($bootstrap-icons-map, "cloud-snow"); }
.bi-cloud-sun-fill::before { content: map-get($bootstrap-icons-map, "cloud-sun-fill"); }
.bi-cloud-sun::before { content: map-get($bootstrap-icons-map, "cloud-sun"); }
.bi-cloud-upload-fill::before { content: map-get($bootstrap-icons-map, "cloud-upload-fill"); }
.bi-cloud-upload::before { content: map-get($bootstrap-icons-map, "cloud-upload"); }
.bi-cloud::before { content: map-get($bootstrap-icons-map, "cloud"); }
.bi-clouds-fill::before { content: map-get($bootstrap-icons-map, "clouds-fill"); }
.bi-clouds::before { content: map-get($bootstrap-icons-map, "clouds"); }
.bi-cloudy-fill::before { content: map-get($bootstrap-icons-map, "cloudy-fill"); }
.bi-cloudy::before { content: map-get($bootstrap-icons-map, "cloudy"); }
.bi-code-slash::before { content: map-get($bootstrap-icons-map, "code-slash"); }
.bi-code-square::before { content: map-get($bootstrap-icons-map, "code-square"); }
.bi-code::before { content: map-get($bootstrap-icons-map, "code"); }
.bi-collection-fill::before { content: map-get($bootstrap-icons-map, "collection-fill"); }
.bi-collection-play-fill::before { content: map-get($bootstrap-icons-map, "collection-play-fill"); }
.bi-collection-play::before { content: map-get($bootstrap-icons-map, "collection-play"); }
.bi-collection::before { content: map-get($bootstrap-icons-map, "collection"); }
.bi-columns-gap::before { content: map-get($bootstrap-icons-map, "columns-gap"); }
.bi-columns::before { content: map-get($bootstrap-icons-map, "columns"); }
.bi-command::before { content: map-get($bootstrap-icons-map, "command"); }
.bi-compass-fill::before { content: map-get($bootstrap-icons-map, "compass-fill"); }
.bi-compass::before { content: map-get($bootstrap-icons-map, "compass"); }
.bi-cone-striped::before { content: map-get($bootstrap-icons-map, "cone-striped"); }
.bi-cone::before { content: map-get($bootstrap-icons-map, "cone"); }
.bi-controller::before { content: map-get($bootstrap-icons-map, "controller"); }
.bi-cpu-fill::before { content: map-get($bootstrap-icons-map, "cpu-fill"); }
.bi-cpu::before { content: map-get($bootstrap-icons-map, "cpu"); }
.bi-credit-card-2-back-fill::before { content: map-get($bootstrap-icons-map, "credit-card-2-back-fill"); }
.bi-credit-card-2-back::before { content: map-get($bootstrap-icons-map, "credit-card-2-back"); }
.bi-credit-card-2-front-fill::before { content: map-get($bootstrap-icons-map, "credit-card-2-front-fill"); }
.bi-credit-card-2-front::before { content: map-get($bootstrap-icons-map, "credit-card-2-front"); }
.bi-credit-card-fill::before { content: map-get($bootstrap-icons-map, "credit-card-fill"); }
.bi-credit-card::before { content: map-get($bootstrap-icons-map, "credit-card"); }
.bi-crop::before { content: map-get($bootstrap-icons-map, "crop"); }
.bi-cup-fill::before { content: map-get($bootstrap-icons-map, "cup-fill"); }
.bi-cup-straw::before { content: map-get($bootstrap-icons-map, "cup-straw"); }
.bi-cup::before { content: map-get($bootstrap-icons-map, "cup"); }
.bi-cursor-fill::before { content: map-get($bootstrap-icons-map, "cursor-fill"); }
.bi-cursor-text::before { content: map-get($bootstrap-icons-map, "cursor-text"); }
.bi-cursor::before { content: map-get($bootstrap-icons-map, "cursor"); }
.bi-dash-circle-dotted::before { content: map-get($bootstrap-icons-map, "dash-circle-dotted"); }
.bi-dash-circle-fill::before { content: map-get($bootstrap-icons-map, "dash-circle-fill"); }
.bi-dash-circle::before { content: map-get($bootstrap-icons-map, "dash-circle"); }
.bi-dash-square-dotted::before { content: map-get($bootstrap-icons-map, "dash-square-dotted"); }
.bi-dash-square-fill::before { content: map-get($bootstrap-icons-map, "dash-square-fill"); }
.bi-dash-square::before { content: map-get($bootstrap-icons-map, "dash-square"); }
.bi-dash::before { content: map-get($bootstrap-icons-map, "dash"); }
.bi-diagram-2-fill::before { content: map-get($bootstrap-icons-map, "diagram-2-fill"); }
.bi-diagram-2::before { content: map-get($bootstrap-icons-map, "diagram-2"); }
.bi-diagram-3-fill::before { content: map-get($bootstrap-icons-map, "diagram-3-fill"); }
.bi-diagram-3::before { content: map-get($bootstrap-icons-map, "diagram-3"); }
.bi-diamond-fill::before { content: map-get($bootstrap-icons-map, "diamond-fill"); }
.bi-diamond-half::before { content: map-get($bootstrap-icons-map, "diamond-half"); }
.bi-diamond::before { content: map-get($bootstrap-icons-map, "diamond"); }
.bi-dice-1-fill::before { content: map-get($bootstrap-icons-map, "dice-1-fill"); }
.bi-dice-1::before { content: map-get($bootstrap-icons-map, "dice-1"); }
.bi-dice-2-fill::before { content: map-get($bootstrap-icons-map, "dice-2-fill"); }
.bi-dice-2::before { content: map-get($bootstrap-icons-map, "dice-2"); }
.bi-dice-3-fill::before { content: map-get($bootstrap-icons-map, "dice-3-fill"); }
.bi-dice-3::before { content: map-get($bootstrap-icons-map, "dice-3"); }
.bi-dice-4-fill::before { content: map-get($bootstrap-icons-map, "dice-4-fill"); }
.bi-dice-4::before { content: map-get($bootstrap-icons-map, "dice-4"); }
.bi-dice-5-fill::before { content: map-get($bootstrap-icons-map, "dice-5-fill"); }
.bi-dice-5::before { content: map-get($bootstrap-icons-map, "dice-5"); }
.bi-dice-6-fill::before { content: map-get($bootstrap-icons-map, "dice-6-fill"); }
.bi-dice-6::before { content: map-get($bootstrap-icons-map, "dice-6"); }
.bi-disc-fill::before { content: map-get($bootstrap-icons-map, "disc-fill"); }
.bi-disc::before { content: map-get($bootstrap-icons-map, "disc"); }
.bi-discord::before { content: map-get($bootstrap-icons-map, "discord"); }
.bi-display-fill::before { content: map-get($bootstrap-icons-map, "display-fill"); }
.bi-display::before { content: map-get($bootstrap-icons-map, "display"); }
.bi-distribute-horizontal::before { content: map-get($bootstrap-icons-map, "distribute-horizontal"); }
.bi-distribute-vertical::before { content: map-get($bootstrap-icons-map, "distribute-vertical"); }
.bi-door-closed-fill::before { content: map-get($bootstrap-icons-map, "door-closed-fill"); }
.bi-door-closed::before { content: map-get($bootstrap-icons-map, "door-closed"); }
.bi-door-open-fill::before { content: map-get($bootstrap-icons-map, "door-open-fill"); }
.bi-door-open::before { content: map-get($bootstrap-icons-map, "door-open"); }
.bi-dot::before { content: map-get($bootstrap-icons-map, "dot"); }
.bi-download::before { content: map-get($bootstrap-icons-map, "download"); }
.bi-droplet-fill::before { content: map-get($bootstrap-icons-map, "droplet-fill"); }
.bi-droplet-half::before { content: map-get($bootstrap-icons-map, "droplet-half"); }
.bi-droplet::before { content: map-get($bootstrap-icons-map, "droplet"); }
.bi-earbuds::before { content: map-get($bootstrap-icons-map, "earbuds"); }
.bi-easel-fill::before { content: map-get($bootstrap-icons-map, "easel-fill"); }
.bi-easel::before { content: map-get($bootstrap-icons-map, "easel"); }
.bi-egg-fill::before { content: map-get($bootstrap-icons-map, "egg-fill"); }
.bi-egg-fried::before { content: map-get($bootstrap-icons-map, "egg-fried"); }
.bi-egg::before { content: map-get($bootstrap-icons-map, "egg"); }
.bi-eject-fill::before { content: map-get($bootstrap-icons-map, "eject-fill"); }
.bi-eject::before { content: map-get($bootstrap-icons-map, "eject"); }
.bi-emoji-angry-fill::before { content: map-get($bootstrap-icons-map, "emoji-angry-fill"); }
.bi-emoji-angry::before { content: map-get($bootstrap-icons-map, "emoji-angry"); }
.bi-emoji-dizzy-fill::before { content: map-get($bootstrap-icons-map, "emoji-dizzy-fill"); }
.bi-emoji-dizzy::before { content: map-get($bootstrap-icons-map, "emoji-dizzy"); }
.bi-emoji-expressionless-fill::before { content: map-get($bootstrap-icons-map, "emoji-expressionless-fill"); }
.bi-emoji-expressionless::before { content: map-get($bootstrap-icons-map, "emoji-expressionless"); }
.bi-emoji-frown-fill::before { content: map-get($bootstrap-icons-map, "emoji-frown-fill"); }
.bi-emoji-frown::before { content: map-get($bootstrap-icons-map, "emoji-frown"); }
.bi-emoji-heart-eyes-fill::before { content: map-get($bootstrap-icons-map, "emoji-heart-eyes-fill"); }
.bi-emoji-heart-eyes::before { content: map-get($bootstrap-icons-map, "emoji-heart-eyes"); }
.bi-emoji-laughing-fill::before { content: map-get($bootstrap-icons-map, "emoji-laughing-fill"); }
.bi-emoji-laughing::before { content: map-get($bootstrap-icons-map, "emoji-laughing"); }
.bi-emoji-neutral-fill::before { content: map-get($bootstrap-icons-map, "emoji-neutral-fill"); }
.bi-emoji-neutral::before { content: map-get($bootstrap-icons-map, "emoji-neutral"); }
.bi-emoji-smile-fill::before { content: map-get($bootstrap-icons-map, "emoji-smile-fill"); }
.bi-emoji-smile-upside-down-fill::before { content: map-get($bootstrap-icons-map, "emoji-smile-upside-down-fill"); }
.bi-emoji-smile-upside-down::before { content: map-get($bootstrap-icons-map, "emoji-smile-upside-down"); }
.bi-emoji-smile::before { content: map-get($bootstrap-icons-map, "emoji-smile"); }
.bi-emoji-sunglasses-fill::before { content: map-get($bootstrap-icons-map, "emoji-sunglasses-fill"); }
.bi-emoji-sunglasses::before { content: map-get($bootstrap-icons-map, "emoji-sunglasses"); }
.bi-emoji-wink-fill::before { content: map-get($bootstrap-icons-map, "emoji-wink-fill"); }
.bi-emoji-wink::before { content: map-get($bootstrap-icons-map, "emoji-wink"); }
.bi-envelope-fill::before { content: map-get($bootstrap-icons-map, "envelope-fill"); }
.bi-envelope-open-fill::before { content: map-get($bootstrap-icons-map, "envelope-open-fill"); }
.bi-envelope-open::before { content: map-get($bootstrap-icons-map, "envelope-open"); }
.bi-envelope::before { content: map-get($bootstrap-icons-map, "envelope"); }
.bi-eraser-fill::before { content: map-get($bootstrap-icons-map, "eraser-fill"); }
.bi-eraser::before { content: map-get($bootstrap-icons-map, "eraser"); }
.bi-exclamation-circle-fill::before { content: map-get($bootstrap-icons-map, "exclamation-circle-fill"); }
.bi-exclamation-circle::before { content: map-get($bootstrap-icons-map, "exclamation-circle"); }
.bi-exclamation-diamond-fill::before { content: map-get($bootstrap-icons-map, "exclamation-diamond-fill"); }
.bi-exclamation-diamond::before { content: map-get($bootstrap-icons-map, "exclamation-diamond"); }
.bi-exclamation-octagon-fill::before { content: map-get($bootstrap-icons-map, "exclamation-octagon-fill"); }
.bi-exclamation-octagon::before { content: map-get($bootstrap-icons-map, "exclamation-octagon"); }
.bi-exclamation-square-fill::before { content: map-get($bootstrap-icons-map, "exclamation-square-fill"); }
.bi-exclamation-square::before { content: map-get($bootstrap-icons-map, "exclamation-square"); }
.bi-exclamation-triangle-fill::before { content: map-get($bootstrap-icons-map, "exclamation-triangle-fill"); }
.bi-exclamation-triangle::before { content: map-get($bootstrap-icons-map, "exclamation-triangle"); }
.bi-exclamation::before { content: map-get($bootstrap-icons-map, "exclamation"); }
.bi-exclude::before { content: map-get($bootstrap-icons-map, "exclude"); }
.bi-eye-fill::before { content: map-get($bootstrap-icons-map, "eye-fill"); }
.bi-eye-slash-fill::before { content: map-get($bootstrap-icons-map, "eye-slash-fill"); }
.bi-eye-slash::before { content: map-get($bootstrap-icons-map, "eye-slash"); }
.bi-eye::before { content: map-get($bootstrap-icons-map, "eye"); }
.bi-eyedropper::before { content: map-get($bootstrap-icons-map, "eyedropper"); }
.bi-eyeglasses::before { content: map-get($bootstrap-icons-map, "eyeglasses"); }
.bi-facebook::before { content: map-get($bootstrap-icons-map, "facebook"); }
.bi-file-arrow-down-fill::before { content: map-get($bootstrap-icons-map, "file-arrow-down-fill"); }
.bi-file-arrow-down::before { content: map-get($bootstrap-icons-map, "file-arrow-down"); }
.bi-file-arrow-up-fill::before { content: map-get($bootstrap-icons-map, "file-arrow-up-fill"); }
.bi-file-arrow-up::before { content: map-get($bootstrap-icons-map, "file-arrow-up"); }
.bi-file-bar-graph-fill::before { content: map-get($bootstrap-icons-map, "file-bar-graph-fill"); }
.bi-file-bar-graph::before { content: map-get($bootstrap-icons-map, "file-bar-graph"); }
.bi-file-binary-fill::before { content: map-get($bootstrap-icons-map, "file-binary-fill"); }
.bi-file-binary::before { content: map-get($bootstrap-icons-map, "file-binary"); }
.bi-file-break-fill::before { content: map-get($bootstrap-icons-map, "file-break-fill"); }
.bi-file-break::before { content: map-get($bootstrap-icons-map, "file-break"); }
.bi-file-check-fill::before { content: map-get($bootstrap-icons-map, "file-check-fill"); }
.bi-file-check::before { content: map-get($bootstrap-icons-map, "file-check"); }
.bi-file-code-fill::before { content: map-get($bootstrap-icons-map, "file-code-fill"); }
.bi-file-code::before { content: map-get($bootstrap-icons-map, "file-code"); }
.bi-file-diff-fill::before { content: map-get($bootstrap-icons-map, "file-diff-fill"); }
.bi-file-diff::before { content: map-get($bootstrap-icons-map, "file-diff"); }
.bi-file-earmark-arrow-down-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-arrow-down-fill"); }
.bi-file-earmark-arrow-down::before { content: map-get($bootstrap-icons-map, "file-earmark-arrow-down"); }
.bi-file-earmark-arrow-up-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-arrow-up-fill"); }
.bi-file-earmark-arrow-up::before { content: map-get($bootstrap-icons-map, "file-earmark-arrow-up"); }
.bi-file-earmark-bar-graph-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-bar-graph-fill"); }
.bi-file-earmark-bar-graph::before { content: map-get($bootstrap-icons-map, "file-earmark-bar-graph"); }
.bi-file-earmark-binary-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-binary-fill"); }
.bi-file-earmark-binary::before { content: map-get($bootstrap-icons-map, "file-earmark-binary"); }
.bi-file-earmark-break-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-break-fill"); }
.bi-file-earmark-break::before { content: map-get($bootstrap-icons-map, "file-earmark-break"); }
.bi-file-earmark-check-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-check-fill"); }
.bi-file-earmark-check::before { content: map-get($bootstrap-icons-map, "file-earmark-check"); }
.bi-file-earmark-code-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-code-fill"); }
.bi-file-earmark-code::before { content: map-get($bootstrap-icons-map, "file-earmark-code"); }
.bi-file-earmark-diff-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-diff-fill"); }
.bi-file-earmark-diff::before { content: map-get($bootstrap-icons-map, "file-earmark-diff"); }
.bi-file-earmark-easel-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-easel-fill"); }
.bi-file-earmark-easel::before { content: map-get($bootstrap-icons-map, "file-earmark-easel"); }
.bi-file-earmark-excel-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-excel-fill"); }
.bi-file-earmark-excel::before { content: map-get($bootstrap-icons-map, "file-earmark-excel"); }
.bi-file-earmark-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-fill"); }
.bi-file-earmark-font-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-font-fill"); }
.bi-file-earmark-font::before { content: map-get($bootstrap-icons-map, "file-earmark-font"); }
.bi-file-earmark-image-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-image-fill"); }
.bi-file-earmark-image::before { content: map-get($bootstrap-icons-map, "file-earmark-image"); }
.bi-file-earmark-lock-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-lock-fill"); }
.bi-file-earmark-lock::before { content: map-get($bootstrap-icons-map, "file-earmark-lock"); }
.bi-file-earmark-lock2-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-lock2-fill"); }
.bi-file-earmark-lock2::before { content: map-get($bootstrap-icons-map, "file-earmark-lock2"); }
.bi-file-earmark-medical-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-medical-fill"); }
.bi-file-earmark-medical::before { content: map-get($bootstrap-icons-map, "file-earmark-medical"); }
.bi-file-earmark-minus-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-minus-fill"); }
.bi-file-earmark-minus::before { content: map-get($bootstrap-icons-map, "file-earmark-minus"); }
.bi-file-earmark-music-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-music-fill"); }
.bi-file-earmark-music::before { content: map-get($bootstrap-icons-map, "file-earmark-music"); }
.bi-file-earmark-person-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-person-fill"); }
.bi-file-earmark-person::before { content: map-get($bootstrap-icons-map, "file-earmark-person"); }
.bi-file-earmark-play-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-play-fill"); }
.bi-file-earmark-play::before { content: map-get($bootstrap-icons-map, "file-earmark-play"); }
.bi-file-earmark-plus-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-plus-fill"); }
.bi-file-earmark-plus::before { content: map-get($bootstrap-icons-map, "file-earmark-plus"); }
.bi-file-earmark-post-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-post-fill"); }
.bi-file-earmark-post::before { content: map-get($bootstrap-icons-map, "file-earmark-post"); }
.bi-file-earmark-ppt-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-ppt-fill"); }
.bi-file-earmark-ppt::before { content: map-get($bootstrap-icons-map, "file-earmark-ppt"); }
.bi-file-earmark-richtext-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-richtext-fill"); }
.bi-file-earmark-richtext::before { content: map-get($bootstrap-icons-map, "file-earmark-richtext"); }
.bi-file-earmark-ruled-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-ruled-fill"); }
.bi-file-earmark-ruled::before { content: map-get($bootstrap-icons-map, "file-earmark-ruled"); }
.bi-file-earmark-slides-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-slides-fill"); }
.bi-file-earmark-slides::before { content: map-get($bootstrap-icons-map, "file-earmark-slides"); }
.bi-file-earmark-spreadsheet-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-spreadsheet-fill"); }
.bi-file-earmark-spreadsheet::before { content: map-get($bootstrap-icons-map, "file-earmark-spreadsheet"); }
.bi-file-earmark-text-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-text-fill"); }
.bi-file-earmark-text::before { content: map-get($bootstrap-icons-map, "file-earmark-text"); }
.bi-file-earmark-word-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-word-fill"); }
.bi-file-earmark-word::before { content: map-get($bootstrap-icons-map, "file-earmark-word"); }
.bi-file-earmark-x-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-x-fill"); }
.bi-file-earmark-x::before { content: map-get($bootstrap-icons-map, "file-earmark-x"); }
.bi-file-earmark-zip-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-zip-fill"); }
.bi-file-earmark-zip::before { content: map-get($bootstrap-icons-map, "file-earmark-zip"); }
.bi-file-earmark::before { content: map-get($bootstrap-icons-map, "file-earmark"); }
.bi-file-easel-fill::before { content: map-get($bootstrap-icons-map, "file-easel-fill"); }
.bi-file-easel::before { content: map-get($bootstrap-icons-map, "file-easel"); }
.bi-file-excel-fill::before { content: map-get($bootstrap-icons-map, "file-excel-fill"); }
.bi-file-excel::before { content: map-get($bootstrap-icons-map, "file-excel"); }
.bi-file-fill::before { content: map-get($bootstrap-icons-map, "file-fill"); }
.bi-file-font-fill::before { content: map-get($bootstrap-icons-map, "file-font-fill"); }
.bi-file-font::before { content: map-get($bootstrap-icons-map, "file-font"); }
.bi-file-image-fill::before { content: map-get($bootstrap-icons-map, "file-image-fill"); }
.bi-file-image::before { content: map-get($bootstrap-icons-map, "file-image"); }
.bi-file-lock-fill::before { content: map-get($bootstrap-icons-map, "file-lock-fill"); }
.bi-file-lock::before { content: map-get($bootstrap-icons-map, "file-lock"); }
.bi-file-lock2-fill::before { content: map-get($bootstrap-icons-map, "file-lock2-fill"); }
.bi-file-lock2::before { content: map-get($bootstrap-icons-map, "file-lock2"); }
.bi-file-medical-fill::before { content: map-get($bootstrap-icons-map, "file-medical-fill"); }
.bi-file-medical::before { content: map-get($bootstrap-icons-map, "file-medical"); }
.bi-file-minus-fill::before { content: map-get($bootstrap-icons-map, "file-minus-fill"); }
.bi-file-minus::before { content: map-get($bootstrap-icons-map, "file-minus"); }
.bi-file-music-fill::before { content: map-get($bootstrap-icons-map, "file-music-fill"); }
.bi-file-music::before { content: map-get($bootstrap-icons-map, "file-music"); }
.bi-file-person-fill::before { content: map-get($bootstrap-icons-map, "file-person-fill"); }
.bi-file-person::before { content: map-get($bootstrap-icons-map, "file-person"); }
.bi-file-play-fill::before { content: map-get($bootstrap-icons-map, "file-play-fill"); }
.bi-file-play::before { content: map-get($bootstrap-icons-map, "file-play"); }
.bi-file-plus-fill::before { content: map-get($bootstrap-icons-map, "file-plus-fill"); }
.bi-file-plus::before { content: map-get($bootstrap-icons-map, "file-plus"); }
.bi-file-post-fill::before { content: map-get($bootstrap-icons-map, "file-post-fill"); }
.bi-file-post::before { content: map-get($bootstrap-icons-map, "file-post"); }
.bi-file-ppt-fill::before { content: map-get($bootstrap-icons-map, "file-ppt-fill"); }
.bi-file-ppt::before { content: map-get($bootstrap-icons-map, "file-ppt"); }
.bi-file-richtext-fill::before { content: map-get($bootstrap-icons-map, "file-richtext-fill"); }
.bi-file-richtext::before { content: map-get($bootstrap-icons-map, "file-richtext"); }
.bi-file-ruled-fill::before { content: map-get($bootstrap-icons-map, "file-ruled-fill"); }
.bi-file-ruled::before { content: map-get($bootstrap-icons-map, "file-ruled"); }
.bi-file-slides-fill::before { content: map-get($bootstrap-icons-map, "file-slides-fill"); }
.bi-file-slides::before { content: map-get($bootstrap-icons-map, "file-slides"); }
.bi-file-spreadsheet-fill::before { content: map-get($bootstrap-icons-map, "file-spreadsheet-fill"); }
.bi-file-spreadsheet::before { content: map-get($bootstrap-icons-map, "file-spreadsheet"); }
.bi-file-text-fill::before { content: map-get($bootstrap-icons-map, "file-text-fill"); }
.bi-file-text::before { content: map-get($bootstrap-icons-map, "file-text"); }
.bi-file-word-fill::before { content: map-get($bootstrap-icons-map, "file-word-fill"); }
.bi-file-word::before { content: map-get($bootstrap-icons-map, "file-word"); }
.bi-file-x-fill::before { content: map-get($bootstrap-icons-map, "file-x-fill"); }
.bi-file-x::before { content: map-get($bootstrap-icons-map, "file-x"); }
.bi-file-zip-fill::before { content: map-get($bootstrap-icons-map, "file-zip-fill"); }
.bi-file-zip::before { content: map-get($bootstrap-icons-map, "file-zip"); }
.bi-file::before { content: map-get($bootstrap-icons-map, "file"); }
.bi-files-alt::before { content: map-get($bootstrap-icons-map, "files-alt"); }
.bi-files::before { content: map-get($bootstrap-icons-map, "files"); }
.bi-film::before { content: map-get($bootstrap-icons-map, "film"); }
.bi-filter-circle-fill::before { content: map-get($bootstrap-icons-map, "filter-circle-fill"); }
.bi-filter-circle::before { content: map-get($bootstrap-icons-map, "filter-circle"); }
.bi-filter-left::before { content: map-get($bootstrap-icons-map, "filter-left"); }
.bi-filter-right::before { content: map-get($bootstrap-icons-map, "filter-right"); }
.bi-filter-square-fill::before { content: map-get($bootstrap-icons-map, "filter-square-fill"); }
.bi-filter-square::before { content: map-get($bootstrap-icons-map, "filter-square"); }
.bi-filter::before { content: map-get($bootstrap-icons-map, "filter"); }
.bi-flag-fill::before { content: map-get($bootstrap-icons-map, "flag-fill"); }
.bi-flag::before { content: map-get($bootstrap-icons-map, "flag"); }
.bi-flower1::before { content: map-get($bootstrap-icons-map, "flower1"); }
.bi-flower2::before { content: map-get($bootstrap-icons-map, "flower2"); }
.bi-flower3::before { content: map-get($bootstrap-icons-map, "flower3"); }
.bi-folder-check::before { content: map-get($bootstrap-icons-map, "folder-check"); }
.bi-folder-fill::before { content: map-get($bootstrap-icons-map, "folder-fill"); }
.bi-folder-minus::before { content: map-get($bootstrap-icons-map, "folder-minus"); }
.bi-folder-plus::before { content: map-get($bootstrap-icons-map, "folder-plus"); }
.bi-folder-symlink-fill::before { content: map-get($bootstrap-icons-map, "folder-symlink-fill"); }
.bi-folder-symlink::before { content: map-get($bootstrap-icons-map, "folder-symlink"); }
.bi-folder-x::before { content: map-get($bootstrap-icons-map, "folder-x"); }
.bi-folder::before { content: map-get($bootstrap-icons-map, "folder"); }
.bi-folder2-open::before { content: map-get($bootstrap-icons-map, "folder2-open"); }
.bi-folder2::before { content: map-get($bootstrap-icons-map, "folder2"); }
.bi-fonts::before { content: map-get($bootstrap-icons-map, "fonts"); }
.bi-forward-fill::before { content: map-get($bootstrap-icons-map, "forward-fill"); }
.bi-forward::before { content: map-get($bootstrap-icons-map, "forward"); }
.bi-front::before { content: map-get($bootstrap-icons-map, "front"); }
.bi-fullscreen-exit::before { content: map-get($bootstrap-icons-map, "fullscreen-exit"); }
.bi-fullscreen::before { content: map-get($bootstrap-icons-map, "fullscreen"); }
.bi-funnel-fill::before { content: map-get($bootstrap-icons-map, "funnel-fill"); }
.bi-funnel::before { content: map-get($bootstrap-icons-map, "funnel"); }
.bi-gear-fill::before { content: map-get($bootstrap-icons-map, "gear-fill"); }
.bi-gear-wide-connected::before { content: map-get($bootstrap-icons-map, "gear-wide-connected"); }
.bi-gear-wide::before { content: map-get($bootstrap-icons-map, "gear-wide"); }
.bi-gear::before { content: map-get($bootstrap-icons-map, "gear"); }
.bi-gem::before { content: map-get($bootstrap-icons-map, "gem"); }
.bi-geo-alt-fill::before { content: map-get($bootstrap-icons-map, "geo-alt-fill"); }
.bi-geo-alt::before { content: map-get($bootstrap-icons-map, "geo-alt"); }
.bi-geo-fill::before { content: map-get($bootstrap-icons-map, "geo-fill"); }
.bi-geo::before { content: map-get($bootstrap-icons-map, "geo"); }
.bi-gift-fill::before { content: map-get($bootstrap-icons-map, "gift-fill"); }
.bi-gift::before { content: map-get($bootstrap-icons-map, "gift"); }
.bi-github::before { content: map-get($bootstrap-icons-map, "github"); }
.bi-globe::before { content: map-get($bootstrap-icons-map, "globe"); }
.bi-globe2::before { content: map-get($bootstrap-icons-map, "globe2"); }
.bi-google::before { content: map-get($bootstrap-icons-map, "google"); }
.bi-graph-down::before { content: map-get($bootstrap-icons-map, "graph-down"); }
.bi-graph-up::before { content: map-get($bootstrap-icons-map, "graph-up"); }
.bi-grid-1x2-fill::before { content: map-get($bootstrap-icons-map, "grid-1x2-fill"); }
.bi-grid-1x2::before { content: map-get($bootstrap-icons-map, "grid-1x2"); }
.bi-grid-3x2-gap-fill::before { content: map-get($bootstrap-icons-map, "grid-3x2-gap-fill"); }
.bi-grid-3x2-gap::before { content: map-get($bootstrap-icons-map, "grid-3x2-gap"); }
.bi-grid-3x2::before { content: map-get($bootstrap-icons-map, "grid-3x2"); }
.bi-grid-3x3-gap-fill::before { content: map-get($bootstrap-icons-map, "grid-3x3-gap-fill"); }
.bi-grid-3x3-gap::before { content: map-get($bootstrap-icons-map, "grid-3x3-gap"); }
.bi-grid-3x3::before { content: map-get($bootstrap-icons-map, "grid-3x3"); }
.bi-grid-fill::before { content: map-get($bootstrap-icons-map, "grid-fill"); }
.bi-grid::before { content: map-get($bootstrap-icons-map, "grid"); }
.bi-grip-horizontal::before { content: map-get($bootstrap-icons-map, "grip-horizontal"); }
.bi-grip-vertical::before { content: map-get($bootstrap-icons-map, "grip-vertical"); }
.bi-hammer::before { content: map-get($bootstrap-icons-map, "hammer"); }
.bi-hand-index-fill::before { content: map-get($bootstrap-icons-map, "hand-index-fill"); }
.bi-hand-index-thumb-fill::before { content: map-get($bootstrap-icons-map, "hand-index-thumb-fill"); }
.bi-hand-index-thumb::before { content: map-get($bootstrap-icons-map, "hand-index-thumb"); }
.bi-hand-index::before { content: map-get($bootstrap-icons-map, "hand-index"); }
.bi-hand-thumbs-down-fill::before { content: map-get($bootstrap-icons-map, "hand-thumbs-down-fill"); }
.bi-hand-thumbs-down::before { content: map-get($bootstrap-icons-map, "hand-thumbs-down"); }
.bi-hand-thumbs-up-fill::before { content: map-get($bootstrap-icons-map, "hand-thumbs-up-fill"); }
.bi-hand-thumbs-up::before { content: map-get($bootstrap-icons-map, "hand-thumbs-up"); }
.bi-handbag-fill::before { content: map-get($bootstrap-icons-map, "handbag-fill"); }
.bi-handbag::before { content: map-get($bootstrap-icons-map, "handbag"); }
.bi-hash::before { content: map-get($bootstrap-icons-map, "hash"); }
.bi-hdd-fill::before { content: map-get($bootstrap-icons-map, "hdd-fill"); }
.bi-hdd-network-fill::before { content: map-get($bootstrap-icons-map, "hdd-network-fill"); }
.bi-hdd-network::before { content: map-get($bootstrap-icons-map, "hdd-network"); }
.bi-hdd-rack-fill::before { content: map-get($bootstrap-icons-map, "hdd-rack-fill"); }
.bi-hdd-rack::before { content: map-get($bootstrap-icons-map, "hdd-rack"); }
.bi-hdd-stack-fill::before { content: map-get($bootstrap-icons-map, "hdd-stack-fill"); }
.bi-hdd-stack::before { content: map-get($bootstrap-icons-map, "hdd-stack"); }
.bi-hdd::before { content: map-get($bootstrap-icons-map, "hdd"); }
.bi-headphones::before { content: map-get($bootstrap-icons-map, "headphones"); }
.bi-headset::before { content: map-get($bootstrap-icons-map, "headset"); }
.bi-heart-fill::before { content: map-get($bootstrap-icons-map, "heart-fill"); }
.bi-heart-half::before { content: map-get($bootstrap-icons-map, "heart-half"); }
.bi-heart::before { content: map-get($bootstrap-icons-map, "heart"); }
.bi-heptagon-fill::before { content: map-get($bootstrap-icons-map, "heptagon-fill"); }
.bi-heptagon-half::before { content: map-get($bootstrap-icons-map, "heptagon-half"); }
.bi-heptagon::before { content: map-get($bootstrap-icons-map, "heptagon"); }
.bi-hexagon-fill::before { content: map-get($bootstrap-icons-map, "hexagon-fill"); }
.bi-hexagon-half::before { content: map-get($bootstrap-icons-map, "hexagon-half"); }
.bi-hexagon::before { content: map-get($bootstrap-icons-map, "hexagon"); }
.bi-hourglass-bottom::before { content: map-get($bootstrap-icons-map, "hourglass-bottom"); }
.bi-hourglass-split::before { content: map-get($bootstrap-icons-map, "hourglass-split"); }
.bi-hourglass-top::before { content: map-get($bootstrap-icons-map, "hourglass-top"); }
.bi-hourglass::before { content: map-get($bootstrap-icons-map, "hourglass"); }
.bi-house-door-fill::before { content: map-get($bootstrap-icons-map, "house-door-fill"); }
.bi-house-door::before { content: map-get($bootstrap-icons-map, "house-door"); }
.bi-house-fill::before { content: map-get($bootstrap-icons-map, "house-fill"); }
.bi-house::before { content: map-get($bootstrap-icons-map, "house"); }
.bi-hr::before { content: map-get($bootstrap-icons-map, "hr"); }
.bi-hurricane::before { content: map-get($bootstrap-icons-map, "hurricane"); }
.bi-image-alt::before { content: map-get($bootstrap-icons-map, "image-alt"); }
.bi-image-fill::before { content: map-get($bootstrap-icons-map, "image-fill"); }
.bi-image::before { content: map-get($bootstrap-icons-map, "image"); }
.bi-images::before { content: map-get($bootstrap-icons-map, "images"); }
.bi-inbox-fill::before { content: map-get($bootstrap-icons-map, "inbox-fill"); }
.bi-inbox::before { content: map-get($bootstrap-icons-map, "inbox"); }
.bi-inboxes-fill::before { content: map-get($bootstrap-icons-map, "inboxes-fill"); }
.bi-inboxes::before { content: map-get($bootstrap-icons-map, "inboxes"); }
.bi-info-circle-fill::before { content: map-get($bootstrap-icons-map, "info-circle-fill"); }
.bi-info-circle::before { content: map-get($bootstrap-icons-map, "info-circle"); }
.bi-info-square-fill::before { content: map-get($bootstrap-icons-map, "info-square-fill"); }
.bi-info-square::before { content: map-get($bootstrap-icons-map, "info-square"); }
.bi-info::before { content: map-get($bootstrap-icons-map, "info"); }
.bi-input-cursor-text::before { content: map-get($bootstrap-icons-map, "input-cursor-text"); }
.bi-input-cursor::before { content: map-get($bootstrap-icons-map, "input-cursor"); }
.bi-instagram::before { content: map-get($bootstrap-icons-map, "instagram"); }
.bi-intersect::before { content: map-get($bootstrap-icons-map, "intersect"); }
.bi-journal-album::before { content: map-get($bootstrap-icons-map, "journal-album"); }
.bi-journal-arrow-down::before { content: map-get($bootstrap-icons-map, "journal-arrow-down"); }
.bi-journal-arrow-up::before { content: map-get($bootstrap-icons-map, "journal-arrow-up"); }
.bi-journal-bookmark-fill::before { content: map-get($bootstrap-icons-map, "journal-bookmark-fill"); }
.bi-journal-bookmark::before { content: map-get($bootstrap-icons-map, "journal-bookmark"); }
.bi-journal-check::before { content: map-get($bootstrap-icons-map, "journal-check"); }
.bi-journal-code::before { content: map-get($bootstrap-icons-map, "journal-code"); }
.bi-journal-medical::before { content: map-get($bootstrap-icons-map, "journal-medical"); }
.bi-journal-minus::before { content: map-get($bootstrap-icons-map, "journal-minus"); }
.bi-journal-plus::before { content: map-get($bootstrap-icons-map, "journal-plus"); }
.bi-journal-richtext::before { content: map-get($bootstrap-icons-map, "journal-richtext"); }
.bi-journal-text::before { content: map-get($bootstrap-icons-map, "journal-text"); }
.bi-journal-x::before { content: map-get($bootstrap-icons-map, "journal-x"); }
.bi-journal::before { content: map-get($bootstrap-icons-map, "journal"); }
.bi-journals::before { content: map-get($bootstrap-icons-map, "journals"); }
.bi-joystick::before { content: map-get($bootstrap-icons-map, "joystick"); }
.bi-justify-left::before { content: map-get($bootstrap-icons-map, "justify-left"); }
.bi-justify-right::before { content: map-get($bootstrap-icons-map, "justify-right"); }
.bi-justify::before { content: map-get($bootstrap-icons-map, "justify"); }
.bi-kanban-fill::before { content: map-get($bootstrap-icons-map, "kanban-fill"); }
.bi-kanban::before { content: map-get($bootstrap-icons-map, "kanban"); }
.bi-key-fill::before { content: map-get($bootstrap-icons-map, "key-fill"); }
.bi-key::before { content: map-get($bootstrap-icons-map, "key"); }
.bi-keyboard-fill::before { content: map-get($bootstrap-icons-map, "keyboard-fill"); }
.bi-keyboard::before { content: map-get($bootstrap-icons-map, "keyboard"); }
.bi-ladder::before { content: map-get($bootstrap-icons-map, "ladder"); }
.bi-lamp-fill::before { content: map-get($bootstrap-icons-map, "lamp-fill"); }
.bi-lamp::before { content: map-get($bootstrap-icons-map, "lamp"); }
.bi-laptop-fill::before { content: map-get($bootstrap-icons-map, "laptop-fill"); }
.bi-laptop::before { content: map-get($bootstrap-icons-map, "laptop"); }
.bi-layer-backward::before { content: map-get($bootstrap-icons-map, "layer-backward"); }
.bi-layer-forward::before { content: map-get($bootstrap-icons-map, "layer-forward"); }
.bi-layers-fill::before { content: map-get($bootstrap-icons-map, "layers-fill"); }
.bi-layers-half::before { content: map-get($bootstrap-icons-map, "layers-half"); }
.bi-layers::before { content: map-get($bootstrap-icons-map, "layers"); }
.bi-layout-sidebar-inset-reverse::before { content: map-get($bootstrap-icons-map, "layout-sidebar-inset-reverse"); }
.bi-layout-sidebar-inset::before { content: map-get($bootstrap-icons-map, "layout-sidebar-inset"); }
.bi-layout-sidebar-reverse::before { content: map-get($bootstrap-icons-map, "layout-sidebar-reverse"); }
.bi-layout-sidebar::before { content: map-get($bootstrap-icons-map, "layout-sidebar"); }
.bi-layout-split::before { content: map-get($bootstrap-icons-map, "layout-split"); }
.bi-layout-text-sidebar-reverse::before { content: map-get($bootstrap-icons-map, "layout-text-sidebar-reverse"); }
.bi-layout-text-sidebar::before { content: map-get($bootstrap-icons-map, "layout-text-sidebar"); }
.bi-layout-text-window-reverse::before { content: map-get($bootstrap-icons-map, "layout-text-window-reverse"); }
.bi-layout-text-window::before { content: map-get($bootstrap-icons-map, "layout-text-window"); }
.bi-layout-three-columns::before { content: map-get($bootstrap-icons-map, "layout-three-columns"); }
.bi-layout-wtf::before { content: map-get($bootstrap-icons-map, "layout-wtf"); }
.bi-life-preserver::before { content: map-get($bootstrap-icons-map, "life-preserver"); }
.bi-lightbulb-fill::before { content: map-get($bootstrap-icons-map, "lightbulb-fill"); }
.bi-lightbulb-off-fill::before { content: map-get($bootstrap-icons-map, "lightbulb-off-fill"); }
.bi-lightbulb-off::before { content: map-get($bootstrap-icons-map, "lightbulb-off"); }
.bi-lightbulb::before { content: map-get($bootstrap-icons-map, "lightbulb"); }
.bi-lightning-charge-fill::before { content: map-get($bootstrap-icons-map, "lightning-charge-fill"); }
.bi-lightning-charge::before { content: map-get($bootstrap-icons-map, "lightning-charge"); }
.bi-lightning-fill::before { content: map-get($bootstrap-icons-map, "lightning-fill"); }
.bi-lightning::before { content: map-get($bootstrap-icons-map, "lightning"); }
.bi-link-45deg::before { content: map-get($bootstrap-icons-map, "link-45deg"); }
.bi-link::before { content: map-get($bootstrap-icons-map, "link"); }
.bi-linkedin::before { content: map-get($bootstrap-icons-map, "linkedin"); }
.bi-list-check::before { content: map-get($bootstrap-icons-map, "list-check"); }
.bi-list-nested::before { content: map-get($bootstrap-icons-map, "list-nested"); }
.bi-list-ol::before { content: map-get($bootstrap-icons-map, "list-ol"); }
.bi-list-stars::before { content: map-get($bootstrap-icons-map, "list-stars"); }
.bi-list-task::before { content: map-get($bootstrap-icons-map, "list-task"); }
.bi-list-ul::before { content: map-get($bootstrap-icons-map, "list-ul"); }
.bi-list::before { content: map-get($bootstrap-icons-map, "list"); }
.bi-lock-fill::before { content: map-get($bootstrap-icons-map, "lock-fill"); }
.bi-lock::before { content: map-get($bootstrap-icons-map, "lock"); }
.bi-mailbox::before { content: map-get($bootstrap-icons-map, "mailbox"); }
.bi-mailbox2::before { content: map-get($bootstrap-icons-map, "mailbox2"); }
.bi-map-fill::before { content: map-get($bootstrap-icons-map, "map-fill"); }
.bi-map::before { content: map-get($bootstrap-icons-map, "map"); }
.bi-markdown-fill::before { content: map-get($bootstrap-icons-map, "markdown-fill"); }
.bi-markdown::before { content: map-get($bootstrap-icons-map, "markdown"); }
.bi-mask::before { content: map-get($bootstrap-icons-map, "mask"); }
.bi-megaphone-fill::before { content: map-get($bootstrap-icons-map, "megaphone-fill"); }
.bi-megaphone::before { content: map-get($bootstrap-icons-map, "megaphone"); }
.bi-menu-app-fill::before { content: map-get($bootstrap-icons-map, "menu-app-fill"); }
.bi-menu-app::before { content: map-get($bootstrap-icons-map, "menu-app"); }
.bi-menu-button-fill::before { content: map-get($bootstrap-icons-map, "menu-button-fill"); }
.bi-menu-button-wide-fill::before { content: map-get($bootstrap-icons-map, "menu-button-wide-fill"); }
.bi-menu-button-wide::before { content: map-get($bootstrap-icons-map, "menu-button-wide"); }
.bi-menu-button::before { content: map-get($bootstrap-icons-map, "menu-button"); }
.bi-menu-down::before { content: map-get($bootstrap-icons-map, "menu-down"); }
.bi-menu-up::before { content: map-get($bootstrap-icons-map, "menu-up"); }
.bi-mic-fill::before { content: map-get($bootstrap-icons-map, "mic-fill"); }
.bi-mic-mute-fill::before { content: map-get($bootstrap-icons-map, "mic-mute-fill"); }
.bi-mic-mute::before { content: map-get($bootstrap-icons-map, "mic-mute"); }
.bi-mic::before { content: map-get($bootstrap-icons-map, "mic"); }
.bi-minecart-loaded::before { content: map-get($bootstrap-icons-map, "minecart-loaded"); }
.bi-minecart::before { content: map-get($bootstrap-icons-map, "minecart"); }
.bi-moisture::before { content: map-get($bootstrap-icons-map, "moisture"); }
.bi-moon-fill::before { content: map-get($bootstrap-icons-map, "moon-fill"); }
.bi-moon-stars-fill::before { content: map-get($bootstrap-icons-map, "moon-stars-fill"); }
.bi-moon-stars::before { content: map-get($bootstrap-icons-map, "moon-stars"); }
.bi-moon::before { content: map-get($bootstrap-icons-map, "moon"); }
.bi-mouse-fill::before { content: map-get($bootstrap-icons-map, "mouse-fill"); }
.bi-mouse::before { content: map-get($bootstrap-icons-map, "mouse"); }
.bi-mouse2-fill::before { content: map-get($bootstrap-icons-map, "mouse2-fill"); }
.bi-mouse2::before { content: map-get($bootstrap-icons-map, "mouse2"); }
.bi-mouse3-fill::before { content: map-get($bootstrap-icons-map, "mouse3-fill"); }
.bi-mouse3::before { content: map-get($bootstrap-icons-map, "mouse3"); }
.bi-music-note-beamed::before { content: map-get($bootstrap-icons-map, "music-note-beamed"); }
.bi-music-note-list::before { content: map-get($bootstrap-icons-map, "music-note-list"); }
.bi-music-note::before { content: map-get($bootstrap-icons-map, "music-note"); }
.bi-music-player-fill::before { content: map-get($bootstrap-icons-map, "music-player-fill"); }
.bi-music-player::before { content: map-get($bootstrap-icons-map, "music-player"); }
.bi-newspaper::before { content: map-get($bootstrap-icons-map, "newspaper"); }
.bi-node-minus-fill::before { content: map-get($bootstrap-icons-map, "node-minus-fill"); }
.bi-node-minus::before { content: map-get($bootstrap-icons-map, "node-minus"); }
.bi-node-plus-fill::before { content: map-get($bootstrap-icons-map, "node-plus-fill"); }
.bi-node-plus::before { content: map-get($bootstrap-icons-map, "node-plus"); }
.bi-nut-fill::before { content: map-get($bootstrap-icons-map, "nut-fill"); }
.bi-nut::before { content: map-get($bootstrap-icons-map, "nut"); }
.bi-octagon-fill::before { content: map-get($bootstrap-icons-map, "octagon-fill"); }
.bi-octagon-half::before { content: map-get($bootstrap-icons-map, "octagon-half"); }
.bi-octagon::before { content: map-get($bootstrap-icons-map, "octagon"); }
.bi-option::before { content: map-get($bootstrap-icons-map, "option"); }
.bi-outlet::before { content: map-get($bootstrap-icons-map, "outlet"); }
.bi-paint-bucket::before { content: map-get($bootstrap-icons-map, "paint-bucket"); }
.bi-palette-fill::before { content: map-get($bootstrap-icons-map, "palette-fill"); }
.bi-palette::before { content: map-get($bootstrap-icons-map, "palette"); }
.bi-palette2::before { content: map-get($bootstrap-icons-map, "palette2"); }
.bi-paperclip::before { content: map-get($bootstrap-icons-map, "paperclip"); }
.bi-paragraph::before { content: map-get($bootstrap-icons-map, "paragraph"); }
.bi-patch-check-fill::before { content: map-get($bootstrap-icons-map, "patch-check-fill"); }
.bi-patch-check::before { content: map-get($bootstrap-icons-map, "patch-check"); }
.bi-patch-exclamation-fill::before { content: map-get($bootstrap-icons-map, "patch-exclamation-fill"); }
.bi-patch-exclamation::before { content: map-get($bootstrap-icons-map, "patch-exclamation"); }
.bi-patch-minus-fill::before { content: map-get($bootstrap-icons-map, "patch-minus-fill"); }
.bi-patch-minus::before { content: map-get($bootstrap-icons-map, "patch-minus"); }
.bi-patch-plus-fill::before { content: map-get($bootstrap-icons-map, "patch-plus-fill"); }
.bi-patch-plus::before { content: map-get($bootstrap-icons-map, "patch-plus"); }
.bi-patch-question-fill::before { content: map-get($bootstrap-icons-map, "patch-question-fill"); }
.bi-patch-question::before { content: map-get($bootstrap-icons-map, "patch-question"); }
.bi-pause-btn-fill::before { content: map-get($bootstrap-icons-map, "pause-btn-fill"); }
.bi-pause-btn::before { content: map-get($bootstrap-icons-map, "pause-btn"); }
.bi-pause-circle-fill::before { content: map-get($bootstrap-icons-map, "pause-circle-fill"); }
.bi-pause-circle::before { content: map-get($bootstrap-icons-map, "pause-circle"); }
.bi-pause-fill::before { content: map-get($bootstrap-icons-map, "pause-fill"); }
.bi-pause::before { content: map-get($bootstrap-icons-map, "pause"); }
.bi-peace-fill::before { content: map-get($bootstrap-icons-map, "peace-fill"); }
.bi-peace::before { content: map-get($bootstrap-icons-map, "peace"); }
.bi-pen-fill::before { content: map-get($bootstrap-icons-map, "pen-fill"); }
.bi-pen::before { content: map-get($bootstrap-icons-map, "pen"); }
.bi-pencil-fill::before { content: map-get($bootstrap-icons-map, "pencil-fill"); }
.bi-pencil-square::before { content: map-get($bootstrap-icons-map, "pencil-square"); }
.bi-pencil::before { content: map-get($bootstrap-icons-map, "pencil"); }
.bi-pentagon-fill::before { content: map-get($bootstrap-icons-map, "pentagon-fill"); }
.bi-pentagon-half::before { content: map-get($bootstrap-icons-map, "pentagon-half"); }
.bi-pentagon::before { content: map-get($bootstrap-icons-map, "pentagon"); }
.bi-people-fill::before { content: map-get($bootstrap-icons-map, "people-fill"); }
.bi-people::before { content: map-get($bootstrap-icons-map, "people"); }
.bi-percent::before { content: map-get($bootstrap-icons-map, "percent"); }
.bi-person-badge-fill::before { content: map-get($bootstrap-icons-map, "person-badge-fill"); }
.bi-person-badge::before { content: map-get($bootstrap-icons-map, "person-badge"); }
.bi-person-bounding-box::before { content: map-get($bootstrap-icons-map, "person-bounding-box"); }
.bi-person-check-fill::before { content: map-get($bootstrap-icons-map, "person-check-fill"); }
.bi-person-check::before { content: map-get($bootstrap-icons-map, "person-check"); }
.bi-person-circle::before { content: map-get($bootstrap-icons-map, "person-circle"); }
.bi-person-dash-fill::before { content: map-get($bootstrap-icons-map, "person-dash-fill"); }
.bi-person-dash::before { content: map-get($bootstrap-icons-map, "person-dash"); }
.bi-person-fill::before { content: map-get($bootstrap-icons-map, "person-fill"); }
.bi-person-lines-fill::before { content: map-get($bootstrap-icons-map, "person-lines-fill"); }
.bi-person-plus-fill::before { content: map-get($bootstrap-icons-map, "person-plus-fill"); }
.bi-person-plus::before { content: map-get($bootstrap-icons-map, "person-plus"); }
.bi-person-square::before { content: map-get($bootstrap-icons-map, "person-square"); }
.bi-person-x-fill::before { content: map-get($bootstrap-icons-map, "person-x-fill"); }
.bi-person-x::before { content: map-get($bootstrap-icons-map, "person-x"); }
.bi-person::before { content: map-get($bootstrap-icons-map, "person"); }
.bi-phone-fill::before { content: map-get($bootstrap-icons-map, "phone-fill"); }
.bi-phone-landscape-fill::before { content: map-get($bootstrap-icons-map, "phone-landscape-fill"); }
.bi-phone-landscape::before { content: map-get($bootstrap-icons-map, "phone-landscape"); }
.bi-phone-vibrate-fill::before { content: map-get($bootstrap-icons-map, "phone-vibrate-fill"); }
.bi-phone-vibrate::before { content: map-get($bootstrap-icons-map, "phone-vibrate"); }
.bi-phone::before { content: map-get($bootstrap-icons-map, "phone"); }
.bi-pie-chart-fill::before { content: map-get($bootstrap-icons-map, "pie-chart-fill"); }
.bi-pie-chart::before { content: map-get($bootstrap-icons-map, "pie-chart"); }
.bi-pin-angle-fill::before { content: map-get($bootstrap-icons-map, "pin-angle-fill"); }
.bi-pin-angle::before { content: map-get($bootstrap-icons-map, "pin-angle"); }
.bi-pin-fill::before { content: map-get($bootstrap-icons-map, "pin-fill"); }
.bi-pin::before { content: map-get($bootstrap-icons-map, "pin"); }
.bi-pip-fill::before { content: map-get($bootstrap-icons-map, "pip-fill"); }
.bi-pip::before { content: map-get($bootstrap-icons-map, "pip"); }
.bi-play-btn-fill::before { content: map-get($bootstrap-icons-map, "play-btn-fill"); }
.bi-play-btn::before { content: map-get($bootstrap-icons-map, "play-btn"); }
.bi-play-circle-fill::before { content: map-get($bootstrap-icons-map, "play-circle-fill"); }
.bi-play-circle::before { content: map-get($bootstrap-icons-map, "play-circle"); }
.bi-play-fill::before { content: map-get($bootstrap-icons-map, "play-fill"); }
.bi-play::before { content: map-get($bootstrap-icons-map, "play"); }
.bi-plug-fill::before { content: map-get($bootstrap-icons-map, "plug-fill"); }
.bi-plug::before { content: map-get($bootstrap-icons-map, "plug"); }
.bi-plus-circle-dotted::before { content: map-get($bootstrap-icons-map, "plus-circle-dotted"); }
.bi-plus-circle-fill::before { content: map-get($bootstrap-icons-map, "plus-circle-fill"); }
.bi-plus-circle::before { content: map-get($bootstrap-icons-map, "plus-circle"); }
.bi-plus-square-dotted::before { content: map-get($bootstrap-icons-map, "plus-square-dotted"); }
.bi-plus-square-fill::before { content: map-get($bootstrap-icons-map, "plus-square-fill"); }
.bi-plus-square::before { content: map-get($bootstrap-icons-map, "plus-square"); }
.bi-plus::before { content: map-get($bootstrap-icons-map, "plus"); }
.bi-power::before { content: map-get($bootstrap-icons-map, "power"); }
.bi-printer-fill::before { content: map-get($bootstrap-icons-map, "printer-fill"); }
.bi-printer::before { content: map-get($bootstrap-icons-map, "printer"); }
.bi-puzzle-fill::before { content: map-get($bootstrap-icons-map, "puzzle-fill"); }
.bi-puzzle::before { content: map-get($bootstrap-icons-map, "puzzle"); }
.bi-question-circle-fill::before { content: map-get($bootstrap-icons-map, "question-circle-fill"); }
.bi-question-circle::before { content: map-get($bootstrap-icons-map, "question-circle"); }
.bi-question-diamond-fill::before { content: map-get($bootstrap-icons-map, "question-diamond-fill"); }
.bi-question-diamond::before { content: map-get($bootstrap-icons-map, "question-diamond"); }
.bi-question-octagon-fill::before { content: map-get($bootstrap-icons-map, "question-octagon-fill"); }
.bi-question-octagon::before { content: map-get($bootstrap-icons-map, "question-octagon"); }
.bi-question-square-fill::before { content: map-get($bootstrap-icons-map, "question-square-fill"); }
.bi-question-square::before { content: map-get($bootstrap-icons-map, "question-square"); }
.bi-question::before { content: map-get($bootstrap-icons-map, "question"); }
.bi-rainbow::before { content: map-get($bootstrap-icons-map, "rainbow"); }
.bi-receipt-cutoff::before { content: map-get($bootstrap-icons-map, "receipt-cutoff"); }
.bi-receipt::before { content: map-get($bootstrap-icons-map, "receipt"); }
.bi-reception-0::before { content: map-get($bootstrap-icons-map, "reception-0"); }
.bi-reception-1::before { content: map-get($bootstrap-icons-map, "reception-1"); }
.bi-reception-2::before { content: map-get($bootstrap-icons-map, "reception-2"); }
.bi-reception-3::before { content: map-get($bootstrap-icons-map, "reception-3"); }
.bi-reception-4::before { content: map-get($bootstrap-icons-map, "reception-4"); }
.bi-record-btn-fill::before { content: map-get($bootstrap-icons-map, "record-btn-fill"); }
.bi-record-btn::before { content: map-get($bootstrap-icons-map, "record-btn"); }
.bi-record-circle-fill::before { content: map-get($bootstrap-icons-map, "record-circle-fill"); }
.bi-record-circle::before { content: map-get($bootstrap-icons-map, "record-circle"); }
.bi-record-fill::before { content: map-get($bootstrap-icons-map, "record-fill"); }
.bi-record::before { content: map-get($bootstrap-icons-map, "record"); }
.bi-record2-fill::before { content: map-get($bootstrap-icons-map, "record2-fill"); }
.bi-record2::before { content: map-get($bootstrap-icons-map, "record2"); }
.bi-reply-all-fill::before { content: map-get($bootstrap-icons-map, "reply-all-fill"); }
.bi-reply-all::before { content: map-get($bootstrap-icons-map, "reply-all"); }
.bi-reply-fill::before { content: map-get($bootstrap-icons-map, "reply-fill"); }
.bi-reply::before { content: map-get($bootstrap-icons-map, "reply"); }
.bi-rss-fill::before { content: map-get($bootstrap-icons-map, "rss-fill"); }
.bi-rss::before { content: map-get($bootstrap-icons-map, "rss"); }
.bi-rulers::before { content: map-get($bootstrap-icons-map, "rulers"); }
.bi-save-fill::before { content: map-get($bootstrap-icons-map, "save-fill"); }
.bi-save::before { content: map-get($bootstrap-icons-map, "save"); }
.bi-save2-fill::before { content: map-get($bootstrap-icons-map, "save2-fill"); }
.bi-save2::before { content: map-get($bootstrap-icons-map, "save2"); }
.bi-scissors::before { content: map-get($bootstrap-icons-map, "scissors"); }
.bi-screwdriver::before { content: map-get($bootstrap-icons-map, "screwdriver"); }
.bi-search::before { content: map-get($bootstrap-icons-map, "search"); }
.bi-segmented-nav::before { content: map-get($bootstrap-icons-map, "segmented-nav"); }
.bi-server::before { content: map-get($bootstrap-icons-map, "server"); }
.bi-share-fill::before { content: map-get($bootstrap-icons-map, "share-fill"); }
.bi-share::before { content: map-get($bootstrap-icons-map, "share"); }
.bi-shield-check::before { content: map-get($bootstrap-icons-map, "shield-check"); }
.bi-shield-exclamation::before { content: map-get($bootstrap-icons-map, "shield-exclamation"); }
.bi-shield-fill-check::before { content: map-get($bootstrap-icons-map, "shield-fill-check"); }
.bi-shield-fill-exclamation::before { content: map-get($bootstrap-icons-map, "shield-fill-exclamation"); }
.bi-shield-fill-minus::before { content: map-get($bootstrap-icons-map, "shield-fill-minus"); }
.bi-shield-fill-plus::before { content: map-get($bootstrap-icons-map, "shield-fill-plus"); }
.bi-shield-fill-x::before { content: map-get($bootstrap-icons-map, "shield-fill-x"); }
.bi-shield-fill::before { content: map-get($bootstrap-icons-map, "shield-fill"); }
.bi-shield-lock-fill::before { content: map-get($bootstrap-icons-map, "shield-lock-fill"); }
.bi-shield-lock::before { content: map-get($bootstrap-icons-map, "shield-lock"); }
.bi-shield-minus::before { content: map-get($bootstrap-icons-map, "shield-minus"); }
.bi-shield-plus::before { content: map-get($bootstrap-icons-map, "shield-plus"); }
.bi-shield-shaded::before { content: map-get($bootstrap-icons-map, "shield-shaded"); }
.bi-shield-slash-fill::before { content: map-get($bootstrap-icons-map, "shield-slash-fill"); }
.bi-shield-slash::before { content: map-get($bootstrap-icons-map, "shield-slash"); }
.bi-shield-x::before { content: map-get($bootstrap-icons-map, "shield-x"); }
.bi-shield::before { content: map-get($bootstrap-icons-map, "shield"); }
.bi-shift-fill::before { content: map-get($bootstrap-icons-map, "shift-fill"); }
.bi-shift::before { content: map-get($bootstrap-icons-map, "shift"); }
.bi-shop-window::before { content: map-get($bootstrap-icons-map, "shop-window"); }
.bi-shop::before { content: map-get($bootstrap-icons-map, "shop"); }
.bi-shuffle::before { content: map-get($bootstrap-icons-map, "shuffle"); }
.bi-signpost-2-fill::before { content: map-get($bootstrap-icons-map, "signpost-2-fill"); }
.bi-signpost-2::before { content: map-get($bootstrap-icons-map, "signpost-2"); }
.bi-signpost-fill::before { content: map-get($bootstrap-icons-map, "signpost-fill"); }
.bi-signpost-split-fill::before { content: map-get($bootstrap-icons-map, "signpost-split-fill"); }
.bi-signpost-split::before { content: map-get($bootstrap-icons-map, "signpost-split"); }
.bi-signpost::before { content: map-get($bootstrap-icons-map, "signpost"); }
.bi-sim-fill::before { content: map-get($bootstrap-icons-map, "sim-fill"); }
.bi-sim::before { content: map-get($bootstrap-icons-map, "sim"); }
.bi-skip-backward-btn-fill::before { content: map-get($bootstrap-icons-map, "skip-backward-btn-fill"); }
.bi-skip-backward-btn::before { content: map-get($bootstrap-icons-map, "skip-backward-btn"); }
.bi-skip-backward-circle-fill::before { content: map-get($bootstrap-icons-map, "skip-backward-circle-fill"); }
.bi-skip-backward-circle::before { content: map-get($bootstrap-icons-map, "skip-backward-circle"); }
.bi-skip-backward-fill::before { content: map-get($bootstrap-icons-map, "skip-backward-fill"); }
.bi-skip-backward::before { content: map-get($bootstrap-icons-map, "skip-backward"); }
.bi-skip-end-btn-fill::before { content: map-get($bootstrap-icons-map, "skip-end-btn-fill"); }
.bi-skip-end-btn::before { content: map-get($bootstrap-icons-map, "skip-end-btn"); }
.bi-skip-end-circle-fill::before { content: map-get($bootstrap-icons-map, "skip-end-circle-fill"); }
.bi-skip-end-circle::before { content: map-get($bootstrap-icons-map, "skip-end-circle"); }
.bi-skip-end-fill::before { content: map-get($bootstrap-icons-map, "skip-end-fill"); }
.bi-skip-end::before { content: map-get($bootstrap-icons-map, "skip-end"); }
.bi-skip-forward-btn-fill::before { content: map-get($bootstrap-icons-map, "skip-forward-btn-fill"); }
.bi-skip-forward-btn::before { content: map-get($bootstrap-icons-map, "skip-forward-btn"); }
.bi-skip-forward-circle-fill::before { content: map-get($bootstrap-icons-map, "skip-forward-circle-fill"); }
.bi-skip-forward-circle::before { content: map-get($bootstrap-icons-map, "skip-forward-circle"); }
.bi-skip-forward-fill::before { content: map-get($bootstrap-icons-map, "skip-forward-fill"); }
.bi-skip-forward::before { content: map-get($bootstrap-icons-map, "skip-forward"); }
.bi-skip-start-btn-fill::before { content: map-get($bootstrap-icons-map, "skip-start-btn-fill"); }
.bi-skip-start-btn::before { content: map-get($bootstrap-icons-map, "skip-start-btn"); }
.bi-skip-start-circle-fill::before { content: map-get($bootstrap-icons-map, "skip-start-circle-fill"); }
.bi-skip-start-circle::before { content: map-get($bootstrap-icons-map, "skip-start-circle"); }
.bi-skip-start-fill::before { content: map-get($bootstrap-icons-map, "skip-start-fill"); }
.bi-skip-start::before { content: map-get($bootstrap-icons-map, "skip-start"); }
.bi-slack::before { content: map-get($bootstrap-icons-map, "slack"); }
.bi-slash-circle-fill::before { content: map-get($bootstrap-icons-map, "slash-circle-fill"); }
.bi-slash-circle::before { content: map-get($bootstrap-icons-map, "slash-circle"); }
.bi-slash-square-fill::before { content: map-get($bootstrap-icons-map, "slash-square-fill"); }
.bi-slash-square::before { content: map-get($bootstrap-icons-map, "slash-square"); }
.bi-slash::before { content: map-get($bootstrap-icons-map, "slash"); }
.bi-sliders::before { content: map-get($bootstrap-icons-map, "sliders"); }
.bi-smartwatch::before { content: map-get($bootstrap-icons-map, "smartwatch"); }
.bi-snow::before { content: map-get($bootstrap-icons-map, "snow"); }
.bi-snow2::before { content: map-get($bootstrap-icons-map, "snow2"); }
.bi-snow3::before { content: map-get($bootstrap-icons-map, "snow3"); }
.bi-sort-alpha-down-alt::before { content: map-get($bootstrap-icons-map, "sort-alpha-down-alt"); }
.bi-sort-alpha-down::before { content: map-get($bootstrap-icons-map, "sort-alpha-down"); }
.bi-sort-alpha-up-alt::before { content: map-get($bootstrap-icons-map, "sort-alpha-up-alt"); }
.bi-sort-alpha-up::before { content: map-get($bootstrap-icons-map, "sort-alpha-up"); }
.bi-sort-down-alt::before { content: map-get($bootstrap-icons-map, "sort-down-alt"); }
.bi-sort-down::before { content: map-get($bootstrap-icons-map, "sort-down"); }
.bi-sort-numeric-down-alt::before { content: map-get($bootstrap-icons-map, "sort-numeric-down-alt"); }
.bi-sort-numeric-down::before { content: map-get($bootstrap-icons-map, "sort-numeric-down"); }
.bi-sort-numeric-up-alt::before { content: map-get($bootstrap-icons-map, "sort-numeric-up-alt"); }
.bi-sort-numeric-up::before { content: map-get($bootstrap-icons-map, "sort-numeric-up"); }
.bi-sort-up-alt::before { content: map-get($bootstrap-icons-map, "sort-up-alt"); }
.bi-sort-up::before { content: map-get($bootstrap-icons-map, "sort-up"); }
.bi-soundwave::before { content: map-get($bootstrap-icons-map, "soundwave"); }
.bi-speaker-fill::before { content: map-get($bootstrap-icons-map, "speaker-fill"); }
.bi-speaker::before { content: map-get($bootstrap-icons-map, "speaker"); }
.bi-speedometer::before { content: map-get($bootstrap-icons-map, "speedometer"); }
.bi-speedometer2::before { content: map-get($bootstrap-icons-map, "speedometer2"); }
.bi-spellcheck::before { content: map-get($bootstrap-icons-map, "spellcheck"); }
.bi-square-fill::before { content: map-get($bootstrap-icons-map, "square-fill"); }
.bi-square-half::before { content: map-get($bootstrap-icons-map, "square-half"); }
.bi-square::before { content: map-get($bootstrap-icons-map, "square"); }
.bi-stack::before { content: map-get($bootstrap-icons-map, "stack"); }
.bi-star-fill::before { content: map-get($bootstrap-icons-map, "star-fill"); }
.bi-star-half::before { content: map-get($bootstrap-icons-map, "star-half"); }
.bi-star::before { content: map-get($bootstrap-icons-map, "star"); }
.bi-stars::before { content: map-get($bootstrap-icons-map, "stars"); }
.bi-stickies-fill::before { content: map-get($bootstrap-icons-map, "stickies-fill"); }
.bi-stickies::before { content: map-get($bootstrap-icons-map, "stickies"); }
.bi-sticky-fill::before { content: map-get($bootstrap-icons-map, "sticky-fill"); }
.bi-sticky::before { content: map-get($bootstrap-icons-map, "sticky"); }
.bi-stop-btn-fill::before { content: map-get($bootstrap-icons-map, "stop-btn-fill"); }
.bi-stop-btn::before { content: map-get($bootstrap-icons-map, "stop-btn"); }
.bi-stop-circle-fill::before { content: map-get($bootstrap-icons-map, "stop-circle-fill"); }
.bi-stop-circle::before { content: map-get($bootstrap-icons-map, "stop-circle"); }
.bi-stop-fill::before { content: map-get($bootstrap-icons-map, "stop-fill"); }
.bi-stop::before { content: map-get($bootstrap-icons-map, "stop"); }
.bi-stoplights-fill::before { content: map-get($bootstrap-icons-map, "stoplights-fill"); }
.bi-stoplights::before { content: map-get($bootstrap-icons-map, "stoplights"); }
.bi-stopwatch-fill::before { content: map-get($bootstrap-icons-map, "stopwatch-fill"); }
.bi-stopwatch::before { content: map-get($bootstrap-icons-map, "stopwatch"); }
.bi-subtract::before { content: map-get($bootstrap-icons-map, "subtract"); }
.bi-suit-club-fill::before { content: map-get($bootstrap-icons-map, "suit-club-fill"); }
.bi-suit-club::before { content: map-get($bootstrap-icons-map, "suit-club"); }
.bi-suit-diamond-fill::before { content: map-get($bootstrap-icons-map, "suit-diamond-fill"); }
.bi-suit-diamond::before { content: map-get($bootstrap-icons-map, "suit-diamond"); }
.bi-suit-heart-fill::before { content: map-get($bootstrap-icons-map, "suit-heart-fill"); }
.bi-suit-heart::before { content: map-get($bootstrap-icons-map, "suit-heart"); }
.bi-suit-spade-fill::before { content: map-get($bootstrap-icons-map, "suit-spade-fill"); }
.bi-suit-spade::before { content: map-get($bootstrap-icons-map, "suit-spade"); }
.bi-sun-fill::before { content: map-get($bootstrap-icons-map, "sun-fill"); }
.bi-sun::before { content: map-get($bootstrap-icons-map, "sun"); }
.bi-sunglasses::before { content: map-get($bootstrap-icons-map, "sunglasses"); }
.bi-sunrise-fill::before { content: map-get($bootstrap-icons-map, "sunrise-fill"); }
.bi-sunrise::before { content: map-get($bootstrap-icons-map, "sunrise"); }
.bi-sunset-fill::before { content: map-get($bootstrap-icons-map, "sunset-fill"); }
.bi-sunset::before { content: map-get($bootstrap-icons-map, "sunset"); }
.bi-symmetry-horizontal::before { content: map-get($bootstrap-icons-map, "symmetry-horizontal"); }
.bi-symmetry-vertical::before { content: map-get($bootstrap-icons-map, "symmetry-vertical"); }
.bi-table::before { content: map-get($bootstrap-icons-map, "table"); }
.bi-tablet-fill::before { content: map-get($bootstrap-icons-map, "tablet-fill"); }
.bi-tablet-landscape-fill::before { content: map-get($bootstrap-icons-map, "tablet-landscape-fill"); }
.bi-tablet-landscape::before { content: map-get($bootstrap-icons-map, "tablet-landscape"); }
.bi-tablet::before { content: map-get($bootstrap-icons-map, "tablet"); }
.bi-tag-fill::before { content: map-get($bootstrap-icons-map, "tag-fill"); }
.bi-tag::before { content: map-get($bootstrap-icons-map, "tag"); }
.bi-tags-fill::before { content: map-get($bootstrap-icons-map, "tags-fill"); }
.bi-tags::before { content: map-get($bootstrap-icons-map, "tags"); }
.bi-telegram::before { content: map-get($bootstrap-icons-map, "telegram"); }
.bi-telephone-fill::before { content: map-get($bootstrap-icons-map, "telephone-fill"); }
.bi-telephone-forward-fill::before { content: map-get($bootstrap-icons-map, "telephone-forward-fill"); }
.bi-telephone-forward::before { content: map-get($bootstrap-icons-map, "telephone-forward"); }
.bi-telephone-inbound-fill::before { content: map-get($bootstrap-icons-map, "telephone-inbound-fill"); }
.bi-telephone-inbound::before { content: map-get($bootstrap-icons-map, "telephone-inbound"); }
.bi-telephone-minus-fill::before { content: map-get($bootstrap-icons-map, "telephone-minus-fill"); }
.bi-telephone-minus::before { content: map-get($bootstrap-icons-map, "telephone-minus"); }
.bi-telephone-outbound-fill::before { content: map-get($bootstrap-icons-map, "telephone-outbound-fill"); }
.bi-telephone-outbound::before { content: map-get($bootstrap-icons-map, "telephone-outbound"); }
.bi-telephone-plus-fill::before { content: map-get($bootstrap-icons-map, "telephone-plus-fill"); }
.bi-telephone-plus::before { content: map-get($bootstrap-icons-map, "telephone-plus"); }
.bi-telephone-x-fill::before { content: map-get($bootstrap-icons-map, "telephone-x-fill"); }
.bi-telephone-x::before { content: map-get($bootstrap-icons-map, "telephone-x"); }
.bi-telephone::before { content: map-get($bootstrap-icons-map, "telephone"); }
.bi-terminal-fill::before { content: map-get($bootstrap-icons-map, "terminal-fill"); }
.bi-terminal::before { content: map-get($bootstrap-icons-map, "terminal"); }
.bi-text-center::before { content: map-get($bootstrap-icons-map, "text-center"); }
.bi-text-indent-left::before { content: map-get($bootstrap-icons-map, "text-indent-left"); }
.bi-text-indent-right::before { content: map-get($bootstrap-icons-map, "text-indent-right"); }
.bi-text-left::before { content: map-get($bootstrap-icons-map, "text-left"); }
.bi-text-paragraph::before { content: map-get($bootstrap-icons-map, "text-paragraph"); }
.bi-text-right::before { content: map-get($bootstrap-icons-map, "text-right"); }
.bi-textarea-resize::before { content: map-get($bootstrap-icons-map, "textarea-resize"); }
.bi-textarea-t::before { content: map-get($bootstrap-icons-map, "textarea-t"); }
.bi-textarea::before { content: map-get($bootstrap-icons-map, "textarea"); }
.bi-thermometer-half::before { content: map-get($bootstrap-icons-map, "thermometer-half"); }
.bi-thermometer-high::before { content: map-get($bootstrap-icons-map, "thermometer-high"); }
.bi-thermometer-low::before { content: map-get($bootstrap-icons-map, "thermometer-low"); }
.bi-thermometer-snow::before { content: map-get($bootstrap-icons-map, "thermometer-snow"); }
.bi-thermometer-sun::before { content: map-get($bootstrap-icons-map, "thermometer-sun"); }
.bi-thermometer::before { content: map-get($bootstrap-icons-map, "thermometer"); }
.bi-three-dots-vertical::before { content: map-get($bootstrap-icons-map, "three-dots-vertical"); }
.bi-three-dots::before { content: map-get($bootstrap-icons-map, "three-dots"); }
.bi-toggle-off::before { content: map-get($bootstrap-icons-map, "toggle-off"); }
.bi-toggle-on::before { content: map-get($bootstrap-icons-map, "toggle-on"); }
.bi-toggle2-off::before { content: map-get($bootstrap-icons-map, "toggle2-off"); }
.bi-toggle2-on::before { content: map-get($bootstrap-icons-map, "toggle2-on"); }
.bi-toggles::before { content: map-get($bootstrap-icons-map, "toggles"); }
.bi-toggles2::before { content: map-get($bootstrap-icons-map, "toggles2"); }
.bi-tools::before { content: map-get($bootstrap-icons-map, "tools"); }
.bi-tornado::before { content: map-get($bootstrap-icons-map, "tornado"); }
.bi-trash-fill::before { content: map-get($bootstrap-icons-map, "trash-fill"); }
.bi-trash::before { content: map-get($bootstrap-icons-map, "trash"); }
.bi-trash2-fill::before { content: map-get($bootstrap-icons-map, "trash2-fill"); }
.bi-trash2::before { content: map-get($bootstrap-icons-map, "trash2"); }
.bi-tree-fill::before { content: map-get($bootstrap-icons-map, "tree-fill"); }
.bi-tree::before { content: map-get($bootstrap-icons-map, "tree"); }
.bi-triangle-fill::before { content: map-get($bootstrap-icons-map, "triangle-fill"); }
.bi-triangle-half::before { content: map-get($bootstrap-icons-map, "triangle-half"); }
.bi-triangle::before { content: map-get($bootstrap-icons-map, "triangle"); }
.bi-trophy-fill::before { content: map-get($bootstrap-icons-map, "trophy-fill"); }
.bi-trophy::before { content: map-get($bootstrap-icons-map, "trophy"); }
.bi-tropical-storm::before { content: map-get($bootstrap-icons-map, "tropical-storm"); }
.bi-truck-flatbed::before { content: map-get($bootstrap-icons-map, "truck-flatbed"); }
.bi-truck::before { content: map-get($bootstrap-icons-map, "truck"); }
.bi-tsunami::before { content: map-get($bootstrap-icons-map, "tsunami"); }
.bi-tv-fill::before { content: map-get($bootstrap-icons-map, "tv-fill"); }
.bi-tv::before { content: map-get($bootstrap-icons-map, "tv"); }
.bi-twitch::before { content: map-get($bootstrap-icons-map, "twitch"); }
.bi-twitter::before { content: map-get($bootstrap-icons-map, "twitter"); }
.bi-type-bold::before { content: map-get($bootstrap-icons-map, "type-bold"); }
.bi-type-h1::before { content: map-get($bootstrap-icons-map, "type-h1"); }
.bi-type-h2::before { content: map-get($bootstrap-icons-map, "type-h2"); }
.bi-type-h3::before { content: map-get($bootstrap-icons-map, "type-h3"); }
.bi-type-italic::before { content: map-get($bootstrap-icons-map, "type-italic"); }
.bi-type-strikethrough::before { content: map-get($bootstrap-icons-map, "type-strikethrough"); }
.bi-type-underline::before { content: map-get($bootstrap-icons-map, "type-underline"); }
.bi-type::before { content: map-get($bootstrap-icons-map, "type"); }
.bi-ui-checks-grid::before { content: map-get($bootstrap-icons-map, "ui-checks-grid"); }
.bi-ui-checks::before { content: map-get($bootstrap-icons-map, "ui-checks"); }
.bi-ui-radios-grid::before { content: map-get($bootstrap-icons-map, "ui-radios-grid"); }
.bi-ui-radios::before { content: map-get($bootstrap-icons-map, "ui-radios"); }
.bi-umbrella-fill::before { content: map-get($bootstrap-icons-map, "umbrella-fill"); }
.bi-umbrella::before { content: map-get($bootstrap-icons-map, "umbrella"); }
.bi-union::before { content: map-get($bootstrap-icons-map, "union"); }
.bi-unlock-fill::before { content: map-get($bootstrap-icons-map, "unlock-fill"); }
.bi-unlock::before { content: map-get($bootstrap-icons-map, "unlock"); }
.bi-upc-scan::before { content: map-get($bootstrap-icons-map, "upc-scan"); }
.bi-upc::before { content: map-get($bootstrap-icons-map, "upc"); }
.bi-upload::before { content: map-get($bootstrap-icons-map, "upload"); }
.bi-vector-pen::before { content: map-get($bootstrap-icons-map, "vector-pen"); }
.bi-view-list::before { content: map-get($bootstrap-icons-map, "view-list"); }
.bi-view-stacked::before { content: map-get($bootstrap-icons-map, "view-stacked"); }
.bi-vinyl-fill::before { content: map-get($bootstrap-icons-map, "vinyl-fill"); }
.bi-vinyl::before { content: map-get($bootstrap-icons-map, "vinyl"); }
.bi-voicemail::before { content: map-get($bootstrap-icons-map, "voicemail"); }
.bi-volume-down-fill::before { content: map-get($bootstrap-icons-map, "volume-down-fill"); }
.bi-volume-down::before { content: map-get($bootstrap-icons-map, "volume-down"); }
.bi-volume-mute-fill::before { content: map-get($bootstrap-icons-map, "volume-mute-fill"); }
.bi-volume-mute::before { content: map-get($bootstrap-icons-map, "volume-mute"); }
.bi-volume-off-fill::before { content: map-get($bootstrap-icons-map, "volume-off-fill"); }
.bi-volume-off::before { content: map-get($bootstrap-icons-map, "volume-off"); }
.bi-volume-up-fill::before { content: map-get($bootstrap-icons-map, "volume-up-fill"); }
.bi-volume-up::before { content: map-get($bootstrap-icons-map, "volume-up"); }
.bi-vr::before { content: map-get($bootstrap-icons-map, "vr"); }
.bi-wallet-fill::before { content: map-get($bootstrap-icons-map, "wallet-fill"); }
.bi-wallet::before { content: map-get($bootstrap-icons-map, "wallet"); }
.bi-wallet2::before { content: map-get($bootstrap-icons-map, "wallet2"); }
.bi-watch::before { content: map-get($bootstrap-icons-map, "watch"); }
.bi-water::before { content: map-get($bootstrap-icons-map, "water"); }
.bi-whatsapp::before { content: map-get($bootstrap-icons-map, "whatsapp"); }
.bi-wifi-1::before { content: map-get($bootstrap-icons-map, "wifi-1"); }
.bi-wifi-2::before { content: map-get($bootstrap-icons-map, "wifi-2"); }
.bi-wifi-off::before { content: map-get($bootstrap-icons-map, "wifi-off"); }
.bi-wifi::before { content: map-get($bootstrap-icons-map, "wifi"); }
.bi-wind::before { content: map-get($bootstrap-icons-map, "wind"); }
.bi-window-dock::before { content: map-get($bootstrap-icons-map, "window-dock"); }
.bi-window-sidebar::before { content: map-get($bootstrap-icons-map, "window-sidebar"); }
.bi-window::before { content: map-get($bootstrap-icons-map, "window"); }
.bi-wrench::before { content: map-get($bootstrap-icons-map, "wrench"); }
.bi-x-circle-fill::before { content: map-get($bootstrap-icons-map, "x-circle-fill"); }
.bi-x-circle::before { content: map-get($bootstrap-icons-map, "x-circle"); }
.bi-x-diamond-fill::before { content: map-get($bootstrap-icons-map, "x-diamond-fill"); }
.bi-x-diamond::before { content: map-get($bootstrap-icons-map, "x-diamond"); }
.bi-x-octagon-fill::before { content: map-get($bootstrap-icons-map, "x-octagon-fill"); }
.bi-x-octagon::before { content: map-get($bootstrap-icons-map, "x-octagon"); }
.bi-x-square-fill::before { content: map-get($bootstrap-icons-map, "x-square-fill"); }
.bi-x-square::before { content: map-get($bootstrap-icons-map, "x-square"); }
.bi-x::before { content: map-get($bootstrap-icons-map, "x"); }
.bi-youtube::before { content: map-get($bootstrap-icons-map, "youtube"); }
.bi-zoom-in::before { content: map-get($bootstrap-icons-map, "zoom-in"); }
.bi-zoom-out::before { content: map-get($bootstrap-icons-map, "zoom-out"); }
.bi-bank::before { content: map-get($bootstrap-icons-map, "bank"); }
.bi-bank2::before { content: map-get($bootstrap-icons-map, "bank2"); }
.bi-bell-slash-fill::before { content: map-get($bootstrap-icons-map, "bell-slash-fill"); }
.bi-bell-slash::before { content: map-get($bootstrap-icons-map, "bell-slash"); }
.bi-cash-coin::before { content: map-get($bootstrap-icons-map, "cash-coin"); }
.bi-check-lg::before { content: map-get($bootstrap-icons-map, "check-lg"); }
.bi-coin::before { content: map-get($bootstrap-icons-map, "coin"); }
.bi-currency-bitcoin::before { content: map-get($bootstrap-icons-map, "currency-bitcoin"); }
.bi-currency-dollar::before { content: map-get($bootstrap-icons-map, "currency-dollar"); }
.bi-currency-euro::before { content: map-get($bootstrap-icons-map, "currency-euro"); }
.bi-currency-exchange::before { content: map-get($bootstrap-icons-map, "currency-exchange"); }
.bi-currency-pound::before { content: map-get($bootstrap-icons-map, "currency-pound"); }
.bi-currency-yen::before { content: map-get($bootstrap-icons-map, "currency-yen"); }
.bi-dash-lg::before { content: map-get($bootstrap-icons-map, "dash-lg"); }
.bi-exclamation-lg::before { content: map-get($bootstrap-icons-map, "exclamation-lg"); }
.bi-file-earmark-pdf-fill::before { content: map-get($bootstrap-icons-map, "file-earmark-pdf-fill"); }
.bi-file-earmark-pdf::before { content: map-get($bootstrap-icons-map, "file-earmark-pdf"); }
.bi-file-pdf-fill::before { content: map-get($bootstrap-icons-map, "file-pdf-fill"); }
.bi-file-pdf::before { content: map-get($bootstrap-icons-map, "file-pdf"); }
.bi-gender-ambiguous::before { content: map-get($bootstrap-icons-map, "gender-ambiguous"); }
.bi-gender-female::before { content: map-get($bootstrap-icons-map, "gender-female"); }
.bi-gender-male::before { content: map-get($bootstrap-icons-map, "gender-male"); }
.bi-gender-trans::before { content: map-get($bootstrap-icons-map, "gender-trans"); }
.bi-headset-vr::before { content: map-get($bootstrap-icons-map, "headset-vr"); }
.bi-info-lg::before { content: map-get($bootstrap-icons-map, "info-lg"); }
.bi-mastodon::before { content: map-get($bootstrap-icons-map, "mastodon"); }
.bi-messenger::before { content: map-get($bootstrap-icons-map, "messenger"); }
.bi-piggy-bank-fill::before { content: map-get($bootstrap-icons-map, "piggy-bank-fill"); }
.bi-piggy-bank::before { content: map-get($bootstrap-icons-map, "piggy-bank"); }
.bi-pin-map-fill::before { content: map-get($bootstrap-icons-map, "pin-map-fill"); }
.bi-pin-map::before { content: map-get($bootstrap-icons-map, "pin-map"); }
.bi-plus-lg::before { content: map-get($bootstrap-icons-map, "plus-lg"); }
.bi-question-lg::before { content: map-get($bootstrap-icons-map, "question-lg"); }
.bi-recycle::before { content: map-get($bootstrap-icons-map, "recycle"); }
.bi-reddit::before { content: map-get($bootstrap-icons-map, "reddit"); }
.bi-safe-fill::before { content: map-get($bootstrap-icons-map, "safe-fill"); }
.bi-safe2-fill::before { content: map-get($bootstrap-icons-map, "safe2-fill"); }
.bi-safe2::before { content: map-get($bootstrap-icons-map, "safe2"); }
.bi-sd-card-fill::before { content: map-get($bootstrap-icons-map, "sd-card-fill"); }
.bi-sd-card::before { content: map-get($bootstrap-icons-map, "sd-card"); }
.bi-skype::before { content: map-get($bootstrap-icons-map, "skype"); }
.bi-slash-lg::before { content: map-get($bootstrap-icons-map, "slash-lg"); }
.bi-translate::before { content: map-get($bootstrap-icons-map, "translate"); }
.bi-x-lg::before { content: map-get($bootstrap-icons-map, "x-lg"); }
.bi-safe::before { content: map-get($bootstrap-icons-map, "safe"); }
.bi-apple::before { content: map-get($bootstrap-icons-map, "apple"); }
.bi-microsoft::before { content: map-get($bootstrap-icons-map, "microsoft"); }
.bi-windows::before { content: map-get($bootstrap-icons-map, "windows"); }
.bi-behance::before { content: map-get($bootstrap-icons-map, "behance"); }
.bi-dribbble::before { content: map-get($bootstrap-icons-map, "dribbble"); }
.bi-line::before { content: map-get($bootstrap-icons-map, "line"); }
.bi-medium::before { content: map-get($bootstrap-icons-map, "medium"); }
.bi-paypal::before { content: map-get($bootstrap-icons-map, "paypal"); }
.bi-pinterest::before { content: map-get($bootstrap-icons-map, "pinterest"); }
.bi-signal::before { content: map-get($bootstrap-icons-map, "signal"); }
.bi-snapchat::before { content: map-get($bootstrap-icons-map, "snapchat"); }
.bi-spotify::before { content: map-get($bootstrap-icons-map, "spotify"); }
.bi-stack-overflow::before { content: map-get($bootstrap-icons-map, "stack-overflow"); }
.bi-strava::before { content: map-get($bootstrap-icons-map, "strava"); }
.bi-wordpress::before { content: map-get($bootstrap-icons-map, "wordpress"); }
.bi-vimeo::before { content: map-get($bootstrap-icons-map, "vimeo"); }
.bi-activity::before { content: map-get($bootstrap-icons-map, "activity"); }
.bi-easel2-fill::before { content: map-get($bootstrap-icons-map, "easel2-fill"); }
.bi-easel2::before { content: map-get($bootstrap-icons-map, "easel2"); }
.bi-easel3-fill::before { content: map-get($bootstrap-icons-map, "easel3-fill"); }
.bi-easel3::before { content: map-get($bootstrap-icons-map, "easel3"); }
.bi-fan::before { content: map-get($bootstrap-icons-map, "fan"); }
.bi-fingerprint::before { content: map-get($bootstrap-icons-map, "fingerprint"); }
.bi-graph-down-arrow::before { content: map-get($bootstrap-icons-map, "graph-down-arrow"); }
.bi-graph-up-arrow::before { content: map-get($bootstrap-icons-map, "graph-up-arrow"); }
.bi-hypnotize::before { content: map-get($bootstrap-icons-map, "hypnotize"); }
.bi-magic::before { content: map-get($bootstrap-icons-map, "magic"); }
.bi-person-rolodex::before { content: map-get($bootstrap-icons-map, "person-rolodex"); }
.bi-person-video::before { content: map-get($bootstrap-icons-map, "person-video"); }
.bi-person-video2::before { content: map-get($bootstrap-icons-map, "person-video2"); }
.bi-person-video3::before { content: map-get($bootstrap-icons-map, "person-video3"); }
.bi-person-workspace::before { content: map-get($bootstrap-icons-map, "person-workspace"); }
.bi-radioactive::before { content: map-get($bootstrap-icons-map, "radioactive"); }
.bi-webcam-fill::before { content: map-get($bootstrap-icons-map, "webcam-fill"); }
.bi-webcam::before { content: map-get($bootstrap-icons-map, "webcam"); }
.bi-yin-yang::before { content: map-get($bootstrap-icons-map, "yin-yang"); }
.bi-bandaid-fill::before { content: map-get($bootstrap-icons-map, "bandaid-fill"); }
.bi-bandaid::before { content: map-get($bootstrap-icons-map, "bandaid"); }
.bi-bluetooth::before { content: map-get($bootstrap-icons-map, "bluetooth"); }
.bi-body-text::before { content: map-get($bootstrap-icons-map, "body-text"); }
.bi-boombox::before { content: map-get($bootstrap-icons-map, "boombox"); }
.bi-boxes::before { content: map-get($bootstrap-icons-map, "boxes"); }
.bi-dpad-fill::before { content: map-get($bootstrap-icons-map, "dpad-fill"); }
.bi-dpad::before { content: map-get($bootstrap-icons-map, "dpad"); }
.bi-ear-fill::before { content: map-get($bootstrap-icons-map, "ear-fill"); }
.bi-ear::before { content: map-get($bootstrap-icons-map, "ear"); }
.bi-envelope-check-1::before { content: map-get($bootstrap-icons-map, "envelope-check-1"); }
.bi-envelope-check-fill::before { content: map-get($bootstrap-icons-map, "envelope-check-fill"); }
.bi-envelope-check::before { content: map-get($bootstrap-icons-map, "envelope-check"); }
.bi-envelope-dash-1::before { content: map-get($bootstrap-icons-map, "envelope-dash-1"); }
.bi-envelope-dash-fill::before { content: map-get($bootstrap-icons-map, "envelope-dash-fill"); }
.bi-envelope-dash::before { content: map-get($bootstrap-icons-map, "envelope-dash"); }
.bi-envelope-exclamation-1::before { content: map-get($bootstrap-icons-map, "envelope-exclamation-1"); }
.bi-envelope-exclamation-fill::before { content: map-get($bootstrap-icons-map, "envelope-exclamation-fill"); }
.bi-envelope-exclamation::before { content: map-get($bootstrap-icons-map, "envelope-exclamation"); }
.bi-envelope-plus-fill::before { content: map-get($bootstrap-icons-map, "envelope-plus-fill"); }
.bi-envelope-plus::before { content: map-get($bootstrap-icons-map, "envelope-plus"); }
.bi-envelope-slash-1::before { content: map-get($bootstrap-icons-map, "envelope-slash-1"); }
.bi-envelope-slash-fill::before { content: map-get($bootstrap-icons-map, "envelope-slash-fill"); }
.bi-envelope-slash::before { content: map-get($bootstrap-icons-map, "envelope-slash"); }
.bi-envelope-x-1::before { content: map-get($bootstrap-icons-map, "envelope-x-1"); }
.bi-envelope-x-fill::before { content: map-get($bootstrap-icons-map, "envelope-x-fill"); }
.bi-envelope-x::before { content: map-get($bootstrap-icons-map, "envelope-x"); }
.bi-explicit-fill::before { content: map-get($bootstrap-icons-map, "explicit-fill"); }
.bi-explicit::before { content: map-get($bootstrap-icons-map, "explicit"); }
.bi-git::before { content: map-get($bootstrap-icons-map, "git"); }
.bi-infinity::before { content: map-get($bootstrap-icons-map, "infinity"); }
.bi-list-columns-reverse::before { content: map-get($bootstrap-icons-map, "list-columns-reverse"); }
.bi-list-columns::before { content: map-get($bootstrap-icons-map, "list-columns"); }
.bi-meta::before { content: map-get($bootstrap-icons-map, "meta"); }
.bi-mortorboard-fill::before { content: map-get($bootstrap-icons-map, "mortorboard-fill"); }
.bi-mortorboard::before { content: map-get($bootstrap-icons-map, "mortorboard"); }
.bi-nintendo-switch::before { content: map-get($bootstrap-icons-map, "nintendo-switch"); }
.bi-pc-display-horizontal::before { content: map-get($bootstrap-icons-map, "pc-display-horizontal"); }
.bi-pc-display::before { content: map-get($bootstrap-icons-map, "pc-display"); }
.bi-pc-horizontal::before { content: map-get($bootstrap-icons-map, "pc-horizontal"); }
.bi-pc::before { content: map-get($bootstrap-icons-map, "pc"); }
.bi-playstation::before { content: map-get($bootstrap-icons-map, "playstation"); }
.bi-plus-slash-minus::before { content: map-get($bootstrap-icons-map, "plus-slash-minus"); }
.bi-projector-fill::before { content: map-get($bootstrap-icons-map, "projector-fill"); }
.bi-projector::before { content: map-get($bootstrap-icons-map, "projector"); }
.bi-qr-code-scan::before { content: map-get($bootstrap-icons-map, "qr-code-scan"); }
.bi-qr-code::before { content: map-get($bootstrap-icons-map, "qr-code"); }
.bi-quora::before { content: map-get($bootstrap-icons-map, "quora"); }
.bi-quote::before { content: map-get($bootstrap-icons-map, "quote"); }
.bi-robot::before { content: map-get($bootstrap-icons-map, "robot"); }
.bi-send-check-fill::before { content: map-get($bootstrap-icons-map, "send-check-fill"); }
.bi-send-check::before { content: map-get($bootstrap-icons-map, "send-check"); }
.bi-send-dash-fill::before { content: map-get($bootstrap-icons-map, "send-dash-fill"); }
.bi-send-dash::before { content: map-get($bootstrap-icons-map, "send-dash"); }
.bi-send-exclamation-1::before { content: map-get($bootstrap-icons-map, "send-exclamation-1"); }
.bi-send-exclamation-fill::before { content: map-get($bootstrap-icons-map, "send-exclamation-fill"); }
.bi-send-exclamation::before { content: map-get($bootstrap-icons-map, "send-exclamation"); }
.bi-send-fill::before { content: map-get($bootstrap-icons-map, "send-fill"); }
.bi-send-plus-fill::before { content: map-get($bootstrap-icons-map, "send-plus-fill"); }
.bi-send-plus::before { content: map-get($bootstrap-icons-map, "send-plus"); }
.bi-send-slash-fill::before { content: map-get($bootstrap-icons-map, "send-slash-fill"); }
.bi-send-slash::before { content: map-get($bootstrap-icons-map, "send-slash"); }
.bi-send-x-fill::before { content: map-get($bootstrap-icons-map, "send-x-fill"); }
.bi-send-x::before { content: map-get($bootstrap-icons-map, "send-x"); }
.bi-send::before { content: map-get($bootstrap-icons-map, "send"); }
.bi-steam::before { content: map-get($bootstrap-icons-map, "steam"); }
.bi-terminal-dash-1::before { content: map-get($bootstrap-icons-map, "terminal-dash-1"); }
.bi-terminal-dash::before { content: map-get($bootstrap-icons-map, "terminal-dash"); }
.bi-terminal-plus::before { content: map-get($bootstrap-icons-map, "terminal-plus"); }
.bi-terminal-split::before { content: map-get($bootstrap-icons-map, "terminal-split"); }
.bi-ticket-detailed-fill::before { content: map-get($bootstrap-icons-map, "ticket-detailed-fill"); }
.bi-ticket-detailed::before { content: map-get($bootstrap-icons-map, "ticket-detailed"); }
.bi-ticket-fill::before { content: map-get($bootstrap-icons-map, "ticket-fill"); }
.bi-ticket-perforated-fill::before { content: map-get($bootstrap-icons-map, "ticket-perforated-fill"); }
.bi-ticket-perforated::before { content: map-get($bootstrap-icons-map, "ticket-perforated"); }
.bi-ticket::before { content: map-get($bootstrap-icons-map, "ticket"); }
.bi-tiktok::before { content: map-get($bootstrap-icons-map, "tiktok"); }
.bi-window-dash::before { content: map-get($bootstrap-icons-map, "window-dash"); }
.bi-window-desktop::before { content: map-get($bootstrap-icons-map, "window-desktop"); }
.bi-window-fullscreen::before { content: map-get($bootstrap-icons-map, "window-fullscreen"); }
.bi-window-plus::before { content: map-get($bootstrap-icons-map, "window-plus"); }
.bi-window-split::before { content: map-get($bootstrap-icons-map, "window-split"); }
.bi-window-stack::before { content: map-get($bootstrap-icons-map, "window-stack"); }
.bi-window-x::before { content: map-get($bootstrap-icons-map, "window-x"); }
.bi-xbox::before { content: map-get($bootstrap-icons-map, "xbox"); }
.bi-ethernet::before { content: map-get($bootstrap-icons-map, "ethernet"); }
.bi-hdmi-fill::before { content: map-get($bootstrap-icons-map, "hdmi-fill"); }
.bi-hdmi::before { content: map-get($bootstrap-icons-map, "hdmi"); }
.bi-usb-c-fill::before { content: map-get($bootstrap-icons-map, "usb-c-fill"); }
.bi-usb-c::before { content: map-get($bootstrap-icons-map, "usb-c"); }
.bi-usb-fill::before { content: map-get($bootstrap-icons-map, "usb-fill"); }
.bi-usb-plug-fill::before { content: map-get($bootstrap-icons-map, "usb-plug-fill"); }
.bi-usb-plug::before { content: map-get($bootstrap-icons-map, "usb-plug"); }
.bi-usb-symbol::before { content: map-get($bootstrap-icons-map, "usb-symbol"); }
.bi-usb::before { content: map-get($bootstrap-icons-map, "usb"); }
.bi-boombox-fill::before { content: map-get($bootstrap-icons-map, "boombox-fill"); }
.bi-displayport-1::before { content: map-get($bootstrap-icons-map, "displayport-1"); }
.bi-displayport::before { content: map-get($bootstrap-icons-map, "displayport"); }
.bi-gpu-card::before { content: map-get($bootstrap-icons-map, "gpu-card"); }
.bi-memory::before { content: map-get($bootstrap-icons-map, "memory"); }
.bi-modem-fill::before { content: map-get($bootstrap-icons-map, "modem-fill"); }
.bi-modem::before { content: map-get($bootstrap-icons-map, "modem"); }
.bi-motherboard-fill::before { content: map-get($bootstrap-icons-map, "motherboard-fill"); }
.bi-motherboard::before { content: map-get($bootstrap-icons-map, "motherboard"); }
.bi-optical-audio-fill::before { content: map-get($bootstrap-icons-map, "optical-audio-fill"); }
.bi-optical-audio::before { content: map-get($bootstrap-icons-map, "optical-audio"); }
.bi-pci-card::before { content: map-get($bootstrap-icons-map, "pci-card"); }
.bi-router-fill::before { content: map-get($bootstrap-icons-map, "router-fill"); }
.bi-router::before { content: map-get($bootstrap-icons-map, "router"); }
.bi-ssd-fill::before { content: map-get($bootstrap-icons-map, "ssd-fill"); }
.bi-ssd::before { content: map-get($bootstrap-icons-map, "ssd"); }
.bi-thunderbolt-fill::before { content: map-get($bootstrap-icons-map, "thunderbolt-fill"); }
.bi-thunderbolt::before { content: map-get($bootstrap-icons-map, "thunderbolt"); }
.bi-usb-drive-fill::before { content: map-get($bootstrap-icons-map, "usb-drive-fill"); }
.bi-usb-drive::before { content: map-get($bootstrap-icons-map, "usb-drive"); }
.bi-usb-micro-fill::before { content: map-get($bootstrap-icons-map, "usb-micro-fill"); }
.bi-usb-micro::before { content: map-get($bootstrap-icons-map, "usb-micro"); }
.bi-usb-mini-fill::before { content: map-get($bootstrap-icons-map, "usb-mini-fill"); }
.bi-usb-mini::before { content: map-get($bootstrap-icons-map, "usb-mini"); }
.bi-cloud-haze2::before { content: map-get($bootstrap-icons-map, "cloud-haze2"); }
.bi-device-hdd-fill::before { content: map-get($bootstrap-icons-map, "device-hdd-fill"); }
.bi-device-hdd::before { content: map-get($bootstrap-icons-map, "device-hdd"); }
.bi-device-ssd-fill::before { content: map-get($bootstrap-icons-map, "device-ssd-fill"); }
.bi-device-ssd::before { content: map-get($bootstrap-icons-map, "device-ssd"); }
.bi-displayport-fill::before { content: map-get($bootstrap-icons-map, "displayport-fill"); }
.bi-mortarboard-fill::before { content: map-get($bootstrap-icons-map, "mortarboard-fill"); }
.bi-mortarboard::before { content: map-get($bootstrap-icons-map, "mortarboard"); }
.bi-terminal-x::before { content: map-get($bootstrap-icons-map, "terminal-x"); }
.bi-arrow-through-heart-fill::before { content: map-get($bootstrap-icons-map, "arrow-through-heart-fill"); }
.bi-arrow-through-heart::before { content: map-get($bootstrap-icons-map, "arrow-through-heart"); }
.bi-badge-sd-fill::before { content: map-get($bootstrap-icons-map, "badge-sd-fill"); }
.bi-badge-sd::before { content: map-get($bootstrap-icons-map, "badge-sd"); }
.bi-bag-heart-fill::before { content: map-get($bootstrap-icons-map, "bag-heart-fill"); }
.bi-bag-heart::before { content: map-get($bootstrap-icons-map, "bag-heart"); }
.bi-balloon-fill::before { content: map-get($bootstrap-icons-map, "balloon-fill"); }
.bi-balloon-heart-fill::before { content: map-get($bootstrap-icons-map, "balloon-heart-fill"); }
.bi-balloon-heart::before { content: map-get($bootstrap-icons-map, "balloon-heart"); }
.bi-balloon::before { content: map-get($bootstrap-icons-map, "balloon"); }
.bi-box2-fill::before { content: map-get($bootstrap-icons-map, "box2-fill"); }
.bi-box2-heart-fill::before { content: map-get($bootstrap-icons-map, "box2-heart-fill"); }
.bi-box2-heart::before { content: map-get($bootstrap-icons-map, "box2-heart"); }
.bi-box2::before { content: map-get($bootstrap-icons-map, "box2"); }
.bi-braces-asterisk::before { content: map-get($bootstrap-icons-map, "braces-asterisk"); }
.bi-calendar-heart-fill::before { content: map-get($bootstrap-icons-map, "calendar-heart-fill"); }
.bi-calendar-heart::before { content: map-get($bootstrap-icons-map, "calendar-heart"); }
.bi-calendar2-heart-fill::before { content: map-get($bootstrap-icons-map, "calendar2-heart-fill"); }
.bi-calendar2-heart::before { content: map-get($bootstrap-icons-map, "calendar2-heart"); }
.bi-chat-heart-fill::before { content: map-get($bootstrap-icons-map, "chat-heart-fill"); }
.bi-chat-heart::before { content: map-get($bootstrap-icons-map, "chat-heart"); }
.bi-chat-left-heart-fill::before { content: map-get($bootstrap-icons-map, "chat-left-heart-fill"); }
.bi-chat-left-heart::before { content: map-get($bootstrap-icons-map, "chat-left-heart"); }
.bi-chat-right-heart-fill::before { content: map-get($bootstrap-icons-map, "chat-right-heart-fill"); }
.bi-chat-right-heart::before { content: map-get($bootstrap-icons-map, "chat-right-heart"); }
.bi-chat-square-heart-fill::before { content: map-get($bootstrap-icons-map, "chat-square-heart-fill"); }
.bi-chat-square-heart::before { content: map-get($bootstrap-icons-map, "chat-square-heart"); }
.bi-clipboard-check-fill::before { content: map-get($bootstrap-icons-map, "clipboard-check-fill"); }
.bi-clipboard-data-fill::before { content: map-get($bootstrap-icons-map, "clipboard-data-fill"); }
.bi-clipboard-fill::before { content: map-get($bootstrap-icons-map, "clipboard-fill"); }
.bi-clipboard-heart-fill::before { content: map-get($bootstrap-icons-map, "clipboard-heart-fill"); }
.bi-clipboard-heart::before { content: map-get($bootstrap-icons-map, "clipboard-heart"); }
.bi-clipboard-minus-fill::before { content: map-get($bootstrap-icons-map, "clipboard-minus-fill"); }
.bi-clipboard-plus-fill::before { content: map-get($bootstrap-icons-map, "clipboard-plus-fill"); }
.bi-clipboard-pulse::before { content: map-get($bootstrap-icons-map, "clipboard-pulse"); }
.bi-clipboard-x-fill::before { content: map-get($bootstrap-icons-map, "clipboard-x-fill"); }
.bi-clipboard2-check-fill::before { content: map-get($bootstrap-icons-map, "clipboard2-check-fill"); }
.bi-clipboard2-check::before { content: map-get($bootstrap-icons-map, "clipboard2-check"); }
.bi-clipboard2-data-fill::before { content: map-get($bootstrap-icons-map, "clipboard2-data-fill"); }
.bi-clipboard2-data::before { content: map-get($bootstrap-icons-map, "clipboard2-data"); }
.bi-clipboard2-fill::before { content: map-get($bootstrap-icons-map, "clipboard2-fill"); }
.bi-clipboard2-heart-fill::before { content: map-get($bootstrap-icons-map, "clipboard2-heart-fill"); }
.bi-clipboard2-heart::before { content: map-get($bootstrap-icons-map, "clipboard2-heart"); }
.bi-clipboard2-minus-fill::before { content: map-get($bootstrap-icons-map, "clipboard2-minus-fill"); }
.bi-clipboard2-minus::before { content: map-get($bootstrap-icons-map, "clipboard2-minus"); }
.bi-clipboard2-plus-fill::before { content: map-get($bootstrap-icons-map, "clipboard2-plus-fill"); }
.bi-clipboard2-plus::before { content: map-get($bootstrap-icons-map, "clipboard2-plus"); }
.bi-clipboard2-pulse-fill::before { content: map-get($bootstrap-icons-map, "clipboard2-pulse-fill"); }
.bi-clipboard2-pulse::before { content: map-get($bootstrap-icons-map, "clipboard2-pulse"); }
.bi-clipboard2-x-fill::before { content: map-get($bootstrap-icons-map, "clipboard2-x-fill"); }
.bi-clipboard2-x::before { content: map-get($bootstrap-icons-map, "clipboard2-x"); }
.bi-clipboard2::before { content: map-get($bootstrap-icons-map, "clipboard2"); }
.bi-emoji-kiss-fill::before { content: map-get($bootstrap-icons-map, "emoji-kiss-fill"); }
.bi-emoji-kiss::before { content: map-get($bootstrap-icons-map, "emoji-kiss"); }
.bi-envelope-heart-fill::before { content: map-get($bootstrap-icons-map, "envelope-heart-fill"); }
.bi-envelope-heart::before { content: map-get($bootstrap-icons-map, "envelope-heart"); }
.bi-envelope-open-heart-fill::before { content: map-get($bootstrap-icons-map, "envelope-open-heart-fill"); }
.bi-envelope-open-heart::before { content: map-get($bootstrap-icons-map, "envelope-open-heart"); }
.bi-envelope-paper-fill::before { content: map-get($bootstrap-icons-map, "envelope-paper-fill"); }
.bi-envelope-paper-heart-fill::before { content: map-get($bootstrap-icons-map, "envelope-paper-heart-fill"); }
.bi-envelope-paper-heart::before { content: map-get($bootstrap-icons-map, "envelope-paper-heart"); }
.bi-envelope-paper::before { content: map-get($bootstrap-icons-map, "envelope-paper"); }
.bi-filetype-aac::before { content: map-get($bootstrap-icons-map, "filetype-aac"); }
.bi-filetype-ai::before { content: map-get($bootstrap-icons-map, "filetype-ai"); }
.bi-filetype-bmp::before { content: map-get($bootstrap-icons-map, "filetype-bmp"); }
.bi-filetype-cs::before { content: map-get($bootstrap-icons-map, "filetype-cs"); }
.bi-filetype-css::before { content: map-get($bootstrap-icons-map, "filetype-css"); }
.bi-filetype-csv::before { content: map-get($bootstrap-icons-map, "filetype-csv"); }
.bi-filetype-doc::before { content: map-get($bootstrap-icons-map, "filetype-doc"); }
.bi-filetype-docx::before { content: map-get($bootstrap-icons-map, "filetype-docx"); }
.bi-filetype-exe::before { content: map-get($bootstrap-icons-map, "filetype-exe"); }
.bi-filetype-gif::before { content: map-get($bootstrap-icons-map, "filetype-gif"); }
.bi-filetype-heic::before { content: map-get($bootstrap-icons-map, "filetype-heic"); }
.bi-filetype-html::before { content: map-get($bootstrap-icons-map, "filetype-html"); }
.bi-filetype-java::before { content: map-get($bootstrap-icons-map, "filetype-java"); }
.bi-filetype-jpg::before { content: map-get($bootstrap-icons-map, "filetype-jpg"); }
.bi-filetype-js::before { content: map-get($bootstrap-icons-map, "filetype-js"); }
.bi-filetype-jsx::before { content: map-get($bootstrap-icons-map, "filetype-jsx"); }
.bi-filetype-key::before { content: map-get($bootstrap-icons-map, "filetype-key"); }
.bi-filetype-m4p::before { content: map-get($bootstrap-icons-map, "filetype-m4p"); }
.bi-filetype-md::before { content: map-get($bootstrap-icons-map, "filetype-md"); }
.bi-filetype-mdx::before { content: map-get($bootstrap-icons-map, "filetype-mdx"); }
.bi-filetype-mov::before { content: map-get($bootstrap-icons-map, "filetype-mov"); }
.bi-filetype-mp3::before { content: map-get($bootstrap-icons-map, "filetype-mp3"); }
.bi-filetype-mp4::before { content: map-get($bootstrap-icons-map, "filetype-mp4"); }
.bi-filetype-otf::before { content: map-get($bootstrap-icons-map, "filetype-otf"); }
.bi-filetype-pdf::before { content: map-get($bootstrap-icons-map, "filetype-pdf"); }
.bi-filetype-php::before { content: map-get($bootstrap-icons-map, "filetype-php"); }
.bi-filetype-png::before { content: map-get($bootstrap-icons-map, "filetype-png"); }
.bi-filetype-ppt-1::before { content: map-get($bootstrap-icons-map, "filetype-ppt-1"); }
.bi-filetype-ppt::before { content: map-get($bootstrap-icons-map, "filetype-ppt"); }
.bi-filetype-psd::before { content: map-get($bootstrap-icons-map, "filetype-psd"); }
.bi-filetype-py::before { content: map-get($bootstrap-icons-map, "filetype-py"); }
.bi-filetype-raw::before { content: map-get($bootstrap-icons-map, "filetype-raw"); }
.bi-filetype-rb::before { content: map-get($bootstrap-icons-map, "filetype-rb"); }
.bi-filetype-sass::before { content: map-get($bootstrap-icons-map, "filetype-sass"); }
.bi-filetype-scss::before { content: map-get($bootstrap-icons-map, "filetype-scss"); }
.bi-filetype-sh::before { content: map-get($bootstrap-icons-map, "filetype-sh"); }
.bi-filetype-svg::before { content: map-get($bootstrap-icons-map, "filetype-svg"); }
.bi-filetype-tiff::before { content: map-get($bootstrap-icons-map, "filetype-tiff"); }
.bi-filetype-tsx::before { content: map-get($bootstrap-icons-map, "filetype-tsx"); }
.bi-filetype-ttf::before { content: map-get($bootstrap-icons-map, "filetype-ttf"); }
.bi-filetype-txt::before { content: map-get($bootstrap-icons-map, "filetype-txt"); }
.bi-filetype-wav::before { content: map-get($bootstrap-icons-map, "filetype-wav"); }
.bi-filetype-woff::before { content: map-get($bootstrap-icons-map, "filetype-woff"); }
.bi-filetype-xls-1::before { content: map-get($bootstrap-icons-map, "filetype-xls-1"); }
.bi-filetype-xls::before { content: map-get($bootstrap-icons-map, "filetype-xls"); }
.bi-filetype-xml::before { content: map-get($bootstrap-icons-map, "filetype-xml"); }
.bi-filetype-yml::before { content: map-get($bootstrap-icons-map, "filetype-yml"); }
.bi-heart-arrow::before { content: map-get($bootstrap-icons-map, "heart-arrow"); }
.bi-heart-pulse-fill::before { content: map-get($bootstrap-icons-map, "heart-pulse-fill"); }
.bi-heart-pulse::before { content: map-get($bootstrap-icons-map, "heart-pulse"); }
.bi-heartbreak-fill::before { content: map-get($bootstrap-icons-map, "heartbreak-fill"); }
.bi-heartbreak::before { content: map-get($bootstrap-icons-map, "heartbreak"); }
.bi-hearts::before { content: map-get($bootstrap-icons-map, "hearts"); }
.bi-hospital-fill::before { content: map-get($bootstrap-icons-map, "hospital-fill"); }
.bi-hospital::before { content: map-get($bootstrap-icons-map, "hospital"); }
.bi-house-heart-fill::before { content: map-get($bootstrap-icons-map, "house-heart-fill"); }
.bi-house-heart::before { content: map-get($bootstrap-icons-map, "house-heart"); }
.bi-incognito::before { content: map-get($bootstrap-icons-map, "incognito"); }
.bi-magnet-fill::before { content: map-get($bootstrap-icons-map, "magnet-fill"); }
.bi-magnet::before { content: map-get($bootstrap-icons-map, "magnet"); }
.bi-person-heart::before { content: map-get($bootstrap-icons-map, "person-heart"); }
.bi-person-hearts::before { content: map-get($bootstrap-icons-map, "person-hearts"); }
.bi-phone-flip::before { content: map-get($bootstrap-icons-map, "phone-flip"); }
.bi-plugin::before { content: map-get($bootstrap-icons-map, "plugin"); }
.bi-postage-fill::before { content: map-get($bootstrap-icons-map, "postage-fill"); }
.bi-postage-heart-fill::before { content: map-get($bootstrap-icons-map, "postage-heart-fill"); }
.bi-postage-heart::before { content: map-get($bootstrap-icons-map, "postage-heart"); }
.bi-postage::before { content: map-get($bootstrap-icons-map, "postage"); }
.bi-postcard-fill::before { content: map-get($bootstrap-icons-map, "postcard-fill"); }
.bi-postcard-heart-fill::before { content: map-get($bootstrap-icons-map, "postcard-heart-fill"); }
.bi-postcard-heart::before { content: map-get($bootstrap-icons-map, "postcard-heart"); }
.bi-postcard::before { content: map-get($bootstrap-icons-map, "postcard"); }
.bi-search-heart-fill::before { content: map-get($bootstrap-icons-map, "search-heart-fill"); }
.bi-search-heart::before { content: map-get($bootstrap-icons-map, "search-heart"); }
.bi-sliders2-vertical::before { content: map-get($bootstrap-icons-map, "sliders2-vertical"); }
.bi-sliders2::before { content: map-get($bootstrap-icons-map, "sliders2"); }
.bi-trash3-fill::before { content: map-get($bootstrap-icons-map, "trash3-fill"); }
.bi-trash3::before { content: map-get($bootstrap-icons-map, "trash3"); }
.bi-valentine::before { content: map-get($bootstrap-icons-map, "valentine"); }
.bi-valentine2::before { content: map-get($bootstrap-icons-map, "valentine2"); }
.bi-wrench-adjustable-circle-fill::before { content: map-get($bootstrap-icons-map, "wrench-adjustable-circle-fill"); }
.bi-wrench-adjustable-circle::before { content: map-get($bootstrap-icons-map, "wrench-adjustable-circle"); }
.bi-wrench-adjustable::before { content: map-get($bootstrap-icons-map, "wrench-adjustable"); }
.bi-filetype-json::before { content: map-get($bootstrap-icons-map, "filetype-json"); }
.bi-filetype-pptx::before { content: map-get($bootstrap-icons-map, "filetype-pptx"); }
.bi-filetype-xlsx::before { content: map-get($bootstrap-icons-map, "filetype-xlsx"); }
.bi-1-circle-1::before { content: map-get($bootstrap-icons-map, "1-circle-1"); }
.bi-1-circle-fill-1::before { content: map-get($bootstrap-icons-map, "1-circle-fill-1"); }
.bi-1-circle-fill::before { content: map-get($bootstrap-icons-map, "1-circle-fill"); }
.bi-1-circle::before { content: map-get($bootstrap-icons-map, "1-circle"); }
.bi-1-square-fill::before { content: map-get($bootstrap-icons-map, "1-square-fill"); }
.bi-1-square::before { content: map-get($bootstrap-icons-map, "1-square"); }
.bi-2-circle-1::before { content: map-get($bootstrap-icons-map, "2-circle-1"); }
.bi-2-circle-fill-1::before { content: map-get($bootstrap-icons-map, "2-circle-fill-1"); }
.bi-2-circle-fill::before { content: map-get($bootstrap-icons-map, "2-circle-fill"); }
.bi-2-circle::before { content: map-get($bootstrap-icons-map, "2-circle"); }
.bi-2-square-fill::before { content: map-get($bootstrap-icons-map, "2-square-fill"); }
.bi-2-square::before { content: map-get($bootstrap-icons-map, "2-square"); }
.bi-3-circle-1::before { content: map-get($bootstrap-icons-map, "3-circle-1"); }
.bi-3-circle-fill-1::before { content: map-get($bootstrap-icons-map, "3-circle-fill-1"); }
.bi-3-circle-fill::before { content: map-get($bootstrap-icons-map, "3-circle-fill"); }
.bi-3-circle::before { content: map-get($bootstrap-icons-map, "3-circle"); }
.bi-3-square-fill::before { content: map-get($bootstrap-icons-map, "3-square-fill"); }
.bi-3-square::before { content: map-get($bootstrap-icons-map, "3-square"); }
.bi-4-circle-1::before { content: map-get($bootstrap-icons-map, "4-circle-1"); }
.bi-4-circle-fill-1::before { content: map-get($bootstrap-icons-map, "4-circle-fill-1"); }
.bi-4-circle-fill::before { content: map-get($bootstrap-icons-map, "4-circle-fill"); }
.bi-4-circle::before { content: map-get($bootstrap-icons-map, "4-circle"); }
.bi-4-square-fill::before { content: map-get($bootstrap-icons-map, "4-square-fill"); }
.bi-4-square::before { content: map-get($bootstrap-icons-map, "4-square"); }
.bi-5-circle-1::before { content: map-get($bootstrap-icons-map, "5-circle-1"); }
.bi-5-circle-fill-1::before { content: map-get($bootstrap-icons-map, "5-circle-fill-1"); }
.bi-5-circle-fill::before { content: map-get($bootstrap-icons-map, "5-circle-fill"); }
.bi-5-circle::before { content: map-get($bootstrap-icons-map, "5-circle"); }
.bi-5-square-fill::before { content: map-get($bootstrap-icons-map, "5-square-fill"); }
.bi-5-square::before { content: map-get($bootstrap-icons-map, "5-square"); }
.bi-6-circle-1::before { content: map-get($bootstrap-icons-map, "6-circle-1"); }
.bi-6-circle-fill-1::before { content: map-get($bootstrap-icons-map, "6-circle-fill-1"); }
.bi-6-circle-fill::before { content: map-get($bootstrap-icons-map, "6-circle-fill"); }
.bi-6-circle::before { content: map-get($bootstrap-icons-map, "6-circle"); }
.bi-6-square-fill::before { content: map-get($bootstrap-icons-map, "6-square-fill"); }
.bi-6-square::before { content: map-get($bootstrap-icons-map, "6-square"); }
.bi-7-circle-1::before { content: map-get($bootstrap-icons-map, "7-circle-1"); }
.bi-7-circle-fill-1::before { content: map-get($bootstrap-icons-map, "7-circle-fill-1"); }
.bi-7-circle-fill::before { content: map-get($bootstrap-icons-map, "7-circle-fill"); }
.bi-7-circle::before { content: map-get($bootstrap-icons-map, "7-circle"); }
.bi-7-square-fill::before { content: map-get($bootstrap-icons-map, "7-square-fill"); }
.bi-7-square::before { content: map-get($bootstrap-icons-map, "7-square"); }
.bi-8-circle-1::before { content: map-get($bootstrap-icons-map, "8-circle-1"); }
.bi-8-circle-fill-1::before { content: map-get($bootstrap-icons-map, "8-circle-fill-1"); }
.bi-8-circle-fill::before { content: map-get($bootstrap-icons-map, "8-circle-fill"); }
.bi-8-circle::before { content: map-get($bootstrap-icons-map, "8-circle"); }
.bi-8-square-fill::before { content: map-get($bootstrap-icons-map, "8-square-fill"); }
.bi-8-square::before { content: map-get($bootstrap-icons-map, "8-square"); }
.bi-9-circle-1::before { content: map-get($bootstrap-icons-map, "9-circle-1"); }
.bi-9-circle-fill-1::before { content: map-get($bootstrap-icons-map, "9-circle-fill-1"); }
.bi-9-circle-fill::before { content: map-get($bootstrap-icons-map, "9-circle-fill"); }
.bi-9-circle::before { content: map-get($bootstrap-icons-map, "9-circle"); }
.bi-9-square-fill::before { content: map-get($bootstrap-icons-map, "9-square-fill"); }
.bi-9-square::before { content: map-get($bootstrap-icons-map, "9-square"); }
.bi-airplane-engines-fill::before { content: map-get($bootstrap-icons-map, "airplane-engines-fill"); }
.bi-airplane-engines::before { content: map-get($bootstrap-icons-map, "airplane-engines"); }
.bi-airplane-fill::before { content: map-get($bootstrap-icons-map, "airplane-fill"); }
.bi-airplane::before { content: map-get($bootstrap-icons-map, "airplane"); }
.bi-alexa::before { content: map-get($bootstrap-icons-map, "alexa"); }
.bi-alipay::before { content: map-get($bootstrap-icons-map, "alipay"); }
.bi-android::before { content: map-get($bootstrap-icons-map, "android"); }
.bi-android2::before { content: map-get($bootstrap-icons-map, "android2"); }
.bi-box-fill::before { content: map-get($bootstrap-icons-map, "box-fill"); }
.bi-box-seam-fill::before { content: map-get($bootstrap-icons-map, "box-seam-fill"); }
.bi-browser-chrome::before { content: map-get($bootstrap-icons-map, "browser-chrome"); }
.bi-browser-edge::before { content: map-get($bootstrap-icons-map, "browser-edge"); }
.bi-browser-firefox::before { content: map-get($bootstrap-icons-map, "browser-firefox"); }
.bi-browser-safari::before { content: map-get($bootstrap-icons-map, "browser-safari"); }
.bi-c-circle-1::before { content: map-get($bootstrap-icons-map, "c-circle-1"); }
.bi-c-circle-fill-1::before { content: map-get($bootstrap-icons-map, "c-circle-fill-1"); }
.bi-c-circle-fill::before { content: map-get($bootstrap-icons-map, "c-circle-fill"); }
.bi-c-circle::before { content: map-get($bootstrap-icons-map, "c-circle"); }
.bi-c-square-fill::before { content: map-get($bootstrap-icons-map, "c-square-fill"); }
.bi-c-square::before { content: map-get($bootstrap-icons-map, "c-square"); }
.bi-capsule-pill::before { content: map-get($bootstrap-icons-map, "capsule-pill"); }
.bi-capsule::before { content: map-get($bootstrap-icons-map, "capsule"); }
.bi-car-front-fill::before { content: map-get($bootstrap-icons-map, "car-front-fill"); }
.bi-car-front::before { content: map-get($bootstrap-icons-map, "car-front"); }
.bi-cassette-fill::before { content: map-get($bootstrap-icons-map, "cassette-fill"); }
.bi-cassette::before { content: map-get($bootstrap-icons-map, "cassette"); }
.bi-cc-circle-1::before { content: map-get($bootstrap-icons-map, "cc-circle-1"); }
.bi-cc-circle-fill-1::before { content: map-get($bootstrap-icons-map, "cc-circle-fill-1"); }
.bi-cc-circle-fill::before { content: map-get($bootstrap-icons-map, "cc-circle-fill"); }
.bi-cc-circle::before { content: map-get($bootstrap-icons-map, "cc-circle"); }
.bi-cc-square-fill::before { content: map-get($bootstrap-icons-map, "cc-square-fill"); }
.bi-cc-square::before { content: map-get($bootstrap-icons-map, "cc-square"); }
.bi-cup-hot-fill::before { content: map-get($bootstrap-icons-map, "cup-hot-fill"); }
.bi-cup-hot::before { content: map-get($bootstrap-icons-map, "cup-hot"); }
.bi-currency-rupee::before { content: map-get($bootstrap-icons-map, "currency-rupee"); }
.bi-dropbox::before { content: map-get($bootstrap-icons-map, "dropbox"); }
.bi-escape::before { content: map-get($bootstrap-icons-map, "escape"); }
.bi-fast-forward-btn-fill::before { content: map-get($bootstrap-icons-map, "fast-forward-btn-fill"); }
.bi-fast-forward-btn::before { content: map-get($bootstrap-icons-map, "fast-forward-btn"); }
.bi-fast-forward-circle-fill::before { content: map-get($bootstrap-icons-map, "fast-forward-circle-fill"); }
.bi-fast-forward-circle::before { content: map-get($bootstrap-icons-map, "fast-forward-circle"); }
.bi-fast-forward-fill::before { content: map-get($bootstrap-icons-map, "fast-forward-fill"); }
.bi-fast-forward::before { content: map-get($bootstrap-icons-map, "fast-forward"); }
.bi-filetype-sql::before { content: map-get($bootstrap-icons-map, "filetype-sql"); }
.bi-fire::before { content: map-get($bootstrap-icons-map, "fire"); }
.bi-google-play::before { content: map-get($bootstrap-icons-map, "google-play"); }
.bi-h-circle-1::before { content: map-get($bootstrap-icons-map, "h-circle-1"); }
.bi-h-circle-fill-1::before { content: map-get($bootstrap-icons-map, "h-circle-fill-1"); }
.bi-h-circle-fill::before { content: map-get($bootstrap-icons-map, "h-circle-fill"); }
.bi-h-circle::before { content: map-get($bootstrap-icons-map, "h-circle"); }
.bi-h-square-fill::before { content: map-get($bootstrap-icons-map, "h-square-fill"); }
.bi-h-square::before { content: map-get($bootstrap-icons-map, "h-square"); }
.bi-indent::before { content: map-get($bootstrap-icons-map, "indent"); }
.bi-lungs-fill::before { content: map-get($bootstrap-icons-map, "lungs-fill"); }
.bi-lungs::before { content: map-get($bootstrap-icons-map, "lungs"); }
.bi-microsoft-teams::before { content: map-get($bootstrap-icons-map, "microsoft-teams"); }
.bi-p-circle-1::before { content: map-get($bootstrap-icons-map, "p-circle-1"); }
.bi-p-circle-fill-1::before { content: map-get($bootstrap-icons-map, "p-circle-fill-1"); }
.bi-p-circle-fill::before { content: map-get($bootstrap-icons-map, "p-circle-fill"); }
.bi-p-circle::before { content: map-get($bootstrap-icons-map, "p-circle"); }
.bi-p-square-fill::before { content: map-get($bootstrap-icons-map, "p-square-fill"); }
.bi-p-square::before { content: map-get($bootstrap-icons-map, "p-square"); }
.bi-pass-fill::before { content: map-get($bootstrap-icons-map, "pass-fill"); }
.bi-pass::before { content: map-get($bootstrap-icons-map, "pass"); }
.bi-prescription::before { content: map-get($bootstrap-icons-map, "prescription"); }
.bi-prescription2::before { content: map-get($bootstrap-icons-map, "prescription2"); }
.bi-r-circle-1::before { content: map-get($bootstrap-icons-map, "r-circle-1"); }
.bi-r-circle-fill-1::before { content: map-get($bootstrap-icons-map, "r-circle-fill-1"); }
.bi-r-circle-fill::before { content: map-get($bootstrap-icons-map, "r-circle-fill"); }
.bi-r-circle::before { content: map-get($bootstrap-icons-map, "r-circle"); }
.bi-r-square-fill::before { content: map-get($bootstrap-icons-map, "r-square-fill"); }
.bi-r-square::before { content: map-get($bootstrap-icons-map, "r-square"); }
.bi-repeat-1::before { content: map-get($bootstrap-icons-map, "repeat-1"); }
.bi-repeat::before { content: map-get($bootstrap-icons-map, "repeat"); }
.bi-rewind-btn-fill::before { content: map-get($bootstrap-icons-map, "rewind-btn-fill"); }
.bi-rewind-btn::before { content: map-get($bootstrap-icons-map, "rewind-btn"); }
.bi-rewind-circle-fill::before { content: map-get($bootstrap-icons-map, "rewind-circle-fill"); }
.bi-rewind-circle::before { content: map-get($bootstrap-icons-map, "rewind-circle"); }
.bi-rewind-fill::before { content: map-get($bootstrap-icons-map, "rewind-fill"); }
.bi-rewind::before { content: map-get($bootstrap-icons-map, "rewind"); }
.bi-train-freight-front-fill::before { content: map-get($bootstrap-icons-map, "train-freight-front-fill"); }
.bi-train-freight-front::before { content: map-get($bootstrap-icons-map, "train-freight-front"); }
.bi-train-front-fill::before { content: map-get($bootstrap-icons-map, "train-front-fill"); }
.bi-train-front::before { content: map-get($bootstrap-icons-map, "train-front"); }
.bi-train-lightrail-front-fill::before { content: map-get($bootstrap-icons-map, "train-lightrail-front-fill"); }
.bi-train-lightrail-front::before { content: map-get($bootstrap-icons-map, "train-lightrail-front"); }
.bi-truck-front-fill::before { content: map-get($bootstrap-icons-map, "truck-front-fill"); }
.bi-truck-front::before { content: map-get($bootstrap-icons-map, "truck-front"); }
.bi-ubuntu::before { content: map-get($bootstrap-icons-map, "ubuntu"); }
.bi-unindent::before { content: map-get($bootstrap-icons-map, "unindent"); }
.bi-unity::before { content: map-get($bootstrap-icons-map, "unity"); }
.bi-universal-access-circle::before { content: map-get($bootstrap-icons-map, "universal-access-circle"); }
.bi-universal-access::before { content: map-get($bootstrap-icons-map, "universal-access"); }
.bi-virus::before { content: map-get($bootstrap-icons-map, "virus"); }
.bi-virus2::before { content: map-get($bootstrap-icons-map, "virus2"); }
.bi-wechat::before { content: map-get($bootstrap-icons-map, "wechat"); }
.bi-yelp::before { content: map-get($bootstrap-icons-map, "yelp"); }
.bi-sign-stop-fill::before { content: map-get($bootstrap-icons-map, "sign-stop-fill"); }
.bi-sign-stop-lights-fill::before { content: map-get($bootstrap-icons-map, "sign-stop-lights-fill"); }
.bi-sign-stop-lights::before { content: map-get($bootstrap-icons-map, "sign-stop-lights"); }
.bi-sign-stop::before { content: map-get($bootstrap-icons-map, "sign-stop"); }
.bi-sign-turn-left-fill::before { content: map-get($bootstrap-icons-map, "sign-turn-left-fill"); }
.bi-sign-turn-left::before { content: map-get($bootstrap-icons-map, "sign-turn-left"); }
.bi-sign-turn-right-fill::before { content: map-get($bootstrap-icons-map, "sign-turn-right-fill"); }
.bi-sign-turn-right::before { content: map-get($bootstrap-icons-map, "sign-turn-right"); }
.bi-sign-turn-slight-left-fill::before { content: map-get($bootstrap-icons-map, "sign-turn-slight-left-fill"); }
.bi-sign-turn-slight-left::before { content: map-get($bootstrap-icons-map, "sign-turn-slight-left"); }
.bi-sign-turn-slight-right-fill::before { content: map-get($bootstrap-icons-map, "sign-turn-slight-right-fill"); }
.bi-sign-turn-slight-right::before { content: map-get($bootstrap-icons-map, "sign-turn-slight-right"); }
.bi-sign-yield-fill::before { content: map-get($bootstrap-icons-map, "sign-yield-fill"); }
.bi-sign-yield::before { content: map-get($bootstrap-icons-map, "sign-yield"); }
.bi-ev-station-fill::before { content: map-get($bootstrap-icons-map, "ev-station-fill"); }
.bi-ev-station::before { content: map-get($bootstrap-icons-map, "ev-station"); }
.bi-fuel-pump-diesel-fill::before { content: map-get($bootstrap-icons-map, "fuel-pump-diesel-fill"); }
.bi-fuel-pump-diesel::before { content: map-get($bootstrap-icons-map, "fuel-pump-diesel"); }
.bi-fuel-pump-fill::before { content: map-get($bootstrap-icons-map, "fuel-pump-fill"); }
.bi-fuel-pump::before { content: map-get($bootstrap-icons-map, "fuel-pump"); }
.bi-0-circle-fill::before { content: map-get($bootstrap-icons-map, "0-circle-fill"); }
.bi-0-circle::before { content: map-get($bootstrap-icons-map, "0-circle"); }
.bi-0-square-fill::before { content: map-get($bootstrap-icons-map, "0-square-fill"); }
.bi-0-square::before { content: map-get($bootstrap-icons-map, "0-square"); }
.bi-rocket-fill::before { content: map-get($bootstrap-icons-map, "rocket-fill"); }
.bi-rocket-takeoff-fill::before { content: map-get($bootstrap-icons-map, "rocket-takeoff-fill"); }
.bi-rocket-takeoff::before { content: map-get($bootstrap-icons-map, "rocket-takeoff"); }
.bi-rocket::before { content: map-get($bootstrap-icons-map, "rocket"); }
.bi-stripe::before { content: map-get($bootstrap-icons-map, "stripe"); }
.bi-subscript::before { content: map-get($bootstrap-icons-map, "subscript"); }
.bi-superscript::before { content: map-get($bootstrap-icons-map, "superscript"); }
.bi-trello::before { content: map-get($bootstrap-icons-map, "trello"); }
.bi-envelope-at-fill::before { content: map-get($bootstrap-icons-map, "envelope-at-fill"); }
.bi-envelope-at::before { content: map-get($bootstrap-icons-map, "envelope-at"); }
.bi-regex::before { content: map-get($bootstrap-icons-map, "regex"); }
.bi-text-wrap::before { content: map-get($bootstrap-icons-map, "text-wrap"); }
.bi-sign-dead-end-fill::before { content: map-get($bootstrap-icons-map, "sign-dead-end-fill"); }
.bi-sign-dead-end::before { content: map-get($bootstrap-icons-map, "sign-dead-end"); }
.bi-sign-do-not-enter-fill::before { content: map-get($bootstrap-icons-map, "sign-do-not-enter-fill"); }
.bi-sign-do-not-enter::before { content: map-get($bootstrap-icons-map, "sign-do-not-enter"); }
.bi-sign-intersection-fill::before { content: map-get($bootstrap-icons-map, "sign-intersection-fill"); }
.bi-sign-intersection-side-fill::before { content: map-get($bootstrap-icons-map, "sign-intersection-side-fill"); }
.bi-sign-intersection-side::before { content: map-get($bootstrap-icons-map, "sign-intersection-side"); }
.bi-sign-intersection-t-fill::before { content: map-get($bootstrap-icons-map, "sign-intersection-t-fill"); }
.bi-sign-intersection-t::before { content: map-get($bootstrap-icons-map, "sign-intersection-t"); }
.bi-sign-intersection-y-fill::before { content: map-get($bootstrap-icons-map, "sign-intersection-y-fill"); }
.bi-sign-intersection-y::before { content: map-get($bootstrap-icons-map, "sign-intersection-y"); }
.bi-sign-intersection::before { content: map-get($bootstrap-icons-map, "sign-intersection"); }
.bi-sign-merge-left-fill::before { content: map-get($bootstrap-icons-map, "sign-merge-left-fill"); }
.bi-sign-merge-left::before { content: map-get($bootstrap-icons-map, "sign-merge-left"); }
.bi-sign-merge-right-fill::before { content: map-get($bootstrap-icons-map, "sign-merge-right-fill"); }
.bi-sign-merge-right::before { content: map-get($bootstrap-icons-map, "sign-merge-right"); }
.bi-sign-no-left-turn-fill::before { content: map-get($bootstrap-icons-map, "sign-no-left-turn-fill"); }
.bi-sign-no-left-turn::before { content: map-get($bootstrap-icons-map, "sign-no-left-turn"); }
.bi-sign-no-parking-fill::before { content: map-get($bootstrap-icons-map, "sign-no-parking-fill"); }
.bi-sign-no-parking::before { content: map-get($bootstrap-icons-map, "sign-no-parking"); }
.bi-sign-no-right-turn-fill::before { content: map-get($bootstrap-icons-map, "sign-no-right-turn-fill"); }
.bi-sign-no-right-turn::before { content: map-get($bootstrap-icons-map, "sign-no-right-turn"); }
.bi-sign-railroad-fill::before { content: map-get($bootstrap-icons-map, "sign-railroad-fill"); }
.bi-sign-railroad::before { content: map-get($bootstrap-icons-map, "sign-railroad"); }
.bi-building-add::before { content: map-get($bootstrap-icons-map, "building-add"); }
.bi-building-check::before { content: map-get($bootstrap-icons-map, "building-check"); }
.bi-building-dash::before { content: map-get($bootstrap-icons-map, "building-dash"); }
.bi-building-down::before { content: map-get($bootstrap-icons-map, "building-down"); }
.bi-building-exclamation::before { content: map-get($bootstrap-icons-map, "building-exclamation"); }
.bi-building-fill-add::before { content: map-get($bootstrap-icons-map, "building-fill-add"); }
.bi-building-fill-check::before { content: map-get($bootstrap-icons-map, "building-fill-check"); }
.bi-building-fill-dash::before { content: map-get($bootstrap-icons-map, "building-fill-dash"); }
.bi-building-fill-down::before { content: map-get($bootstrap-icons-map, "building-fill-down"); }
.bi-building-fill-exclamation::before { content: map-get($bootstrap-icons-map, "building-fill-exclamation"); }
.bi-building-fill-gear::before { content: map-get($bootstrap-icons-map, "building-fill-gear"); }
.bi-building-fill-lock::before { content: map-get($bootstrap-icons-map, "building-fill-lock"); }
.bi-building-fill-slash::before { content: map-get($bootstrap-icons-map, "building-fill-slash"); }
.bi-building-fill-up::before { content: map-get($bootstrap-icons-map, "building-fill-up"); }
.bi-building-fill-x::before { content: map-get($bootstrap-icons-map, "building-fill-x"); }
.bi-building-fill::before { content: map-get($bootstrap-icons-map, "building-fill"); }
.bi-building-gear::before { content: map-get($bootstrap-icons-map, "building-gear"); }
.bi-building-lock::before { content: map-get($bootstrap-icons-map, "building-lock"); }
.bi-building-slash::before { content: map-get($bootstrap-icons-map, "building-slash"); }
.bi-building-up::before { content: map-get($bootstrap-icons-map, "building-up"); }
.bi-building-x::before { content: map-get($bootstrap-icons-map, "building-x"); }
.bi-buildings-fill::before { content: map-get($bootstrap-icons-map, "buildings-fill"); }
.bi-buildings::before { content: map-get($bootstrap-icons-map, "buildings"); }
.bi-bus-front-fill::before { content: map-get($bootstrap-icons-map, "bus-front-fill"); }
.bi-bus-front::before { content: map-get($bootstrap-icons-map, "bus-front"); }
.bi-ev-front-fill::before { content: map-get($bootstrap-icons-map, "ev-front-fill"); }
.bi-ev-front::before { content: map-get($bootstrap-icons-map, "ev-front"); }
.bi-globe-americas::before { content: map-get($bootstrap-icons-map, "globe-americas"); }
.bi-globe-asia-australia::before { content: map-get($bootstrap-icons-map, "globe-asia-australia"); }
.bi-globe-central-south-asia::before { content: map-get($bootstrap-icons-map, "globe-central-south-asia"); }
.bi-globe-europe-africa::before { content: map-get($bootstrap-icons-map, "globe-europe-africa"); }
.bi-house-add-fill::before { content: map-get($bootstrap-icons-map, "house-add-fill"); }
.bi-house-add::before { content: map-get($bootstrap-icons-map, "house-add"); }
.bi-house-check-fill::before { content: map-get($bootstrap-icons-map, "house-check-fill"); }
.bi-house-check::before { content: map-get($bootstrap-icons-map, "house-check"); }
.bi-house-dash-fill::before { content: map-get($bootstrap-icons-map, "house-dash-fill"); }
.bi-house-dash::before { content: map-get($bootstrap-icons-map, "house-dash"); }
.bi-house-down-fill::before { content: map-get($bootstrap-icons-map, "house-down-fill"); }
.bi-house-down::before { content: map-get($bootstrap-icons-map, "house-down"); }
.bi-house-exclamation-fill::before { content: map-get($bootstrap-icons-map, "house-exclamation-fill"); }
.bi-house-exclamation::before { content: map-get($bootstrap-icons-map, "house-exclamation"); }
.bi-house-gear-fill::before { content: map-get($bootstrap-icons-map, "house-gear-fill"); }
.bi-house-gear::before { content: map-get($bootstrap-icons-map, "house-gear"); }
.bi-house-lock-fill::before { content: map-get($bootstrap-icons-map, "house-lock-fill"); }
.bi-house-lock::before { content: map-get($bootstrap-icons-map, "house-lock"); }
.bi-house-slash-fill::before { content: map-get($bootstrap-icons-map, "house-slash-fill"); }
.bi-house-slash::before { content: map-get($bootstrap-icons-map, "house-slash"); }
.bi-house-up-fill::before { content: map-get($bootstrap-icons-map, "house-up-fill"); }
.bi-house-up::before { content: map-get($bootstrap-icons-map, "house-up"); }
.bi-house-x-fill::before { content: map-get($bootstrap-icons-map, "house-x-fill"); }
.bi-house-x::before { content: map-get($bootstrap-icons-map, "house-x"); }
.bi-person-add::before { content: map-get($bootstrap-icons-map, "person-add"); }
.bi-person-down::before { content: map-get($bootstrap-icons-map, "person-down"); }
.bi-person-exclamation::before { content: map-get($bootstrap-icons-map, "person-exclamation"); }
.bi-person-fill-add::before { content: map-get($bootstrap-icons-map, "person-fill-add"); }
.bi-person-fill-check::before { content: map-get($bootstrap-icons-map, "person-fill-check"); }
.bi-person-fill-dash::before { content: map-get($bootstrap-icons-map, "person-fill-dash"); }
.bi-person-fill-down::before { content: map-get($bootstrap-icons-map, "person-fill-down"); }
.bi-person-fill-exclamation::before { content: map-get($bootstrap-icons-map, "person-fill-exclamation"); }
.bi-person-fill-gear::before { content: map-get($bootstrap-icons-map, "person-fill-gear"); }
.bi-person-fill-lock::before { content: map-get($bootstrap-icons-map, "person-fill-lock"); }
.bi-person-fill-slash::before { content: map-get($bootstrap-icons-map, "person-fill-slash"); }
.bi-person-fill-up::before { content: map-get($bootstrap-icons-map, "person-fill-up"); }
.bi-person-fill-x::before { content: map-get($bootstrap-icons-map, "person-fill-x"); }
.bi-person-gear::before { content: map-get($bootstrap-icons-map, "person-gear"); }
.bi-person-lock::before { content: map-get($bootstrap-icons-map, "person-lock"); }
.bi-person-slash::before { content: map-get($bootstrap-icons-map, "person-slash"); }
.bi-person-up::before { content: map-get($bootstrap-icons-map, "person-up"); }
.bi-scooter::before { content: map-get($bootstrap-icons-map, "scooter"); }
.bi-taxi-front-fill::before { content: map-get($bootstrap-icons-map, "taxi-front-fill"); }
.bi-taxi-front::before { content: map-get($bootstrap-icons-map, "taxi-front"); }
.bi-amd::before { content: map-get($bootstrap-icons-map, "amd"); }
.bi-database-add::before { content: map-get($bootstrap-icons-map, "database-add"); }
.bi-database-check::before { content: map-get($bootstrap-icons-map, "database-check"); }
.bi-database-dash::before { content: map-get($bootstrap-icons-map, "database-dash"); }
.bi-database-down::before { content: map-get($bootstrap-icons-map, "database-down"); }
.bi-database-exclamation::before { content: map-get($bootstrap-icons-map, "database-exclamation"); }
.bi-database-fill-add::before { content: map-get($bootstrap-icons-map, "database-fill-add"); }
.bi-database-fill-check::before { content: map-get($bootstrap-icons-map, "database-fill-check"); }
.bi-database-fill-dash::before { content: map-get($bootstrap-icons-map, "database-fill-dash"); }
.bi-database-fill-down::before { content: map-get($bootstrap-icons-map, "database-fill-down"); }
.bi-database-fill-exclamation::before { content: map-get($bootstrap-icons-map, "database-fill-exclamation"); }
.bi-database-fill-gear::before { content: map-get($bootstrap-icons-map, "database-fill-gear"); }
.bi-database-fill-lock::before { content: map-get($bootstrap-icons-map, "database-fill-lock"); }
.bi-database-fill-slash::before { content: map-get($bootstrap-icons-map, "database-fill-slash"); }
.bi-database-fill-up::before { content: map-get($bootstrap-icons-map, "database-fill-up"); }
.bi-database-fill-x::before { content: map-get($bootstrap-icons-map, "database-fill-x"); }
.bi-database-fill::before { content: map-get($bootstrap-icons-map, "database-fill"); }
.bi-database-gear::before { content: map-get($bootstrap-icons-map, "database-gear"); }
.bi-database-lock::before { content: map-get($bootstrap-icons-map, "database-lock"); }
.bi-database-slash::before { content: map-get($bootstrap-icons-map, "database-slash"); }
.bi-database-up::before { content: map-get($bootstrap-icons-map, "database-up"); }
.bi-database-x::before { content: map-get($bootstrap-icons-map, "database-x"); }
.bi-database::before { content: map-get($bootstrap-icons-map, "database"); }
.bi-houses-fill::before { content: map-get($bootstrap-icons-map, "houses-fill"); }
.bi-houses::before { content: map-get($bootstrap-icons-map, "houses"); }
.bi-nvidia::before { content: map-get($bootstrap-icons-map, "nvidia"); }
.bi-person-vcard-fill::before { content: map-get($bootstrap-icons-map, "person-vcard-fill"); }
.bi-person-vcard::before { content: map-get($bootstrap-icons-map, "person-vcard"); }
.bi-sina-weibo::before { content: map-get($bootstrap-icons-map, "sina-weibo"); }
.bi-tencent-qq::before { content: map-get($bootstrap-icons-map, "tencent-qq"); }
.bi-wikipedia::before { content: map-get($bootstrap-icons-map, "wikipedia"); }
